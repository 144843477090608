import { AccountFeatureEnum } from '../api/accountFeatures/accountFeatureApiTypes';
import { SystemPermissionEnum, UserLicenseEnum } from '../api/roles/roleApiTypes';
import { useAuthContext } from '../auth';
import { ExperienceTypeEnum, } from '../auth/AuthenticationTypes';
import { FeatureFlags } from '../context/settingsAndFeatures/settingsAndFeaturesTypes';
import { useSettingsAndFeatures } from '../context/settingsAndFeatures/SettingsFeaturesContext';
import { RoutePath } from './Routing/routingTypes';
import { useAccountFeatureValidator } from './useAccountFeatureValidator';
import { useSystemPermissionsValidator } from './useSystemPermissionsValidators';

export const useRouteValidator = () => {

    const { authUser, experienceType, getMetadataToggleStatus, groupManagerAccounts, user, userAccounts, userCanViewDocuments, userCanViewDocumentRequests } = useAuthContext();
    const { hasFeatureOnAccount, hasFeatureOnSomeAccount, isESignatureFeatureAvailable } = useAccountFeatureValidator();
    const { hasPermissionOnAccount, hasSomePermissionOnAccount, hasPermissionOnSomeAccount } = useSystemPermissionsValidator();
    const { hasFeatureFlags, checkFeatureFlag } = useSettingsAndFeatures();

    const allAccountIds = Array.from(new Set(authUser?.userRoles.map(i => i.accountID)));
    const currentAccountId = authUser?.accountID ?? 0;

    function canGoToSomeRoute(...routes: RoutePath[]): boolean {
        return routes.some(i => canGoToRoute(i));
    }

    function canGoToRoute(route: RoutePath): boolean {

        switch (route) {

            case RoutePath.AccountSettingsIndex:
            case RoutePath.AccountSettings:
                return hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.SystemSettings);
            case RoutePath.AccountEmailImports:
            case RoutePath.AccountEmailImportNew:
            case RoutePath.AccountEmailImportEdit:
                return hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.SystemSettings);
            case RoutePath.Branding:
                return hasFeatureOnAccount(currentAccountId, AccountFeatureEnum.Branding) && hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.SystemSettings);
            case RoutePath.SingleSignOn:
            case RoutePath.SamlValidate:
            case RoutePath.SamlNew:
            case RoutePath.SamlEdit:
                return hasFeatureOnAccount(currentAccountId, AccountFeatureEnum.SSO_SAML) && hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.SystemSettings);

            case RoutePath.AuditLogs:
                return hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.AuditLogManagement);
            case RoutePath.CheckedOutIndex:
                return allAccountIds.some(i => hasFeatureOnAccount(i, AccountFeatureEnum.CheckInOut) && hasSomePermissionOnAccount(i, SystemPermissionEnum.CheckInOut, SystemPermissionEnum.FileManagement));
            case RoutePath.CheckedOutPersonal:
                return allAccountIds.some(i => hasFeatureOnAccount(i, AccountFeatureEnum.CheckInOut) && hasPermissionOnAccount(i, SystemPermissionEnum.CheckInOut));
            case RoutePath.CheckedOutAccount:
                return hasFeatureOnAccount(currentAccountId, AccountFeatureEnum.CheckInOut) && hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.FileManagement);

            case RoutePath.Documents:
            case RoutePath.Node:
            case RoutePath.NodeDetail:
            case RoutePath.Collections:
            case RoutePath.Collection:
            case RoutePath.CollectionNodeDetail:
                return userCanViewDocuments;

            case RoutePath.DocumentRequestIndex:
            case RoutePath.DocumentRequestInbox:
            case RoutePath.DocumentRequestInboxEdit:
                return hasFeatureOnSomeAccount(AccountFeatureEnum.DocumentRequests) && userCanViewDocumentRequests;
            case RoutePath.DocumentRequestNew:
            case RoutePath.DocumentRequestSent:
            case RoutePath.DocumentRequestSentEdit:
                return allAccountIds.some(i => hasFeatureOnAccount(i, AccountFeatureEnum.DocumentRequests)
                    && hasPermissionOnAccount(i, SystemPermissionEnum.DocumentRequests))
                    && experienceType == ExperienceTypeEnum.Standard;
            case RoutePath.DocumentRequestTemplates:
            case RoutePath.DocumentRequestTemplateNew:
            case RoutePath.DocumentRequestTemplateEdit:
                return hasFeatureOnSomeAccount(AccountFeatureEnum.DocumentRequests)
                    && hasPermissionOnSomeAccount(SystemPermissionEnum.DocumentRequestManagement)
                    && experienceType == ExperienceTypeEnum.Standard;
            case RoutePath.DocumentRequestAccountRequests:
            case RoutePath.DocumentRequestAccountRequestEdit:
                return hasFeatureOnSomeAccount(AccountFeatureEnum.DocumentRequests) && hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.DocumentRequestManagement);

            case RoutePath.ESignatureSent:
            case RoutePath.ESignatureIndex:
                return hasSomePermissionOnAccount(currentAccountId, SystemPermissionEnum.ESignature, SystemPermissionEnum.ESignatureManagement) && isESignatureFeatureAvailable(currentAccountId);
            case RoutePath.ESignatureAll:
            case RoutePath.ESignatureTemplates:
                return hasSomePermissionOnAccount(currentAccountId, SystemPermissionEnum.ESignatureManagement) && isESignatureFeatureAvailable(currentAccountId);

            case RoutePath.PageNotFound:
                return true;

            case RoutePath.FileProcessingQueue:
                return hasFeatureOnAccount(currentAccountId, AccountFeatureEnum.CheckInOut) && hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.FileManagement);

            case RoutePath.FolderTemplatesIndex:
            case RoutePath.FolderTemplateLibrary:
            case RoutePath.FolderTemplateEdit:
                return hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.TemplateManagement);

            case RoutePath.Forms:
                return hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.TemplateManagement);

            case RoutePath.Governance:
                return hasFeatureOnAccount(currentAccountId, AccountFeatureEnum.Governance) && hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.GovernanceAdmin);

            case RoutePath.Home:
                return experienceType != ExperienceTypeEnum.Anonymous;

            case RoutePath.Login:
                return true;

            // TODO: rename profile management and check that these are correct
            case RoutePath.MetadataIndex:
            case RoutePath.MDFields:
            case RoutePath.MDGroups:
                return ((!!hasFeatureFlags && checkFeatureFlag(FeatureFlags.MetadataEnabled)) || !!getMetadataToggleStatus())
                    && hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.ProfileManagement);

            case RoutePath.ProfilesIndex:
            case RoutePath.Profiles:
            case RoutePath.ProfileEdit:
            case RoutePath.ProfileNew:
            case RoutePath.ProfileItems:
            case RoutePath.ProfileItemEdit:
            case RoutePath.ProfileItemNew:
                return hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.ProfileManagement);

            case RoutePath.Recents:
                return true;

            case RoutePath.Reports:
                return hasFeatureOnAccount(currentAccountId, AccountFeatureEnum.Reporting) && hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.RubexReports);

            case RoutePath.RecycleBin:
                return hasFeatureOnAccount(currentAccountId, AccountFeatureEnum.CheckInOut) && hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.FileManagement);

            case RoutePath.Salesforce:
            case RoutePath.SalesforceMappingEdit:
            case RoutePath.SalesforceMappingNew:
                return hasFeatureOnAccount(currentAccountId, AccountFeatureEnum.Salesforce) && hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.SalesforceManagement);

            case RoutePath.Search:
            case RoutePath.UserSearch:
            case RoutePath.SharedSearch:
                return hasPermissionOnSomeAccount(SystemPermissionEnum.Search)
                    || (authUser?.userRoles ?? [])
                        .some(i => !!userAccounts?.get(i.accountID)?.licenseType
                                        && userAccounts?.get(i.accountID)?.licenseType == UserLicenseEnum.Guest
                                        && !i.anonymous
                                        && hasFeatureOnSomeAccount(AccountFeatureEnum.GuestUserSearch))
                    || (authUser?.userRoles ?? [])
                        .some(i => !!userAccounts?.get(i.accountID)?.licenseType
                                        && userAccounts?.get(i.accountID)?.licenseType == UserLicenseEnum.Guest
                                        && i.anonymous
                                        && userCanViewDocuments
                                        && hasFeatureOnSomeAccount(AccountFeatureEnum.PublicShareSearch));

            case RoutePath.SecurityPolicies:
            case RoutePath.SecurityPolicyEdit:
            case RoutePath.SecurityPolicyNew:
                return hasFeatureOnAccount(currentAccountId, AccountFeatureEnum.EnterpriseSecurity) && hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.EnterpriseSecurityManagement);

            case RoutePath.SystemLogsServer:
            case RoutePath.SystemLogsClient:
                return true;

            case RoutePath.OpenToDos:
            case RoutePath.CompletedToDos:
            case RoutePath.DismissedToDos:
                return true;

            case RoutePath.UserSettingsIndex:
            case RoutePath.UserSettings:
            case RoutePath.MyAccounts:
                return true;
            case RoutePath.IntegratedProducts:
                return !!user?.securitySettings && hasPermissionOnSomeAccount(SystemPermissionEnum.PersonalProviders);
            case RoutePath.UserEmailImports:
            case RoutePath.UserEmailImportNew:
            case RoutePath.UserEmailImportEdit:
                return allAccountIds.some(i => hasFeatureOnAccount(i, AccountFeatureEnum.EmailImport) && !!userAccounts?.get(i)?.licenseType && userAccounts?.get(i)?.licenseType != UserLicenseEnum.Guest);

            case RoutePath.UserIndex:
            case RoutePath.Users:
            case RoutePath.UserNew:
            case RoutePath.UserEdit:
                return hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.UserManagement);
            case RoutePath.Groups:
            case RoutePath.GroupNew:
            case RoutePath.GroupEdit:
                return hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.GroupManagement) || groupManagerAccounts .some(i => i == currentAccountId);
            case RoutePath.UserSessions:
                return hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.SessionManagement);
            case RoutePath.AccessLinks:
            case RoutePath.AccessLinkEdit:
                return hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.AccessLinkManagement);
            case RoutePath.UserDirectories:
                return hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.UserManagement, SystemPermissionEnum.GroupManagement);

            case RoutePath.UtilitiesIndex:
                return hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.SystemSettings)
                    || (hasFeatureOnAccount(currentAccountId, AccountFeatureEnum.AccountTemplateFeature) && (hasSomePermissionOnAccount(currentAccountId, SystemPermissionEnum.UserManagement, SystemPermissionEnum.GroupManagement, SystemPermissionEnum.ProfileManagement, SystemPermissionEnum.SalesforceManagement, SystemPermissionEnum.FileManagement)));
            case RoutePath.CSVImport:
            case RoutePath.CSVExport:
                return hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.SystemSettings);
            case RoutePath.AccountTemplateExport:
            case RoutePath.AccountTemplateImport:
                return hasFeatureOnAccount(currentAccountId, AccountFeatureEnum.AccountTemplateFeature) && (hasSomePermissionOnAccount(currentAccountId, SystemPermissionEnum.UserManagement, SystemPermissionEnum.GroupManagement, SystemPermissionEnum.ProfileManagement, SystemPermissionEnum.SalesforceManagement, SystemPermissionEnum.FileManagement));

            case RoutePath.WorkflowIndex:
            case RoutePath.WorkflowInstances:
            case RoutePath.WorkflowInstanceEdit:
                return hasFeatureOnAccount(currentAccountId, AccountFeatureEnum.Workflow) && hasSomePermissionOnAccount(currentAccountId, SystemPermissionEnum.Workflow, SystemPermissionEnum.WorkflowApproval);
            case RoutePath.Workflows:
            case RoutePath.WorkflowEdit:
            case RoutePath.WorkflowDashboard:
            case RoutePath.WorkflowHistory:
                return hasFeatureOnAccount(currentAccountId, AccountFeatureEnum.Workflow) && hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.WorkflowManagement);

            case RoutePath.WorkYouCareAbout:
                return true;

            default:
                return true;
        }
    }

    return {
        canGoToRoute,
        canGoToSomeRoute,
    };
};