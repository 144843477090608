import React, { useEffect, useState } from 'react';
import { EfcCallToActionInfo, Modal, Text } from '@efilecabinet/efc-atlantis-components';
import { useIcons } from '../../../../../hooks/useIcons';
import { useUtilities } from '../../../../../hooks/useUtilities';
import { ThemeEnum } from '../../../../../hooks/useColors';
import { useFileVersionApi } from '../../../../../api/fileVersions/useFileVersionApi';
import { useLayerContext } from '../../../../../context/layer/LayerContext';
import { useNodeDetailsContext } from '../../../../../context/nodeDetails/NodeDetailsContext';
import { TranslationFiles, useSafeTranslation, FileVersionsTKeys } from '../../../../../hooks/useSafeTranslation';
import { FileVersionDTO } from '../../../../../api/fileVersions/fileVersionApiTypes';

interface ConfirmDeleteModalProps {
    fileVersion?: FileVersionDTO;
    isDeleteModalOpen: boolean;
    closeDeleteModal: () => void;
}

export const ConfirmDeleteModal = ({ fileVersion, isDeleteModalOpen, closeDeleteModal }: ConfirmDeleteModalProps) => {
    const { spinnerIconProps } = useIcons();
    const { formatDateTime } = useUtilities();
    const { showToastMessage } = useLayerContext();
    const { deleteFileVersion } = useFileVersionApi();
    const { fetchFileVersions } = useNodeDetailsContext();
    const { t } = useSafeTranslation(TranslationFiles.FileVersions);

    const [showSpinner, setShowSpinner] = useState(false);
    const [ctas, setCtas] = useState<EfcCallToActionInfo[]>([]);

    const showSuccessToast = (createdOn: string) => {
        showToastMessage({
            identifier: 'delete-file-version-success-toast',
            color: ThemeEnum.Success,
            message: <Text>{t(FileVersionsTKeys.DeleteSuccess, { createdOn })}</Text>,
            timeout: 2750,
        });
    };

    const handleConfirm = async () => {
        if (!fileVersion) return;
        try {
            setShowSpinner(true);
            await deleteFileVersion(fileVersion.id.toString());
            await fetchFileVersions(fileVersion.nodeId.toString());
            closeDeleteModal();
            showSuccessToast(formatDateTime(fileVersion.createdOn));
        } catch (error) {
            console.log({ error });
        } finally {
            setShowSpinner(false);
        }
    };

    useEffect(() => {
        setCtas([
            {
                text: t(FileVersionsTKeys.CancelAction),
                emphasis: 'med',
                onClick: closeDeleteModal,
                dataId: 'file-version-delete-cancel',
            },
            {
                text: t(FileVersionsTKeys.ConfirmDeleteAction),
                emphasis: 'high',
                color: 'danger',
                onClick: handleConfirm,
                dataId: 'file-version-delete-confirm',
            },
        ]);
    }, []);

    useEffect(() => {
        const updatedCtas = [...ctas];

        const ctaIndex = updatedCtas.findIndex((cta) => cta.text === t(FileVersionsTKeys.ConfirmDeleteAction));

        if (ctaIndex !== -1) {
            if (!!showSpinner) {
                updatedCtas[ctaIndex].icon = spinnerIconProps;
                updatedCtas[ctaIndex].disabled = true;
            } else {
                delete updatedCtas[ctaIndex].icon;
                updatedCtas[ctaIndex].disabled = false;
            }

            setCtas(updatedCtas);
        }
    }, [showSpinner]);

    return (
        <Modal isOpen={isDeleteModalOpen} ctas={ctas} dataId='delete-file-version-modal'>
            <Modal.Header toggle={closeDeleteModal} tag='div' className='px-4 pt-4 pb-0'>
                {t(FileVersionsTKeys.ConfirmDeleteHeader)}
            </Modal.Header>
            <Modal.Body className='p-4'>{t(FileVersionsTKeys.ConfirmDeleteBody)}</Modal.Body>
        </Modal>
    );
};
