import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Col, Form, FormFeedback, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Label, Input, FormGroup, UncontrolledDropdown, Dropdown, Text, EfcCallToActionInfo } from '@efilecabinet/efc-atlantis-components';
import { useLayerContext } from '../../../../../context/layer/LayerContext';
import { MDFieldModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useBootstrapBreakpoints } from '../../../../../hooks/bootstrap/useBootstrapBreakpoints';
import { IUseModalProps } from '../../../../../hooks/useModal';
import { ScreenSize } from '../../../../../hooks/bootstrap/bootstrapTypes';
import { useMDFieldApi } from '../../../../../api/mdFields/useMDFieldApi';
import { useAuthContext } from '../../../../../auth';
import { useMetadataUtilities } from '../../../../../hooks/useMetadataUtilities';
import { AddressMDFieldFormats } from '../_formats/_address/addressMDFieldFormats/addressMDFieldFormats';
import { NumberMDFieldFormats } from '../_formats/_number/numberMDFieldFormats/numberMDFieldFormats';
import { DateMDFieldFormats } from '../_formats/_date/dateMDFieldFormats/dateMDFieldFormats';
import { TextMDFieldFormats } from '../_formats/_text/textMDFieldFormats/textMDFieldFormats';
import { DropdownListMDFieldFormats } from '../_formats/_dropdownList/dropdownListMDFieldFormats/dropdownListMDFieldFormats';
import { DropdownListAddOptions } from '../_formats/_dropdownList/dropdownListAddOptions/dropdownListAddOptions';
import { CheckboxMDFieldAdvancedFormats } from '../_formats/_checkbox/checkboxMDFieldAdvancedFormats/checkboxMDFieldAdvancedFormats';
import { ConfirmationModal } from '../../../../__shared/_modals/confirmationModal/confirmationModal';
import { MDModalSteps } from '../../../metadataFieldTypes';
import { MDFieldDTO, MDFieldTypes } from '../../../../../api/mdFields/mdFieldApiTypes';
import './mdFieldModal.css';

export interface MDFieldModalProps extends IUseModalProps {
    mdFieldToEdit?: MDFieldDTO; // optional, if not present, the modal will be in add mode
    onSubmitField: (mdField: MDFieldDTO) => void;
}

export const MDFieldModal = ({ isOpen, close, mdFieldToEdit, onSubmitField }: MDFieldModalProps) => {

    const nameInputRef = useRef<HTMLInputElement>(null);

    const { authUser, hasAuthUser } = useAuthContext();
    const { screenSize } = useBootstrapBreakpoints();
    const { openModal } = useLayerContext();
    const { createMDField, getMDFieldByName, updateMDField } = useMDFieldApi();
    const { createInitialMDField } = useMetadataUtilities();
    const { t } = useSafeTranslation(TranslationFiles.MDFieldModal);

    const [currentStep, setCurrentStep] = useState(MDModalSteps.NewField);
    const [fieldTypeDropdownText, setFieldTypeDropdownText] = useState(MDFieldModalTKeys.Default);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);
    const [mdField, setMDField] = useState<MDFieldDTO>(mdFieldToEdit ?? createInitialMDField());
    const [textNameError, setTextInputError] = useState('');
    const [updatedDropDownType, setUpdatedDropDownType] = useState<MDFieldTypes>(MDFieldTypes.Text);

    const MAX_INPUT_CHARS = 250;
    const NAME_REGEX = /[^0-9a-zA-Z#()?._/\-\\]+/ig;

    const clearForm = () => {
        setFieldTypeDropdownText(MDFieldModalTKeys.Default);
        setIsFormValid(true);
        setTextInputError('');
    };

    const handleAddOptions = () => {
        setCurrentStep(MDModalSteps.DropdownListOptions);
    };

    const handleEdit = () => {
        if (!!mdField.name && !!hasAuthUser && !!mdField.id) {
            updateMDField(mdField).then(updatedMDField => {
                onSubmitField(updatedMDField);
                confirmClose(true);
            });
        }
    };

    const handleSubmit = () => {
        if (!!mdField.name && !!hasAuthUser) {
            mdField.accountId = authUser?.accountID.toString();
            createMDField(mdField).then(newMDField => {
                onSubmitField(newMDField);
                confirmClose(true);
            });
        }
    };

    const handleBack = () => {
        setCurrentStep(MDModalSteps.NewField);
    };

    const handleClose = () => {
        if (mdField.type == MDFieldTypes.DropdownList && (mdField.dropdownListProps?.listValue?.length ?? 0 > 0)) {
            openModal((closeModalFn) =>
                <ConfirmationModal
                    closeModalFn={closeModalFn}
                    onClose={confirmClose}
                    confirmCtaText={t(MDFieldModalTKeys.CloseCta)}
                    denyCtaText={t(MDFieldModalTKeys.StayCta)}
                    titleText={t(MDFieldModalTKeys.ConfirmationModalTitle)}
                    bodyText={t(MDFieldModalTKeys.ConfirmCloseMessage)}
                />
            );
        } else {
            confirmClose(true);
        }
    };

    const confirmClose = (confirmClose: boolean) => {
        if (confirmClose) {
            setCurrentStep(MDModalSteps.NewField);
            clearForm();
            !!close && close();
        }
    };

    const renderFieldSpecificDetails = () => {
        switch (fieldTypeDropdownText) {
            case MDFieldModalTKeys.Address:
                return <AddressMDFieldFormats mdField={mdField} setMDField={setMDField} />;
            case MDFieldModalTKeys.Date:
                return <DateMDFieldFormats mdField={mdField} setMDField={setMDField} />;
            case MDFieldModalTKeys.Number:
                return <NumberMDFieldFormats mdField={mdField} setMDField={setMDField} setIsFormValid={setIsFormValid} />;
            case MDFieldModalTKeys.Text:
                return <TextMDFieldFormats mdField={mdField} setMDField={setMDField} setIsFormValid={setIsFormValid} />;
            case MDFieldModalTKeys.Checkbox:
                return <CheckboxMDFieldAdvancedFormats mdField={mdField} setMDField={setMDField} />;
            case MDFieldModalTKeys.Email:
                return null;
            case MDFieldModalTKeys.DropdownList:
                return <DropdownListMDFieldFormats mdField={mdField} setMDField={setMDField} />;
            case MDFieldModalTKeys.PhoneNumber:
                return null;
            default:
                return null;
        }
    };

    const getDropdownTextFromType = (type: MDFieldTypes): MDFieldModalTKeys => {
        switch (type) {
            case MDFieldTypes.Text:
                return MDFieldModalTKeys.Text;
            case MDFieldTypes.Number:
                return MDFieldModalTKeys.Number;
            case MDFieldTypes.DropdownList:
                return MDFieldModalTKeys.DropdownList;
            case MDFieldTypes.Email:
                return MDFieldModalTKeys.Email;
            case MDFieldTypes.Address:
                return MDFieldModalTKeys.Address;
            case MDFieldTypes.PhoneNumber:
                return MDFieldModalTKeys.PhoneNumber;
            case MDFieldTypes.Date:
                return MDFieldModalTKeys.Date;
            case MDFieldTypes.Checkbox:
                return MDFieldModalTKeys.Checkbox;
            default:
            {
                const _exhaustiveCheck: never = type;
                return MDFieldModalTKeys.Default;
            }
        }
    };

    const handleDropdownItem = (newDropDownType: MDFieldTypes) => {
        const newDropDownText = getDropdownTextFromType(newDropDownType);
        if (mdField.type == MDFieldTypes.DropdownList && (mdField.dropdownListProps?.listValue?.length ?? 0 > 0)) {
            openModal((closeModalFn) =>
                <ConfirmationModal
                    closeModalFn={closeModalFn}
                    onClose={(isConfirmed) => confirmChangeType(isConfirmed, newDropDownText)}
                    confirmCtaText={t(MDFieldModalTKeys.ConfirmCta)}
                    denyCtaText={t(MDFieldModalTKeys.CancelCta)}
                    titleText={t(MDFieldModalTKeys.ConfirmationModalTitle)}
                    bodyText={t(MDFieldModalTKeys.ConfirmChangeFieldTypeMessage)}
                />
            );

            setUpdatedDropDownType(newDropDownType);
        } else {
            setFieldTypeDropdownText(newDropDownText);
            setMDField({ ...mdField, type: newDropDownType });
        }
    };

    const confirmChangeType = (confirmChange: boolean, updatedDropDownText: MDFieldModalTKeys) => {
        if (confirmChange) {
            setFieldTypeDropdownText(updatedDropDownText);
            setMDField({ ...mdField, type: updatedDropDownType, dropdownListProps: undefined });
        }
    };

    const focusNameInput = () => {
        if (isOpen) {
            setTimeout(() => {
                (nameInputRef?.current as any)?.focus();
            });
        }
    };

    const validateTextValue = async () => {
        let error = '';

        if (mdField.name == '') {
            error = t(MDFieldModalTKeys.BlankNameError);
        } else if (mdField.name.match(NAME_REGEX)) {
            error = t(MDFieldModalTKeys.AlphanumericNameError);
        } else if (await isTextNameInvalid()) {
            error = t(MDFieldModalTKeys.DuplicateNameError);
        }

        setTextInputError(error);
    };

    const isTextNameInvalid = async (): Promise<boolean> => {
        // response will be '' or id of the field that has the same name
        const response = await getMDFieldByName({ accountId: authUser?.accountID.toString() as string, name: mdField.name });

        if (mdField.id === response.id) {
            return false;
        }
        if (!response?.id) {
            return false;
        } else {
            return true;
        }
    };

    const isSubmitDisabled = (): boolean => {
        return !isFormValid || !mdField.name || fieldTypeDropdownText === MDFieldModalTKeys.Default;
    };

    const getCtas = (): EfcCallToActionInfo[] => {
        return [
            {
                text: t(MDFieldModalTKeys.SaveCta),
                icon: undefined,
                emphasis: 'high',
                color: 'primary',
                disabled: isSubmitDisabled(),
                onClick: !!mdField.id ? handleEdit : handleSubmit,
                dataId: 'btnSaveMetadataField',
                show: () => mdField.type !== MDFieldTypes.DropdownList && currentStep === MDModalSteps.NewField,
            },
            {
                text: t(MDFieldModalTKeys.NextCta),
                icon: { icon: 'arrow-right', pull: 'right' },
                emphasis: 'high',
                color: 'primary',
                disabled: isSubmitDisabled(),
                onClick: handleAddOptions,
                dataId: 'btnNextOptions',
                show: () => mdField.type === MDFieldTypes.DropdownList && currentStep === MDModalSteps.NewField,
            },
            {
                text: t(MDFieldModalTKeys.BackCta),
                icon: { icon: 'arrow-left', pull: 'left' },
                emphasis: 'med',
                color: 'primary',
                disabled: isSubmitDisabled(),
                onClick: handleBack,
                dataId: 'btnBackToNewField',
                show: () => mdField.type === MDFieldTypes.DropdownList && currentStep === MDModalSteps.DropdownListOptions,
            },
            {
                text: t(MDFieldModalTKeys.SaveListCta),
                emphasis: 'high',
                color: 'primary',
                disabled: isSubmitDisabled(),
                onClick: !!mdField.id ? handleEdit : handleSubmit,
                dataId: 'btnSaveListOptions',
                show: () => mdField.type === MDFieldTypes.DropdownList && currentStep === MDModalSteps.DropdownListOptions,
            },
        ];
    };

    useEffect(() => {
        return () => {
            clearForm();
        };
    }, []);

    useEffect(() => {
        setIsFormValid(!textNameError);
    }, [textNameError]);

    useEffect(() => {
        focusNameInput();
    }, [isOpen]);

    useEffect(() => {
        if (!!mdField.id) {
            setFieldTypeDropdownText(getDropdownTextFromType(mdField.type));
        }
    }, [mdField]);

    return (
        <>
            <Modal
                size='lg'
                isOpen={isOpen}
                toggle={handleClose}
                ctas={getCtas()}
                unmountOnClose={true}
                title={currentStep === MDModalSteps.NewField ? t(MDFieldModalTKeys.ModalTitle) : t(MDFieldModalTKeys.TitleAddOptions)}
                className={`${screenSize < ScreenSize.s ? 'md-field-mobile-modal-spacing' : ''}`}>
                {currentStep === MDModalSteps.NewField && (
                    <Modal.Body className='mt-4'>
                        <Form>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Label for='nameInputFieldText'>{t(MDFieldModalTKeys.FieldName)}</Label>
                                        <Input
                                            innerRef={nameInputRef}
                                            id='nameInputFieldText'
                                            name='nameInputFieldText'
                                            dataId='txtNameInputText'
                                            className='md-field-modal-field-size'
                                            value={mdField.name}
                                            maxLength={MAX_INPUT_CHARS}
                                            onChange={(event) => setMDField({ ...mdField, name: event.target.value })}
                                            invalid={!!textNameError}
                                            onBlur={validateTextValue}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormFeedback className={!!textNameError ? 'd-block' : ''} valid={!textNameError}>
                                            {textNameError}
                                        </FormFeedback>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>

                        <Row>
                            <Col>
                                <Label for='fieldTypeDropdown'>{t(MDFieldModalTKeys.FieldType)}</Label>
                                <UncontrolledDropdown direction='down' disabled={!!mdField.id}>
                                    <Dropdown.Toggle dataId='fieldTypeDropdown' className={`nav-link d-flex align-items-center ${!!mdField.id ? 'md-field-modal-type-dropdown-text-disabled' : ''}`} tag='a'>
                                        <div className='md-field-modal-dropdown-text'>
                                            <Text className='px-2'>{t(fieldTypeDropdownText)}</Text>
                                        </div>
                                        <div className='md-field-modal-dropdown-caret'>
                                            <FontAwesomeIcon icon={['far', 'angle-down']} />
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item dataId='fieldText' onClick={() => handleDropdownItem(MDFieldTypes.Text)}>{t(MDFieldModalTKeys.Text)}</Dropdown.Item>
                                        <Dropdown.Item dataId='fieldNumber' onClick={() => handleDropdownItem(MDFieldTypes.Number)}>{t(MDFieldModalTKeys.Number)}</Dropdown.Item>
                                        <Dropdown.Item dataId='fieldDropdownList' onClick={() => handleDropdownItem(MDFieldTypes.DropdownList)}>{t(MDFieldModalTKeys.DropdownList)}</Dropdown.Item>
                                        <Dropdown.Item dataId='fieldEmail' onClick={() => handleDropdownItem(MDFieldTypes.Email)}>{t(MDFieldModalTKeys.Email)}</Dropdown.Item>
                                        <Dropdown.Item dataId='fieldAddress' onClick={() => handleDropdownItem(MDFieldTypes.Address)}>{t(MDFieldModalTKeys.Address)}</Dropdown.Item>
                                        <Dropdown.Item dataId='fieldPhoneNumber' onClick={() => handleDropdownItem(MDFieldTypes.PhoneNumber)}>{t(MDFieldModalTKeys.PhoneNumber)}</Dropdown.Item>
                                        <Dropdown.Item dataId='fieldDate' onClick={() => handleDropdownItem(MDFieldTypes.Date)}>{t(MDFieldModalTKeys.Date)}</Dropdown.Item>
                                        <Dropdown.Item dataId='fieldCheckbox' onClick={() => handleDropdownItem(MDFieldTypes.Checkbox)}>{t(MDFieldModalTKeys.Checkbox)}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </UncontrolledDropdown>
                            </Col>
                            {!!mdField.id && <Text className='pt-2'>{t(MDFieldModalTKeys.EditDropdownMessage)}</Text>}
                        </Row>

                        {renderFieldSpecificDetails()}

                    </Modal.Body>
                )}
                {currentStep === MDModalSteps.DropdownListOptions && (
                    <DropdownListAddOptions mdField={mdField} setMDField={setMDField} />
                )}
            </Modal>
        </>
    );
};
