import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Col, Form, Label, Row } from 'reactstrap';
import { Input, FormGroup, Text } from '@efilecabinet/efc-atlantis-components';
import { AddressMDFieldModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../../../hooks/useSafeTranslation';
import { AddressMDFieldDTO, MDFieldDTO } from '../../../../../../../api/mdFields/mdFieldApiTypes';
import './addressMDFieldFormats.css';

export interface AddressMDFieldFormatsProps {
    mdField: MDFieldDTO;
    setMDField: Dispatch<SetStateAction<MDFieldDTO>>;
}

export const AddressMDFieldFormats = (props: AddressMDFieldFormatsProps) => {
    const { mdField, setMDField } = props;

    const { t } = useSafeTranslation(TranslationFiles.AddressMDFieldModal);

    useEffect(() => {
        setMDField({ ...mdField, addressProps: { ...mdField.addressProps as AddressMDFieldDTO, addressOne: true, addressTwo: false, city: true, state: true, postal: true, country: true } });
    }, []);

    return (
        <Row className='mt-2 md-field-modal-row-spacing'>
            <Col xs='auto' className='position-relative'>
                <Label for='selectFormat'>{t(AddressMDFieldModalTKeys.SelectFormat)}</Label>
                <Form>
                    <FormGroup>
                        <Row className='mb-2 ms-1 d-flex align-items-center'>
                            <Input
                                id='show-address-one'
                                dataId='cbAddressOne'
                                className='mb-1 pe-0 pointer md-field-modal-checkbox'
                                type='checkbox'
                                defaultChecked={mdField.addressProps?.addressOne} onClick={() => setMDField({ ...mdField, addressProps: { ...mdField.addressProps as AddressMDFieldDTO, addressOne: !mdField.addressProps?.addressOne } })}
                            />
                            <div className={`ms-2 rounded border d-flex align-items-center md-field-modal-address-format-large ${!mdField.addressProps?.addressOne ? 'md-field-modal-address-format-disabled' : ''}`}>
                                <Text className='md-field-modal-address-format-large-text'>{t(AddressMDFieldModalTKeys.AddressLineOne)}</Text>
                            </div>
                        </Row>

                        <Row className='mb-2 ms-1 d-flex align-items-center'>
                            <Input
                                id='show-address-two'
                                dataId='cbAddressTwo'
                                className='mb-1 pe-0 pointer md-field-modal-checkbox'
                                type='checkbox'
                                defaultChecked={mdField.addressProps?.addressTwo} onClick={() => setMDField({ ...mdField, addressProps: { ...mdField.addressProps as AddressMDFieldDTO, addressTwo: !mdField.addressProps?.addressTwo } })}
                            />
                            <div className={`ms-2 rounded border d-flex align-items-center md-field-modal-address-format-large ${!mdField.addressProps?.addressTwo ? 'md-field-modal-address-format-disabled' : ''}`}>
                                <Text className='md-field-modal-address-format-large-text'>{t(AddressMDFieldModalTKeys.AddressLineTwo)}</Text>
                            </div>
                        </Row>

                        <Row className='mb-2 ms-1 d-flex align-items-center'>
                            <Input
                                id='show-city'
                                dataId='cbCity'
                                className='mb-1 pe-0 pointer md-field-modal-checkbox'
                                type='checkbox' defaultChecked={mdField.addressProps?.city} onClick={() => setMDField({ ...mdField, addressProps: { ...mdField.addressProps as AddressMDFieldDTO, city: !mdField.addressProps?.city } })}
                            />
                            <div className={`ms-2 rounded border d-flex align-items-center md-field-modal-address-format-large ${!mdField.addressProps?.city ? 'md-field-modal-address-format-disabled' : ''}`}>
                                <Text className='md-field-modal-address-format-large-text'>{t(AddressMDFieldModalTKeys.City)}</Text>
                            </div>
                        </Row>

                        <Row>
                            <Col>
                                <Row className='mb-2 ms-1 d-flex align-items-center'>
                                    <Input
                                        id='show-state'
                                        dataId='cbState'
                                        className='mb-1 pe-0 pointer md-field-modal-checkbox'
                                        type='checkbox'
                                        defaultChecked={mdField.addressProps?.state} onClick={() => setMDField({ ...mdField, addressProps: { ...mdField.addressProps as AddressMDFieldDTO, state: !mdField.addressProps?.state } })}
                                    />
                                    <div className={`ms-2 rounded border d-flex align-items-center md-field-modal-address-format-small ${!mdField.addressProps?.state ? 'md-field-modal-address-format-disabled' : ''}`}>
                                        <Text className='md-field-modal-address-format-small-text'>{t(AddressMDFieldModalTKeys.State)}</Text>
                                    </div>
                                </Row>

                                <Row className='mb-2 ms-1 d-flex align-items-center'>
                                    <Input
                                        id='show-country'
                                        dataId='cbCountry'
                                        className='mb-1 pe-0 pointer md-field-modal-checkbox'
                                        type='checkbox'
                                        defaultChecked={mdField.addressProps?.country} onClick={() => setMDField({ ...mdField, addressProps: { ...mdField.addressProps as AddressMDFieldDTO, country: !mdField.addressProps?.country } })}
                                    />
                                    <div className={`ms-2 rounded border d-flex align-items-center md-field-modal-address-format-small ${!mdField.addressProps?.country ? 'md-field-modal-address-format-disabled' : ''}`}>
                                        <Text className='md-field-modal-address-format-small-text'>{t(AddressMDFieldModalTKeys.Country)}</Text>
                                    </div>
                                </Row>
                            </Col>
                            <Col>
                                <Row className='d-flex justify-content-end align-items-center'>
                                    <Input id='show-postal'
                                        dataId='cbPostal'
                                        className='mb-1 pe-0 pointer md-field-modal-checkbox'
                                        type='checkbox'
                                        defaultChecked={mdField.addressProps?.postal}
                                        onClick={() => setMDField({ ...mdField, addressProps: { ...mdField.addressProps as AddressMDFieldDTO, postal: !mdField.addressProps?.postal } })}
                                    />
                                    <div className={`ms-2 rounded border d-flex align-items-center md-field-modal-address-format-small ${!mdField.addressProps?.postal ? 'md-field-modal-address-format-disabled' : ''}`}>
                                        <Text className='md-field-modal-address-format-small-text'>{t(AddressMDFieldModalTKeys.Postal)}</Text>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
            </Col>
        </Row>
    );
};