import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { EfcCallToActionInfo, EfcTableColumnInfo, FullTable } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, useSafeTranslation, MDFieldsTKeys, MetadataTKeys } from '../../../../hooks/useSafeTranslation';
import { useAuthContext } from '../../../../auth';
import { useMDFieldApi } from '../../../../api/mdFields/useMDFieldApi';
import { MDManagementZeroState } from '../../_managment/mdManagmentZeroState/mdManagmentZeroState';
import { useLayerContext } from '../../../../context/layer/LayerContext';
import { MDFieldModal } from '../_modal/mdFieldModal/mdFieldModal';
import { MDFieldDTO, MDFieldTypes } from '../../../../api/mdFields/mdFieldApiTypes';

interface IMDFieldDisplay {
    id: string;
    name: string;
    type: string;
    appliedInstanceCount: number;
    createdBy: string;
}

export interface IMDFieldProps {
    active: boolean;
    refreshMDFieldsSentinalKeyState: [number, Dispatch<SetStateAction<number>>];
    selectedMDFieldState: [MDFieldDTO, Dispatch<SetStateAction<MDFieldDTO>>];
}

export const MDFieldsManagement: FC<IMDFieldProps> = ({ active, selectedMDFieldState, refreshMDFieldsSentinalKeyState }) => {

    const { t } = useSafeTranslation(TranslationFiles.MDFields);

    const { authUser, hasAuthUser } = useAuthContext();
    const { getAccountMDFields, deleteMDField, getMDField } = useMDFieldApi();
    const { openModal } = useLayerContext();

    const [allFieldsLoaded, setAllFieldsLoaded] = useState(false);
    const [mdFields, setMDFields] = useState<MDFieldDTO[]>([]);
    const [refreshMDFieldsSentinalKey, setRefreshMDFieldsSentinalKey] = refreshMDFieldsSentinalKeyState;
    const [selectedMDField, setSelectedMDField] = selectedMDFieldState;

    const BATCH_SIZE = 100;

    const mdFieldDisplays = mdFields.map((mdField: MDFieldDTO) => convertFieldToDisplay(mdField));

    function convertFieldToDisplay(mdField: MDFieldDTO): IMDFieldDisplay {
        const mdFieldItem: IMDFieldDisplay = {
            id: mdField.id as string,
            name: mdField.name,
            type: MDFieldTypes[mdField.type],
            appliedInstanceCount: mdField.appliedInstanceCount ?? 0,
            createdBy: mdField.createdByDisplayName === '' ? '-' : mdField.createdByDisplayName ?? '-'
        };

        return mdFieldItem;
    }

    async function deleteMDFieldAsync(mdFieldToDelete: IMDFieldDisplay) {
        if (!!authUser && !!mdFieldToDelete) {
            setMDFields(prevState => prevState
                .filter(mdField => mdField.id != mdField.id)
                .map(mdField => ({ ...mdField })));
            await deleteMDField(authUser?.accountID, mdFieldToDelete.id);
        }
    }

    async function editMDField(mdFieldDisplay: IMDFieldDisplay) {
        const mdField = await getMDField(authUser?.accountID as number, mdFieldDisplay.id);
        openMDFieldModal(mdField);
    }

    function openMDFieldModal(mdFieldToEdit?: MDFieldDTO) {
        openModal(() =>
            <MDFieldModal mdFieldToEdit={mdFieldToEdit} onSubmitField={() => setRefreshMDFieldsSentinalKey(prevState => prevState + 1)} />
        );
    }

    function onSelectField(selectedList: IMDFieldDisplay[]) {
        //seems to be double calling, but that appears to be a FullTable issue (imo)
        const selected = selectedList[0];
        if (!!selected && selected.id !== selectedMDField?.id) {
            setSelectedMDField(mdFields.find((f) => f.id === selected.id) as MDFieldDTO);
        }
    }

    const getColumns = (): (string | EfcTableColumnInfo)[] => {
        return [
            { name: 'name', searchBy: true, displayName: t(MDFieldsTKeys.NameColumn), widthPct: 35 },
            { name: 'type', searchBy: true, isEnum: true, enumValues: Object.keys(MDFieldTypes).filter((o) => isNaN(parseFloat(o))), displayName: t(MDFieldsTKeys.TypeColumn), widthPct: 15 },
            { name: 'instances', displayName: t(MDFieldsTKeys.InstanceColumn), widthPct: 15 },
            { name: 'createdBy', searchBy: true, isUserOrGroup: true, userField: 'createdBy', displayName: t(MDFieldsTKeys.CreatedByColumn), widthPct: 35 },
        ];
    };

    const getContextActions = (): EfcCallToActionInfo[] => {
        return [
            { text: t(MDFieldsTKeys.EditContextButton), onClick: editMDField },
            { text: t(MDFieldsTKeys.DeleteContextButton), onClick: deleteMDFieldAsync },
        ];
    };

    useEffect(() => {
        let componentUnmounted = false;

        async function loadAccountMDFieldsAsync() {
            await getAccountMDFields(authUser?.accountID as number, 0, BATCH_SIZE).then((fields) => {
                if (!componentUnmounted) {
                    if (fields.length < BATCH_SIZE) {
                        setAllFieldsLoaded(true);
                    }
                    setMDFields(fields);
                }
            });
        }

        if (!!active && !!hasAuthUser) {
            loadAccountMDFieldsAsync();
        }

        return () => {
            componentUnmounted = true;
        };
    }, [active, hasAuthUser, refreshMDFieldsSentinalKey]);

    return (
        <>
            {!allFieldsLoaded ? (
                <>Loading</>
            ) : (
                (mdFieldDisplays.length === 0) ? (
                    <MDManagementZeroState buttonText={t(MetadataTKeys.CreateField)} onButtonClick={() => openMDFieldModal()} />
                ) : (
                    <FullTable filterable={true} multiselect={false} data={mdFieldDisplays} columns={getColumns()} contextActions={getContextActions()} onSelect={onSelectField} />
                )
            )}
        </>
    );
};