import { DocumentRouteParams, DocumentRoutePath, RoutePath } from './Routing/routingTypes';

export const useUtopiaIFrameRoutes = () => {

    const IFrameDocumentRoutes: Map<DocumentRoutePath, string> = new Map([
        [RoutePath.Documents, '/home/state/data/root/details/'],
        [RoutePath.Node, `/home/state/data/node/${DocumentRouteParams.RootNodeId}/${DocumentRouteParams.ParentId}/details/${DocumentRouteParams.NodeIds}?selectedDetail=${DocumentRouteParams.SelectedDetail}&showFullPreview=${DocumentRouteParams.ShowFullPreview}`],
        [RoutePath.NodeDetail, `/home/state/data/node/${DocumentRouteParams.RootNodeId}/${DocumentRouteParams.ParentId}/details/${DocumentRouteParams.NodeIds}?selectedDetail=${DocumentRouteParams.SelectedDetail}&showFullPreview=${DocumentRouteParams.ShowFullPreview}`],
        [RoutePath.NodeContainer, `/home/state/data/node/${DocumentRouteParams.RootNodeId}/${DocumentRouteParams.ParentId}/details/${DocumentRouteParams.NodeIds}?selectedDetail=${DocumentRouteParams.SelectedDetail}`],
        [RoutePath.Collections, `/home/state/collections/collection/${DocumentRouteParams.DefaultCollectionId}/rootID/nodeID/details`],
        [RoutePath.Collection, `/home/state/collections/collection/${DocumentRouteParams.CollectionId}/rootID/nodeID/details`],
        [RoutePath.CollectionNodeDetail, `/home/state/collections/collection/${DocumentRouteParams.CollectionId}/rootID/nodeID/details/${DocumentRouteParams.NodeIds}?selectedDetail=${DocumentRouteParams.SelectedDetail}`],
    ]);

    return {
        IFrameDocumentRoutes
    };
};