import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import { Nav, Navbar, SearchInput, RevverIcon } from '@efilecabinet/efc-atlantis-components';
import { useAuthContext } from '../../../auth';
import { useColors } from '../../../hooks/useColors';
import { useBootstrapBreakpoints } from '../../../hooks/bootstrap/useBootstrapBreakpoints';
import { useRouteValidator } from '../../../hooks/useRouteValidator';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { VertNavState } from '../../../hooks/useVertNavState';
import { RoutePath } from '../../../hooks/Routing/routingTypes';
import { ScreenSize } from '../../../hooks/bootstrap/bootstrapTypes';
import { ExperienceTypeEnum } from '../../../auth/AuthenticationTypes';
import { ExperiencedFeedback } from './ExperiencedFeedback';
import { PreviewerXFeedback } from './PreviewerXFeedback';
import { SessionMismatchAlert } from './SessionMismatchAlert';
import { ToastMessages } from './ToastMessages';
import { Tours } from '../../Tours/Tours';
import { TourMenu } from '../../Tours/TourMenu/TourMenu';
import { StandardExperienceNavItems } from './StandardExperience/StandardExperienceNavItems';
import { GuestExperienceNavItems } from './GuestExperience/GuestExperienceNavItems';
import { AnonymousExperienceNavItems } from './AnonymousExperience/AnonymousExperienceNavItems';
import { UtopiaAlerts } from './UtopiaAlerts/UtopiaAlerts';
import './NavMenu.css';

interface NavMenuProps {
    vertNavState: VertNavState;
}

export const NavMenu = (props: NavMenuProps) => {
    const { vertNavState } = props;

    const { routeToSearchInputResults, routeToAdvancedSearch } = useRouting();
    const colors = useColors();

    const [searchInput, setSearchInput] = useState<string>('');

    const { experienceType } = useAuthContext();
    const { canGoToRoute } = useRouteValidator();
    const { screenSize } = useBootstrapBreakpoints();

    const handleKeyEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            searchIconClick();
        }
    };

    const searchIconClick = () => {
        routeToSearchInputResults(searchInput);
        setSearchInput('');
    };

    const advancedSearchIconClick = () => {
        routeToAdvancedSearch();
        setSearchInput('');
    };

    return (
        <Navbar expand='sm' fixed='top' className={`${vertNavState == VertNavState.Hidden ? 'atl-navbar-xs' : 'atl-navbar ' + (vertNavState == VertNavState.Collapsed ? 'sideNav-collapsed' : '')}`}>
            <RevverIcon
                to={`${experienceType != ExperienceTypeEnum.Anonymous ? RoutePath.Home : ''}`}
                tag={Link}
                color={`${vertNavState == VertNavState.Hidden ? 'white' : colors.atlantisEnabled}`}
                className={`icon-link ${vertNavState == VertNavState.Collapsed ? 'sideNav-collapsed' : ''} ${experienceType == ExperienceTypeEnum.Anonymous ? 'mobile-remove-spacing' : ''}`} />
            <Col>
                {screenSize > ScreenSize.xs && (
                    <Nav className='ms-1' navbar>
                        <Nav.Item className='w-100'>
                            {canGoToRoute(RoutePath.Search) &&
                                <SearchInput className='search-box' dataId='txtSearchBox' value={searchInput} onChange={(event) => setSearchInput(event.target.value)} onKeyDown={handleKeyEvent} iconOnClick={searchIconClick} iconAdvancedOnClick={advancedSearchIconClick} />
                            }
                        </Nav.Item>
                    </Nav>
                )}
            </Col>
            <Col sm='auto'>
                <div className='d-flex align-items-center'>
                    {experienceType == ExperienceTypeEnum.Standard &&
                        <StandardExperienceNavItems/>
                    }
                    {experienceType == ExperienceTypeEnum.Guest &&
                        <GuestExperienceNavItems/>
                    }
                    {experienceType == ExperienceTypeEnum.Anonymous &&
                        <AnonymousExperienceNavItems/>
                    }
                </div>
            </Col>
            <ToastMessages />
            <TourMenu />
            <Tours />

            <ExperiencedFeedback />
            <PreviewerXFeedback />
            <SessionMismatchAlert />
            <UtopiaAlerts />
        </Navbar>
    );
};
