import React, { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { LoadDocumentOptions, PreviewerInstance } from '@efilecabinet/previewerx';
import { useAuthContext } from '../../auth';
import { ILoadPreviewerDocumentProps, usePreviewer } from '../../hooks/usePreviewer';
import { useLayerContext } from '../../context/layer/LayerContext';
import { usePreviewerContext } from '../../context/previewer/PreviewerContext';
import { PreviewerFormFillModal } from './PreviewerModals/PreviewerFormFillModal/PreviewerFormFillModal';
import { NodeDTO, NodeType } from '../../api/node/nodeApiTypes';

interface PreviewerProps {
    previewerNode?: NodeDTO;
    fileVersionId?: number;
    isLoading?: boolean;
    previewerContainer: React.RefObject<HTMLDivElement>;
}

export const Previewer = (props: PreviewerProps) => {
    const { previewerNode, fileVersionId, isLoading, previewerContainer } = props;

    const { openModal } = useLayerContext();
    const { hasAuthUser } = useAuthContext();
    const { setupPreviewerInstance, loadPreviewerDocument, getFullAccusoftPreviewUrl } = usePreviewer();
    const { isProviderReady, searchTerm } = usePreviewerContext();

    const [previewerInstance, setPreviewerInstance] = useState<PreviewerInstance>();
    const [isPreviewerDocumentLoading, setIsPreviewerDocumentLoading] = useState<boolean>(false);

    const showAccusoftPreviewerModal = (previewerNode: NodeDTO, fullAccusoftPreviewUrl: string) => {
        openModal((closeModalFn) =>
            <PreviewerFormFillModal onClose={closeModalFn} previewerNode={previewerNode} fullAccusoftPreviewUrl={fullAccusoftPreviewUrl ?? ''} />
        );
    };

    //TODO: Should this load the targetFileNode info instead of using the previewerNode?
    const nonPdfNavigationCallback = (targetFileNodeId: string, previewerNode: NodeDTO) => {
        if (!!previewerNode.id) {
            const documentIframe = document.querySelector('[name="utopiaIframe"]') as HTMLIFrameElement;
            documentIframe?.contentWindow?.postMessage(JSON.stringify({
                key: 'nonPdfNavigationCallback',
                data: {
                    accountId: previewerNode.accountID,
                    parentNodeId: previewerNode.parentID,
                    targetFileNodeId: targetFileNodeId,
                },
            }), '*');
        }
    };

    useEffect(() => {
        if (!!isProviderReady && !previewerInstance) {
            if (previewerContainer.current) {
                setupPreviewerInstance(previewerContainer, setPreviewerInstance);
            }
        }
    }, [isProviderReady, previewerContainer]);

    useEffect(() => {
        if (!previewerNode?.id 
            || !previewerInstance 
            || !fileVersionId 
            || isPreviewerDocumentLoading 
            || isLoading) {
            return;
        }

        if (previewerNode?.systemType != NodeType.File) {
            console.log('Unable to preview non-files');
            return;
        }

        //TODO: Handle rapid node changes. Currenty getting various errors and warnings. 
        //Sometimes the previewer will keep reloading over and over until you refersh. 
        //If you wait for the node preview to completely load before changing nodes, it seems to work alright.
        const tryLoadDocument = (currentPreviewerNode: NodeDTO, currentFileVersionId: number, currentSearchTerm: string, currentPreviewerContainer: React.RefObject<HTMLDivElement>) => {
            if(currentPreviewerNode.id == previewerNode.id 
                && currentFileVersionId == fileVersionId 
                && currentSearchTerm == searchTerm 
                && currentPreviewerContainer.current == previewerContainer.current){
                    getFullAccusoftPreviewUrl(currentPreviewerNode.id, currentSearchTerm, currentFileVersionId?.toString()).then((url) => {
                        const options: LoadDocumentOptions = {
                            fileVersion: currentFileVersionId,
                            searchTerm: currentSearchTerm,
                            formToolsCallback: () => { showAccusoftPreviewerModal(currentPreviewerNode, url); },
                            nonPdfRedirectCallback: (targetFileNodeId: string) => { nonPdfNavigationCallback(targetFileNodeId, currentPreviewerNode); }
                        };

                        const loadPreviewerProps: ILoadPreviewerDocumentProps = {
                            nodeId: currentPreviewerNode.id,
                            options: options,
                            previewerDiv: previewerContainer,
                            previewerInstance: previewerInstance,
                            setIsPreviewerDocumentLoading: setIsPreviewerDocumentLoading,
                        };
            
                        loadPreviewerDocument(loadPreviewerProps);
                    });
            }
        };

        if (!!previewerInstance && !!hasAuthUser && !!previewerNode && !!fileVersionId && !isPreviewerDocumentLoading && !isLoading && !!previewerContainer.current) {
            tryLoadDocument(previewerNode, fileVersionId, searchTerm, previewerContainer);
        }
    }, [previewerInstance, hasAuthUser, previewerNode, fileVersionId, isLoading]);

    return (
        <>
            {!previewerInstance && !isPreviewerDocumentLoading && isLoading && 
                <span className='h-100'>
                    <SkeletonTheme enableAnimation height='100%' width='100%'>
                        <Skeleton count={1} className='mb-3 mx-auto' />
                    </SkeletonTheme>
                </span>
            }
            <div className='h-100' ref={previewerContainer} hidden={!previewerInstance && isPreviewerDocumentLoading && isLoading}></div>
        </>);
};