import React, { useEffect, useState } from 'react';
import Tour, { ReactourStep } from 'reactour';
import { Button, Text } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, useSafeTranslation, AtlantisTourTKeys } from '../../../hooks/useSafeTranslation';
import { useLayerContext } from '../../../context/layer/LayerContext';
import { TourType } from '../tourTypes';
import './AtlantisTour.css';

export interface AtlantisTourStep extends ReactourStep {
    isNavigationStep?: boolean;
    includeWhen?: () => boolean;
}

export interface AtlantisTourProps {
    steps: AtlantisTourStep[];
    isOpen: boolean;
    tourType: TourType;
    onClose?: () => void;
    closeButtonText?: string;
    hideCloseButton?: boolean;
}

export const AtlantisTour = (props: AtlantisTourProps) => {

    const { steps, isOpen, tourType, onClose, closeButtonText, hideCloseButton = false } = props;

    const filterSteps = (steps: AtlantisTourStep[]) => {
        return steps.filter(s => !s.includeWhen || s.includeWhen());
    };

    const [activeSteps, setActiveSteps] = useState(filterSteps(steps));
    const [currentStepNumber, setCurrentStepNumber] = useState(0);
    const [hideNavigation, setHideNavigation] = useState(false);
    const [forceUpdate, setForceUpdate] = useState('');

    const { toggleTour } = useLayerContext();
    const { t } = useSafeTranslation(TranslationFiles.AtlantisTour);

    const handleClose = () => {
        setCurrentStepNumber(0);
        toggleTour(tourType);

        if (!!onClose) {
            onClose();
        }
    };

    const handleNextStep = () => {
        setCurrentStepNumber(current => ++current);
    };

    const handlePrevStep = () => {
        setCurrentStepNumber(current => --current);
    };

    const addOnClickHandler = (domNode: any) => {
        if (!!domNode) {
            domNode.onclick = handleNextStep;
        }
    };

    const shouldHidePrevButton = () => {
        if (hideNavigation || currentStepNumber === 0) {
            return true;
        }

        const previousStepIsNavStep = !!activeSteps[currentStepNumber - 1]?.isNavigationStep;

        return previousStepIsNavStep;
    };

    const updateNavigation = (stepNum: number) => {
        const activeStep = activeSteps[stepNum];

        setHideNavigation(!!activeStep?.isNavigationStep);

        setForceUpdate(`${Math.random()}`);
    };

    useEffect(() => {
        if (!!isOpen) {

            steps.forEach(s => {
                if (s.isNavigationStep && !s.action) {
                    s.action = addOnClickHandler;
                    s.stepInteraction = true;
                }
            });

            const filteredSteps = filterSteps(steps);
            setActiveSteps(filteredSteps);
            setHideNavigation(!!filteredSteps[currentStepNumber]?.isNavigationStep);
        }

    }, [isOpen]);

    return (
        <Tour
            steps={activeSteps}
            isOpen={!!isOpen}
            onRequestClose={handleClose}
            nextStep={handleNextStep}
            prevStep={handlePrevStep}
            prevButton={(<Button hidden={shouldHidePrevButton()} emphasis='low' icon={{ icon: ['far', 'arrow-left'] }}></Button>)}
            nextButton={(<Button hidden={hideNavigation} emphasis='low'>{t(AtlantisTourTKeys.NextButton)}</Button>)}
            lastStepNextButton={(<Button emphasis='low'>{closeButtonText ?? t(AtlantisTourTKeys.CloseButton)}</Button>)}
            scrollDuration={500}
            goToStep={currentStepNumber}
            getCurrentStep={updateNavigation}
            className='tour-container'
            update={forceUpdate}
            showCloseButton={!hideCloseButton}
            // rounds the edges of the tour tile, as well as the highlighted area (in px)
            rounded={10}
            // makes it so you can't click inside of the highlighted component (can be overridden on individual steps)
            disableInteraction={!hideNavigation}
            // prevent user from closing tour by clicking on background
            closeWithMask={false}
            // this allows users to interact with input, if we ever wanted to guide them through a form (or allow them to search)
            disableFocusLock
            // the rest hides all of the default navigation from component
            showNavigation={false}
            showNavigationNumber={false}
            showNumber={false}

            // DON'T USE THIS PROP, it causes inconsistencies in rendering when it's the first time and re-runs
            // startAt={0}
        >
            {activeSteps.length > 1 &&
                <Text muted className='step-counter'>
                    {currentStepNumber + 1}{t(AtlantisTourTKeys.StepCounter)}{activeSteps.length}
                </Text>
            }
        </Tour>
    );
};
