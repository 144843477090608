import { useEffect, useState } from 'react';
import { useAuthContext } from '../auth';
import { AccountBrandingDTO } from '../api/branding/brandingApiTypes';
import { useBrandingApi } from '../api/branding/useBrandingApi';

export const useAccountBranding = () => {
    const [accountBranding, setAccountBranding] = useState<AccountBrandingDTO>();

    const {getBrandingByAccountIdentifier} = useBrandingApi();
    const { authUser, hasAuthUser, userAccounts, hasUserAccounts } = useAuthContext();

    const  loadExistingBranding = async () => {
        if (!authUser?.accountID || !userAccounts) {
            return;
        }

        const accountIdentifier = userAccounts.get(authUser.accountID)?.identifier;
        if (!accountIdentifier) {
            return;
        }

        const branding = await getBrandingByAccountIdentifier(accountIdentifier);

        if (!!branding) {
            setAccountBranding(branding);
        }
    };

    useEffect(() => {
        loadExistingBranding();
    }, [hasAuthUser, hasUserAccounts]);

    return {
        accountBranding,
    };

};