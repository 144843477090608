import React, { useEffect, useState } from 'react';
import { SidesheetPage } from '../../../SideSheet/SidesheetPage';
import { NotesList } from './NotesList/NotesList';
import { NotesZeroStateComponent } from './NotesZeroState';
import { NotesSkeletonLoader } from './NotesSkeletonLoader';
import { NewNote } from './NewNote';
import { NotesTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { useLayerContext } from '../../../../context/layer/LayerContext';
import { ThemeEnum } from '../../../../hooks/useColors';
import { useNodeNotesApi } from '../../../../api/nodeNotes/useNodeNotesApi';
import { NodeNoteDTO } from '../../../../api/nodeNotes/nodeNoteApiTypes';
import './Notes.css';

interface NotesProps {
    onClose?: () => void;
    nodeId: string;
}

export const Notes = ({ onClose, nodeId }: NotesProps) => {

    const { t } = useSafeTranslation(TranslationFiles.Notes);

    const { getNodeNotesAsync } = useNodeNotesApi();
    const { showToastMessage, closeToastMessage } = useLayerContext();
    const [nodeNotes, setNodeNotes] = useState<NodeNoteDTO[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const getNodeNotes = async (showSkeletonLoader = true) => {
        if (showSkeletonLoader) {
            setIsLoading(true);
        }
        try {
            closeToastMessage('error-loading-notes-toast');
            const response = await getNodeNotesAsync(nodeId);
            setNodeNotes(response);
        }
        catch (error) {
            console.error('Error fetching Note data:', error);
            showToastMessage({
                color: ThemeEnum.Danger,
                identifier: 'error-loading-notes-toast',
                message: t(NotesTKeys.ErrorLoadingNotes),
            });
        }
        finally {
            setIsLoading(false);
        }
    };

    const onNewNoteCreated = (note: NodeNoteDTO) => {
        setNodeNotes([...nodeNotes, note]);
    };

    const onNoteDelete = (nodeId: number) => {
        setNodeNotes([...nodeNotes.filter(note => note.id !== nodeId)]);
    };
    const onNoteEdit = (editedNote: NodeNoteDTO) => {
        setNodeNotes([...nodeNotes.map(note => note.id === editedNote.id ? editedNote : note)]);
    };

    useEffect(() => { getNodeNotes(); }, [nodeId]);

    return (
        <SidesheetPage title={t(NotesTKeys.Title)} onClose={onClose} dataId='nodes-sideSheet-pane'>
            {!!isLoading
                ? <NotesSkeletonLoader />
                : (
                    <div className='notes-parent'>
                        <div className='notes-content'>
                            {!nodeNotes.length
                                ? <NotesZeroStateComponent />
                                : <NotesList nodeNotes={nodeNotes} onNoteDelete={onNoteDelete} onNoteEdit={onNoteEdit} />
                            }
                        </div>
                        <NewNote nodeId={nodeId} onNewNoteCreated={onNewNoteCreated} />
                    </div>
                )
            }
        </SidesheetPage>
    );
};
