import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Card, EfcFeedItemInfo, Feed, Text } from '@efilecabinet/efc-atlantis-components';
import { useAuthContext } from '../../../auth';
import { useWorkYouCareAboutApi } from '../../../api/workYouCareAbout/useWorkYouCareAboutApi';
import { useUtopiaNotificationsConverter } from '../../../hooks/useUtopiaNotificationsConverter';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { useBootstrapBreakpoints } from '../../../hooks/bootstrap/useBootstrapBreakpoints';
import { TranslationFiles, useSafeTranslation, WorkYouCareAboutTKeys } from '../../../hooks/useSafeTranslation';
import { ScreenSize } from '../../../hooks/bootstrap/bootstrapTypes';
import { ZeroState } from '../../../components/ZeroState';
import { NotificationOrderEnum, NotificationStatusEnum, UtopiaNotificationDTO } from '../../../api/notifications/notificationApiTypes';
import zeroStateImg from '../../../assets/images/workYouCareAboutZeroState.svg';
import './workYouCareAboutWidget.css';

export interface WorkYouCareAboutWidgetProps {
    hideIfEmpty: boolean;
    className?: string;
}

export const WorkYouCareAboutWidget = (props: WorkYouCareAboutWidgetProps) => {
    const { hideIfEmpty, className } = props;

    const [workYouCareAboutFeedItems, setWorkYouCareAboutFeedItems] = useState<EfcFeedItemInfo[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorLoading, setErrorLoading] = useState<boolean>(false);

    const { t } = useSafeTranslation(TranslationFiles.WorkYouCareAbout);
    const { hasAuthUser } = useAuthContext();
    const { getUserWorkYouCareAbout } = useWorkYouCareAboutApi();
    const { toFeedItem, eSignatureModal } = useUtopiaNotificationsConverter();
    const { getLinkToWorkYouCareAbout } = useRouting();
    const { screenSize } = useBootstrapBreakpoints();

    const MAX_ROWS_TO_DISPLAY = 8;
    const SKELETON_COUNT = 8;

    const getUtopiaNotifications = () => {
        getUserWorkYouCareAbout([NotificationStatusEnum.Active], NotificationOrderEnum.QueuedOnDesc, 0, MAX_ROWS_TO_DISPLAY + 1)
            .then((workYouCareAbout: UtopiaNotificationDTO[]) => {
                setWorkYouCareAboutFeedItems(workYouCareAbout.map((workItem) => toFeedItem(workItem)));
            })
            .catch(() => {
                setErrorLoading(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (!!hasAuthUser) {
            getUtopiaNotifications();
        }
    }, [hasAuthUser]);

    return (
        <>
            {(!hideIfEmpty || (hideIfEmpty && workYouCareAboutFeedItems.length > 0)) &&
                <Card fluid borderless className={className}>
                    <Card.Body dataId='workYouCareAboutBody'>
                        <Card.Title tag={Link} size='xl' bold icon={{ icon: ['far', 'clipboard-check'] }} to={getLinkToWorkYouCareAbout()}>
                            {t(WorkYouCareAboutTKeys.WorkYouCareAboutTitle)}
                        </Card.Title>
                        {!!isLoading || !!errorLoading ? (
                            <>
                                {!!isLoading && (
                                    <SkeletonTheme enableAnimation height='24px' width='100%'>
                                        <Skeleton count={SKELETON_COUNT} className='mb-3 mx-auto' />
                                    </SkeletonTheme>
                                )}

                                {!!errorLoading && <Card.Text> {t(WorkYouCareAboutTKeys.ErrorLoading)} </Card.Text>}
                            </>
                        ) : (
                            <>
                                {workYouCareAboutFeedItems.length == 0 ? (
                                    <ZeroState
                                        imageColContent={<img src={zeroStateImg} alt={t(WorkYouCareAboutTKeys.ZeroStateImgAltText)} />}
                                        textColContent={
                                            <>
                                                <Text bold>
                                                    {t(WorkYouCareAboutTKeys.NoItemsMainText)}
                                                </Text>
                                                <br />
                                                <Text>
                                                    {t(WorkYouCareAboutTKeys.NoItemsSubText)}
                                                </Text>
                                            </>
                                        }
                                        stackColumns={screenSize < ScreenSize.xl} />
                                ) : (
                                    <Feed className='m-2' small feedItems={workYouCareAboutFeedItems} maxRowsToDisplay={MAX_ROWS_TO_DISPLAY} tag={Link} seeMoreTo={getLinkToWorkYouCareAbout()} />
                                )}
                            </>
                        )}
                    </Card.Body>
                </Card>
            }
            
            {eSignatureModal}
        </>
    );
};
