import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Text, Toggle } from '@efilecabinet/efc-atlantis-components';
import { useAuthApi, useAuthContext } from '../../../auth';
import { useOptInApi } from '../../../api/optIn/useOptInApi';
import { useSettingsAndFeatures } from '../../../context/settingsAndFeatures/SettingsFeaturesContext';
import { OptOutToggleNavItemTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { RoutePath } from '../../../hooks/Routing/routingTypes';
import { OptOutModal } from './Help/Feedback/OptOutModal';

export const OptOutToggleNavItem = () => {
    const [isChecked, setIsChecked] = useState(true);
    const [showOptOutFeedback, setShowOptOutFeedback] = useState(false);

    const { authUser, optInInfo } = useAuthContext();
    const { optOut } = useOptInApi();
    const { settings } = useSettingsAndFeatures();
    const { getOneTimeToken } = useAuthApi();
    const navigate = useNavigate();
    const { t } = useSafeTranslation(TranslationFiles.OptOutToggleNavItem);

    const completeOptOut = async (optedOut: boolean) => {
        if (optedOut && !!authUser?.userID) {
            navigate(`${RoutePath.Login}?optingout=true`);
            await optOut(authUser?.userID);
            const oneTimeToken = await getOneTimeToken();
            window.location.replace(`${settings?.utopiaUrl}/#/auth/${oneTimeToken}`);
        } else {
            toggleOptOutFeedback();
        }
    };

    const toggleOptOutFeedback = () => {
        setIsChecked((prevState) => !prevState);
        setShowOptOutFeedback((prevState) => !prevState);
    };

    return (
        <>
            {optInInfo?.canOptOutAtlantis && (
                <>
                    <Text className='me-2'>{t(OptOutToggleNavItemTKeys.NewExperience)}</Text>
                    <Toggle isChecked={isChecked} onClick={toggleOptOutFeedback} className='me-3 opt-out-toggle' />
                    <OptOutModal showOptOutModal={showOptOutFeedback} toggleModal={toggleOptOutFeedback} onOptOutSubmission={completeOptOut} />
                </>
            )}
        </>
    );
};
