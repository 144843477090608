import { useNavigate } from 'react-router';
import { RoutePath, SalesforceRouteParams } from './routingTypes';

export const useSalesforceRouting = () => {

    const navigate = useNavigate();

    const getLinkToSalesforce = () => {
        return RoutePath.Salesforce;
    };

    const routeToSalesforce = () => {
        navigate(getLinkToSalesforce());
    };

    const getLinkToSalesforceMappingNew = () => {
        return RoutePath.SalesforceMappingNew;
    };

    const routeToSalesforceMappingNew = () => {
        navigate(getLinkToSalesforceMappingNew());
    };

    const getLinkToSalesforceMappingEdit = (mappingId: string) => {
        return RoutePath.SalesforceMappingEdit
            .replace(SalesforceRouteParams.MappingId, mappingId);
    };

    const routeToSalesforceMappingEdit = (mappingId: string) => {
        navigate(getLinkToSalesforceMappingEdit(mappingId));
    };

    return {
        getLinkToSalesforce,
        getLinkToSalesforceMappingNew,
        getLinkToSalesforceMappingEdit,
        routeToSalesforce,
        routeToSalesforceMappingNew,
        routeToSalesforceMappingEdit,
    };
};
