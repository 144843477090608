import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { NodePermissionDTO } from './nodePermissionApiTypes';

export const useNodePermissionsApi = () => {

    const getPermissionByNodeIdAsync = async (nodeId: string): Promise<NodePermissionDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/NodePermissions/NodeId/${nodeId}`);
        return resp.data;
    };

    return {
        getPermissionByNodeIdAsync,
    };
};
