import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useLocation, useParams } from 'react-router';
import { Card, Page } from '@efilecabinet/efc-atlantis-components';
import { useAuthContext } from '../../../auth';
import { useSafeTranslation, TranslationFiles } from '../../../hooks/useSafeTranslation';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import { useSyncUtopiaDocumentsIFrameToAtlantisRoute } from '../../../hooks/useSyncUtopiaDocumentsIFrameToAtlantisRoute';
import { IFrame } from '../../../components/IFrame/IFrame';
import { useResizableColUtilities } from '../../../hooks/useResizableColUtilities';
import { FilterItemDTO, UserSearchDTO, FilterComparatorEnum, FilterTypeEnum } from '../../../api/search/searchApiTypes';
import { useSearchApi } from '../../../api/search/useSearchApi';
import { SearchRoutePath, RoutePath, SearchRouteParams } from '../../../hooks/Routing/routingTypes';
import './searchPage.css';

interface SearchState {
    searchInputValue: string;
    searchRequestFilterItem: FilterItemDTO;
    toggleAdvancedSearch?: boolean;
}

const IFrameRoutes: Map<SearchRoutePath, string> = new Map([
    [RoutePath.Search, '/search'],
    [RoutePath.UserSearch, `/search/${SearchRouteParams.UserSearchId}`],
    [RoutePath.SharedSearch, `/search?sharedSearchId=${SearchRouteParams.SharedSearchId}`],
]);

export const SearchPage = () => {

    const location = useLocation();
    const searchState = location.state as SearchState;
    const { userSearchId, sharedSearchId } = useParams();
    const { getShowOverlay } = useResizableColUtilities();
    const { user } = useAuthContext();
    const { t } = useSafeTranslation(TranslationFiles.Search);
    const { createUserSearchAsync } = useSearchApi();
    const iframe = useUtopiaIFrame();

    const createUserSearchFromInputValue = (inputValue: string) => {
        const userSearch = new UserSearchDTO();

        if (!!user?.preferences) {
            const defaultComparator = user.preferences.searchDefaultUseContainsWordsIn ? FilterComparatorEnum.ContainsWords : FilterComparatorEnum.Like;

            if (user.preferences.searchDefaultByCreatedByName) {
                userSearch.filterItem.orCriteria.push(new FilterItemDTO(FilterTypeEnum.CreatedByName, inputValue, defaultComparator));
            }
            if (user.preferences.searchDefaultByProfileValue) {
                userSearch.filterItem.orCriteria.push(new FilterItemDTO(FilterTypeEnum.ProfileValue, inputValue, defaultComparator));
            }
            if (user.preferences.searchDefaultByComment) {
                userSearch.filterItem.orCriteria.push(new FilterItemDTO(FilterTypeEnum.Comment, inputValue, defaultComparator));
            }
            if (user.preferences.searchDefaultByPath) {
                userSearch.filterItem.orCriteria.push(new FilterItemDTO(FilterTypeEnum.Path, inputValue, defaultComparator));
            }
            if (user.preferences.searchDefaultByFullText) {
                userSearch.filterItem.orCriteria.push(new FilterItemDTO(FilterTypeEnum.FullText, inputValue, FilterComparatorEnum.Like));
            }
            if (user.preferences.searchDefaultByName || userSearch.filterItem.orCriteria.length == 0) {
                userSearch.filterItem.orCriteria.push(new FilterItemDTO(FilterTypeEnum.Name, inputValue, defaultComparator));
            }
        } else {
            userSearch.filterItem.orCriteria.push(new FilterItemDTO(FilterTypeEnum.Name, inputValue, FilterComparatorEnum.Like));
        }

        return userSearch;
    };

    const runSearchInIFrame = (userSearch: UserSearchDTO, searchValue: string) => {
        createUserSearchAsync(userSearch)
            .then(searchResponse => {
                iframe.setUrl((IFrameRoutes.get(RoutePath.UserSearch) as string).replace(SearchRouteParams.UserSearchId, searchResponse.id.toString()));
            }).catch(reason => {
                console.error(`createUserSearchAsync failed for searchValue: ${searchValue}. reason: ${reason}`);
                iframe.setUrl(IFrameRoutes.get(SearchRoutePath.Search) as string);
            });
    };

    const setIframeUrl = () => {
        if (!!searchState?.searchInputValue) {
            runSearchInIFrame(createUserSearchFromInputValue(searchState.searchInputValue), searchState.searchInputValue);
        } else if (!!searchState?.searchRequestFilterItem) {
            runSearchInIFrame(new UserSearchDTO(undefined, undefined, searchState.searchRequestFilterItem, undefined, undefined, undefined), searchState.searchInputValue);
        } else if (!!userSearchId) {
            iframe.setUrl((IFrameRoutes.get(SearchRoutePath.UserSearch) as string).replace(SearchRouteParams.UserSearchId, userSearchId));
        } else if (!!sharedSearchId) {
            iframe.setUrl((IFrameRoutes.get(SearchRoutePath.SharedSearch) as string).replace(SearchRouteParams.SharedSearchId, sharedSearchId));
        } else {
            let url = IFrameRoutes.get(SearchRoutePath.Search) as string;
            if (!!searchState?.toggleAdvancedSearch) {
                url = url + '?toggleAdvancedSearch=true';
            }
            iframe.setUrl(url);
        }
    };

    // Sets up a listener for if a user clicks the "Go To" button on a search result
    useSyncUtopiaDocumentsIFrameToAtlantisRoute();

    useEffect(() => {
        setIframeUrl();
    }, [searchState, userSearchId, sharedSearchId]);

    return (
        <Page>
            <Row className='searchRow pt-3 h-100'>
                <Col className='h-100 pe-0'>
                    <Card fluid className='h-100 utopia-iframe-border-overlay'>
                        {!!getShowOverlay() && <div className='overlay'></div>}
                        <IFrame className='iframe' src={iframe.url} />
                    </Card>
                </Col>
            </Row>
        </Page>
    );
};
