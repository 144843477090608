import { useState } from 'react';
import { useSettingsAndFeatures } from '../context/settingsAndFeatures/SettingsFeaturesContext';
import { FeatureFlags } from '../context/settingsAndFeatures/settingsAndFeaturesTypes';

export const usePreviewerXFeedback = () => {
    const { checkFeatureFlag } = useSettingsAndFeatures();

    const [docViewCount, setDocViewCount] = useState(0);
    const [previewerXSessionCount, setPreviewerXSessionCount] = useState(0);

    const updateDocViewCount = (userId: number) => {
        if (checkFeatureFlag(FeatureFlags.PreviewerXEnabled)) {
            const countDocPreviewStored = localStorage.getItem(`previewerx-docview-count-${userId}`) ?? '0';
            const docviewTotal = +countDocPreviewStored + 1;

            setDocViewCount(docviewTotal);

            localStorage.setItem(`previewerx-docview-count-${userId}`, `${docviewTotal}`);
        }
    };

    const updatePreviewerXSessionCount = (userId: number) => {
        const countLoginStored = localStorage.getItem(`previewerx-login-count-${userId}`) ?? '0';

        const loginTotal = +countLoginStored + 1;

        setPreviewerXSessionCount(loginTotal);

        localStorage.setItem(`previewerx-login-count-${userId}`, `${loginTotal}`);
    };

    return {
        docViewCount,
        updateDocViewCount,

        previewerXSessionCount,
        updatePreviewerXSessionCount,
    };
};
