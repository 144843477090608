import React from 'react';
import { useBootstrapBreakpoints } from '../../../../hooks/bootstrap/useBootstrapBreakpoints';
import { ScreenSize } from '../../../../hooks/bootstrap/bootstrapTypes';
import { useAuthContext } from '../../../../auth';
import { FileUploadNavMenuItem } from '../FileUpload';
import { HelpNavMenuItem } from '../Help';
import { RecentsNavMenuItem } from '../Recents';
import { AccountsNavMenuItem } from '../Accounts';
import '../NavMenu.css';

export const AnonymousExperienceNavItems = () => {

    const { userCanViewDocuments } = useAuthContext();
    const { screenSize } = useBootstrapBreakpoints();

    return (
        <>
            {screenSize > ScreenSize.xs && (
                <>
                    <FileUploadNavMenuItem />
                    <HelpNavMenuItem />
                    {userCanViewDocuments &&
                        <RecentsNavMenuItem />}
                </>
            )}
            <AccountsNavMenuItem className='ms-2' showAnonymousElementsOnly={true} />
        </>
    );
};
