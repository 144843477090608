import { useState } from 'react';
import { useAccessLinksApi } from '../api/accessLinks/useAccessLinksApi';
import { AccessLinkDTO } from '../api/accessLinks/accessLinksApiTypes';

export const useAccessLinks = () => {

    const { getAccessLinksByNodeIdAsync } = useAccessLinksApi();

    const getNodeAccessLinksAsync = async (accountId: string, nodeId: string) => {
        return await getAccessLinksByNodeIdAsync(accountId, nodeId);
    };

    const filterNodeAccessLinksByUserId = (accessLinks: AccessLinkDTO[], createdByUserId: string) => {
        return accessLinks.filter(accessLink => accessLink.createdByUserID?.toString() === createdByUserId);
    };

    const filterOtherNodeAccessLinksByUserId = (accessLinks: AccessLinkDTO[], createdByUserId: string) => {
        return accessLinks.filter(accessLink => accessLink.createdByUserID?.toString() !== createdByUserId);
    };

    return {
        getNodeAccessLinksAsync,
        filterNodeAccessLinksByUserId,
        filterOtherNodeAccessLinksByUserId,
    };
};