import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Page } from '@efilecabinet/efc-atlantis-components';
import { IFrame } from '../../../components/IFrame/IFrame';
import { AuthContext, IAuthContext } from '../../../auth';
import { useIcons } from '../../../hooks/useIcons';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { TranslationFiles, useSafeTranslation, FormsTKeys } from '../../../hooks/useSafeTranslation';
import { AccountFeatureUsageHeader } from '../../../components/AccountFeatureUsageHeader/AccountFeatureUsageHeader';
import { useFormsApi } from '../../../api/forms/useFormsApi';
import { NodeDTO } from '../../../api/node/nodeApiTypes';
import { useNodeApi } from '../../../api/node/useNodeApi';
import { AccountFeatureEnum } from '../../../api/accountFeatures/accountFeatureApiTypes';
import './formsPage.css';

export const FormsPage = () => {
    const { hasAuthUser, authUser } = useContext(AuthContext) as IAuthContext;
    const { t } = useSafeTranslation(TranslationFiles.Forms);

    const formsUrl = '/admin/template/formfill';
    const iframe = useUtopiaIFrame(formsUrl);

    const [formFillTemplates, setFormFillTemplates] = useState<NodeDTO[]>([]);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [formToDelete, setFormToDelete] = useState<NodeDTO | null>(null);
    const [formFillLibraryRoute, setFormFillLibraryRoute] = useState<string>('');

    const { getTemplateLibraries, getNodeChildrenAsync } = useNodeApi();
    const { getLinkToFolderTemplate } = useRouting();

    const { getNodeIconProps } = useIcons();
    const { getFormFillTemplates } = useFormsApi();
    const { deleteNodeById } = useNodeApi();

    const getIcon = (item: NodeDTO): FontAwesomeIconProps => {
        return getNodeIconProps(item.systemType, item.fileInfo?.fileExtension);
    };

    const getTemplateData = async (accountId: number) => {
        const templates = await getFormFillTemplates(accountId);
        setFormFillTemplates(templates);
    };

    const openDeleteConfirmation = () => setShowDeleteConfirmation(true);
    const closeDeleteConfirmation = () => setShowDeleteConfirmation(false);

    const deleteForm = (item?: NodeDTO) => {
        if (!!item) {
            setFormToDelete(item);
            openDeleteConfirmation();
        }
    };

    const confirmDelete = async (confirm: boolean) => {
        closeDeleteConfirmation();

        if (!!confirm && !!formToDelete) {
            await deleteNodeById(formToDelete.id);
            await getTemplateData(authUser?.accountID as number);
        }

        setFormToDelete(null);
    };

    const columns = [
        { name: 'name', displayName: t(FormsTKeys.NameColumn), useIcon: getIcon, linkTo: (node: NodeDTO) => formFillLibraryRoute },
        { name: 'createdOn', displayName: t(FormsTKeys.CreatedOnColumn), isDate: true },
        { name: 'createdBy', displayName: t(FormsTKeys.CreatedByColumn) },
    ];

    const contextActions = [
        { text: t(FormsTKeys.DeleteButton), icon: { icon: 'trash-can' }, onClick: deleteForm }
    ];

    const deleteConfirmationCtas = [
        {
            text: t(FormsTKeys.CancelButton),
            emphasis: 'med',
            color: 'primary',
            onClick: () => confirmDelete(false),
        },
        {
            text: t(FormsTKeys.DeleteButton),
            emphasis: 'high',
            color: 'primary',
            onClick: () => confirmDelete(true),
        },
    ];

    const getFormFillLibraryRoute = async (accountId: number) => {
        const nameOfFormFillLibrary = 'Form Fill Template Library';

        const templateLibraries: NodeDTO[] = await getTemplateLibraries(accountId);
        const libs = templateLibraries.filter((item) => item.name === nameOfFormFillLibrary);
        const libId = libs[0].id;

        const templates: NodeDTO[] = await getNodeChildrenAsync(Number(libId));
        const templateId = templates[0].id;

        const routeTo = getLinkToFolderTemplate(libId, templateId);
        setFormFillLibraryRoute(routeTo);
    };

    useEffect(() => {
        if (!!authUser) {
            getTemplateData(authUser.accountID);

            getFormFillLibraryRoute(authUser.accountID);
        }
    }, [hasAuthUser]);

    return (
        <Page title={t(FormsTKeys.FormsTitle)} icon={{ icon: ['far', 'clipboard-list'] }}>
            <AccountFeatureUsageHeader accountFeatureTypes={[AccountFeatureEnum.FormFill]} />

            {/*<div className='my-2'>*/}
            {/*    <FullTable tag={Link} data={formFillTemplates} columns={columns} selectable={false} searchable={false} contextActions={contextActions} />*/}
            {/*    {formFillTemplates.length === 0 &&*/}
            {/*        <Text>*/}
            {/*            {t(FormsKeys.EmptyTemplatesMessage)}*/}
            {/*        </Text>*/}
            {/*    }*/}
            {/*</div>*/}

            <IFrame className='iframe' src={iframe.url} />

            {/*<Modal title={t(FormsKeys.DeleteConfirmationTitle)} toggle={closeDeleteConfirmation} isOpen={showDeleteConfirmation} ctas={deleteConfirmationCtas} >*/}
            {/*    <Modal.Body>*/}
            {/*        {t(FormsKeys.DeleteConfirmationMessage)}*/}
            {/*    </Modal.Body>*/}
            {/*</Modal>*/}
        </Page>
    );
};
