import { AtlantisTaskDTO } from '../api/tasks/taskApiTypes';
import { useTaskApi } from '../api/tasks/useTaskApi';
import { useAuthContext } from '../auth';
import { IToDo, SourceTypeEnum } from '../features/_toDoList/toDoListTypes';

interface IAtlantisConverterProps {
    atlantisTasks: AtlantisTaskDTO[];
    setAtlantisTasks: (atlantisTasks: AtlantisTaskDTO[]) => void;
}

export const useAtlantisTaskConverter = (props: IAtlantisConverterProps) => {
    const { atlantisTasks, setAtlantisTasks } = props;

    const { authUser } = useAuthContext();
    const { completeAtlantisTask } = useTaskApi();

    const handleOnCompleteAtlantisTask = (taskId: string) => {
        const updatedAtlantisTasks = atlantisTasks
            .filter((task) => task.taskId !== taskId)
            .map((e) => {
                return { ...e };
            });
        setAtlantisTasks(updatedAtlantisTasks);
        completeAtlantisTask(authUser?.userID as number, taskId);
    };

    const toAtlantisToDo = (task: AtlantisTaskDTO): IToDo => {
        return {
            ...task,
            id: task.taskId,
            icon: { icon: 'circle-check' },
            from: 'Personal task',
            cta: {
                color: 'primary',
                emphasis: 'med',
                text: 'Mark Complete',
                onClick: () => handleOnCompleteAtlantisTask(task.taskId),
            },
            sourceType: SourceTypeEnum.Atlantis,
            completedDate: task.completedDate,
            createdDate: task.createdDate,
            dismissedDate: task.dismissedDate,
        };
    };

    return {
        toAtlantisToDo,
    };
};