import React, { useEffect, useState } from 'react';
import { EfcLink, Text } from '@efilecabinet/efc-atlantis-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useFileUtilities } from '../../../../hooks/useFileUtilities';
import { useUtilities } from '../../../../hooks/useUtilities';
import { useNodeDetailsContext } from '../../../../context/nodeDetails/NodeDetailsContext';
import { useSettingsAndFeatures } from '../../../../context/settingsAndFeatures/SettingsFeaturesContext';
import { TranslationFiles, useSafeTranslation, NodeDetailsTKeys } from '../../../../hooks/useSafeTranslation';
import { SidesheetPage } from '../../../SideSheet/SidesheetPage';
import { FileVersions } from './FileVersions/FileVersions';
import { NodeIcon } from '../../../Icons/NodeIcon/NodeIcon';
import { NodeDetailsItem } from './NodeDetailsItem';
import { NodeDetailsDTO, NodeType } from '../../../../api/node/nodeApiTypes';
import { useNodeApi } from '../../../../api/node/useNodeApi';
import './NodeDetails.css';

interface NodeDetailsProps {
    onClose?: () => void;
    active: boolean;
}

export const NodeDetails = (props: NodeDetailsProps) => {
    const { onClose, active } = props;

    const { getFileExtension } = useFileUtilities();
    const { getNodeDetailsByIdAsync, getNodeDetailTemplateNamesByIdAsync } = useNodeApi();
    const { activeNode, activeFileVersion, isLoadingNode, nodeTypeIsNotAFile, fetchFileVersions } = useNodeDetailsContext();
    const { t } = useSafeTranslation(TranslationFiles.NodeDetails);
    const { checkFeatureFlag } = useSettingsAndFeatures();
    const { getSizeDisplay } = useUtilities();

    const [fileSize, setFileSize] = useState('');
    const [nodeDetails, setNodeDetails] = useState<NodeDetailsDTO>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [fetchedDetails, setFetchedDetails] = useState<boolean>(false);

    const fetchNodeDetails = async (nodeId: string, allDetails: boolean) => {
        try {
            if(allDetails){
                const nodeDetails = await getNodeDetailsByIdAsync(nodeId);
                setFetchedDetails(true);
                setNodeDetails(nodeDetails);
            } else {
                const nodeDetails = await getNodeDetailTemplateNamesByIdAsync(nodeId);
                setNodeDetails(nodeDetails);
            }
        } catch (error) {
            setNodeDetails({templateNames: [], fileCount: 0, containerCount: 0, size: 0});
            console.error('Error fetching data:', error);
        }
    };

    const getContainerText = () => {
        switch (activeNode?.systemType) {
            case NodeType.Account:
                return t(NodeDetailsTKeys.AccountContainer);
            case NodeType.Cabinet:
                return t(NodeDetailsTKeys.CabinetContainer);
            case NodeType.Drawer:
            case NodeType.Folder:
                return t(NodeDetailsTKeys.FoldersContainer);
        }
    };

    useEffect(() => {
        if (!!activeNode && !!activeNode.id){
            setFetchedDetails(false);
            if (!nodeTypeIsNotAFile) {
                fetchFileVersions(activeNode.id);
            } else {
                fetchNodeDetails(activeNode.id, false);
            }
        }
    }, [activeNode]);

    useEffect(() => {
        if (!nodeTypeIsNotAFile && !!activeFileVersion && !!activeNode && activeFileVersion.node.id == activeNode?.id && !!activeFileVersion.size) {
            const size = getSizeDisplay(activeFileVersion.size);
            setFileSize(size);
        } else {
            setFileSize('');
        }
        setIsLoading(false);
    }, [activeFileVersion]);

    useEffect(() => {
        if (!!nodeTypeIsNotAFile && !!nodeDetails && !!nodeDetails.size) {
            const size = getSizeDisplay(nodeDetails.size);
            setFileSize(size);
        } else {
            setFileSize('');
        }
        setIsLoading(false);
    }, [nodeDetails]);

    useEffect(() => {
        if(!!isLoadingNode && !isLoading){
            setIsLoading(true);
        }
    }, [isLoadingNode]);

    return (
        <>
            <SidesheetPage
                title={t(NodeDetailsTKeys.Details)}
                onClose={onClose}
                dataId='details-sideSheet-pane'
                hidden={!active}>
                {!!isLoading && (
                    <>
                        <SkeletonTheme enableAnimation height='156px' width='100%'>
                            <Skeleton count={1} className='mb-3 mx-auto' />
                        </SkeletonTheme>
                        <SkeletonTheme enableAnimation height='24px' width='100%'>
                            <Skeleton count={5} className='mb-3 mx-auto' />
                        </SkeletonTheme>
                        <hr style={{ color: '#8e8e8e' }} />
                        <SkeletonTheme enableAnimation height='24px' width='100%'>
                            <Skeleton count={3} className='mb-3 mx-auto' />
                        </SkeletonTheme>
                    </>
                )}
                {!isLoading && !!activeNode && (
                    <>
                        <NodeIcon
                            nodeType={activeNode.systemType}
                            fileExtension={getFileExtension(activeNode.name)}
                            customIconClassName={activeNode.systemType == NodeType.Cabinet ? 'details-cabinet-size' : 'details-drawer-size'}
                            fontAwesomeClassName='mt-3'
                            fontAwesomeSize='7x' />
                        <Text className='mt-4 align-self-center' size='lg' semibold>
                            {activeNode?.name}
                        </Text>

                        {(!fileSize && !fetchedDetails) && (
                            <EfcLink className='details-calculate-link' onClick={async () => fetchNodeDetails(activeNode.id, true)} dataId='linkcalcsize' color='primary'>
                                {t(NodeDetailsTKeys.Calculate)}
                            </EfcLink>
                        )}

                        {(!!fileSize || !!fetchedDetails) && (
                            <NodeDetailsItem titleText={t(NodeDetailsTKeys.Size)} contentText={!fileSize ? '0' : fileSize} />
                        )}

                        {nodeTypeIsNotAFile && !!fetchedDetails && (
                            <NodeDetailsItem titleText={t(NodeDetailsTKeys.Files)} contentText={nodeDetails?.fileCount.toString()} />
                        )}

                        {nodeTypeIsNotAFile && !!fetchedDetails && (
                            <NodeDetailsItem titleText={getContainerText() as string} contentText={nodeDetails?.containerCount.toString()} />
                        )}

                        {nodeTypeIsNotAFile && (
                            <NodeDetailsItem titleText={t(NodeDetailsTKeys.Templates)} contentText={nodeDetails?.templateNames.length ? nodeDetails?.templateNames.join(', ') : t(NodeDetailsTKeys.NoTemplate)} />
                        )}

                        <NodeDetailsItem titleText={!nodeTypeIsNotAFile ? t(NodeDetailsTKeys.UploadedOn) : t(NodeDetailsTKeys.CreatedOn)} contentText={new Date(activeNode?.createdOn ?? '').toLocaleDateString()} />

                        <NodeDetailsItem titleText={!nodeTypeIsNotAFile ? t(NodeDetailsTKeys.UploadedBy) : t(NodeDetailsTKeys.CreatedBy)} contentText={activeNode?.createdBy ?? ''} />

                        <NodeDetailsItem titleText={t(NodeDetailsTKeys.NodeId)} contentText={activeNode?.id} />

                        {!nodeTypeIsNotAFile && (
                            <>
                                <hr style={{ color: '#8e8e8e' }} />
                                <FileVersions />
                            </>
                        )}
                    </>
                )}
            </SidesheetPage>
        </>
    );
};
