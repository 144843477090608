import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Text, Form, FormGroup, Label, Input, FormFeedback, RoleEnum } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, WelcomeModalTKeys, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { useAuthContext } from '../../../../auth';
import { AccountOnboardingSteps, IAccountOnboardingProps } from '../AccountOnboardingTypes';
import { CheckMark } from './CheckMark';
import { RoleDTO } from '../../../../api/roles/roleApiTypes';
import { useRoleApi } from '../../../../api/roles/useRoleApi';
import './AccountOnboardingModalComponents.css';

export const AccountOnboardingName = (props: IAccountOnboardingProps) => {
    const { currentStep, setCurrentStep, isShowNewExperience } = props;

    const { authUser } = useAuthContext();
    const { t } = useSafeTranslation(TranslationFiles.WelcomeModal);
    const { updateRole } = useRoleApi();

    const [userName, setUserName] = useState<string | undefined>(undefined);
    const [originalUserName, setOriginalUserName] = useState<string | undefined>(undefined);
    const [userNameError, setUserNameError] = useState<string | undefined>(undefined);

    const [isUpdatingName, setIsUpdatingName] = useState<boolean>(false);

    const maxUserNameSize = 45;

    const onUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newUserName = event.target.value.slice(0, maxUserNameSize);

        if (event.target.value.length > maxUserNameSize) {
            setUserNameError(t(WelcomeModalTKeys.AccountOnboardingNameError));
        } else {
            setUserNameError(undefined);
        }

        setUserName(newUserName);
    };

    const updateRoleName = async () => {
        if (originalUserName === userName) {
            return;
        }

        setIsUpdatingName(true);

        const userRole = authUser?.userRoles.find((x) => x.accountID === authUser.accountID && x.roleType === RoleEnum.User);
        if (!!userRole?.name) {
            userRole.name = userName as string;
        }

        await updateRole(userRole as RoleDTO);
        setOriginalUserName(userName);

        setIsUpdatingName(false);
    };

    const continueOnboarding = async () => {
        if (isUpdatingName) {
            return;
        }

        updateRoleName();
        setCurrentStep(AccountOnboardingSteps.SetBranding);
    };

    const editName = () => {
        setCurrentStep(AccountOnboardingSteps.SetName);
    };

    const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!userName || userName === '') {
            return;
        }

        await continueOnboarding();
    };

    const setupUserName = () => {
        const userRole = authUser?.userRoles.find((x) => x.accountID === authUser.accountID && x.roleType === RoleEnum.User);
        let name = '';

        if (userRole?.name !== userRole?.userName) {
            name = userRole?.name as string;
        }

        setUserName(name);
        setOriginalUserName(name);
    };

    useEffect(() => {
        if (userName === undefined) {
            setupUserName();
        }
    }, [userName]);

    return (
        <>
            {currentStep === AccountOnboardingSteps.SetName && (
                <Form onSubmit={handleFormSubmit}>
                    <Row>
                        <Col sm='auto' className='empty-padding-left'>
                            <CheckMark isFinished={false} showLine={true} />
                        </Col>
                        <Col className='pt-2'>
                            <Row>
                                <Col>
                                    <Text size='xl'>{isShowNewExperience ? t(WelcomeModalTKeys.AccountOnboardingNameTitleFirstTimeUser) : t(WelcomeModalTKeys.AccountOnboardingNameTitle)}</Text>
                                </Col>
                            </Row>
                            <Row className='mt-3 mb-4'>
                                <Col>
                                    <FormGroup>
                                        <Label for='userName'>
                                            {t(WelcomeModalTKeys.AccountOnboardingNameLabel)} <Text muted>({t(WelcomeModalTKeys.AccountOnboardingRequired)})</Text>
                                        </Label>
                                        <Input className='input-name' required id='userName' dataId='userName' name='userName' placeholder='Enter text' type='text' value={userName} onChange={onUserNameChange} invalid={!!userNameError} />
                                        <FormFeedback>
                                            <FontAwesomeIcon icon='exclamation-triangle' size='sm' className='icon-warning' /> {userNameError}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color='primary' onClick={continueOnboarding} className='btn-lg float-end' disabled={userName === '' || isUpdatingName}>
                                Next
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}
            {currentStep !== AccountOnboardingSteps.ShowWelcome && currentStep !== AccountOnboardingSteps.SetName && (
                <Row className='mb-3'>
                    <Col sm='auto' className='empty-padding-left'>
                        <CheckMark isFinished={true} showLine={false} />
                    </Col>
                    <Col className='pt-2'>
                        <Text size='xl'>
                            {t(WelcomeModalTKeys.AccountOnboardingNameEntered) + userName}
                            <FontAwesomeIcon icon='edit' className='icon-edit' onClick={editName} />
                        </Text>
                    </Col>
                </Row>
            )}
        </>
    );
};
