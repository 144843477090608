import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useLocation, useParams } from 'react-router';
import { Card, Page, EfcCallToActionInfo, Modal } from '@efilecabinet/efc-atlantis-components';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import { useDocumentsUtilities } from '../../../hooks/documents/useDocumentsUtilities';
import { useSyncUtopiaDocumentsIFrameToAtlantisRoute } from '../../../hooks/useSyncUtopiaDocumentsIFrameToAtlantisRoute';
import { useUtopiaIFrameRoutes } from '../../../hooks/useUtopiaIFrameRoutes';
import { DocumentsTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { RoutePath } from '../../../hooks/Routing/routingTypes';
import { IDocumentURLDetails, IIFrameURLUpdateDetails } from '../../../hooks/documents/documentsTypes';
import { IFrame } from '../../../components/IFrame/IFrame';
import { useResizableColUtilities } from '../../../hooks/useResizableColUtilities';
import { UploadLinkModal } from './UploadLink/UploadLinkModal';
import { useLayerContext } from '../../../context/layer/LayerContext';
import './Documents.css';

export const Documents = () => {
    const { collectionId, containerNodeId, nodeIds, selectedDetail } = useParams();

    const location = useLocation();
    const iframe = useUtopiaIFrame();
    const { getIFrameUrl } = useDocumentsUtilities();
    const { t } = useSafeTranslation(TranslationFiles.DocumentLayer);
    const { IFrameDocumentRoutes } = useUtopiaIFrameRoutes();
    const { getShowOverlay } = useResizableColUtilities();
    const { openModal } = useLayerContext();

    const [displayInvalidNodeMessage, setDisplayInvalidNodeMessage] = useState(false);
    const [invalidNodeMessage, setInvalidNodeMessage] = useState<string>(t(DocumentsTKeys.InvalidNodeMessage));

    const hideInvalidNodeMessage = () => setDisplayInvalidNodeMessage(false);

    // when in the documents component, the first click of of the back buttons reverts the Atlantis url, the second click reverts the iframe url
    // we are monitoring back button clicks so we can programmatically click it a second time so the user doesn't have to
    const onBackButtonClick = () => {
        if (window.history.length > 1) {
            window.history.go(-1);
        }
    };

    const openUploadLink = (e: MessageEvent) => {
        if (e.data?.type === 'SHOW_CREATE-UPLOAD-LINK_MODAL' || e.data?.type === 'SHOW_VIEW-UPLOAD-LINK_MODAL') {
            openModal((closeModalFn) => <UploadLinkModal destroyModal={closeModalFn} node={e.data.node} uploadLink={e.data.uploadLink} type={e.data.type} />);
        }
    };

    const confirmationCTAs: EfcCallToActionInfo[] = [{ text: t(DocumentsTKeys.CloseCta), color: 'primary', emphasis: 'med', onClick: () => hideInvalidNodeMessage() }];

    // Sets up a listener for navigation within the documents page to make sure the Atlantis url is keeping up
    useSyncUtopiaDocumentsIFrameToAtlantisRoute();

    useEffect(() => {
        window.addEventListener('popstate', onBackButtonClick);
        window.addEventListener('message', openUploadLink);
        return () => {
            window.removeEventListener('popstate', onBackButtonClick);
            window.removeEventListener('message', openUploadLink);
        };
    }, []);

    useEffect(() => {
        if (!iframe.url || !(location.state as any)?.isIFrameSrcChange) {
            const documentURLDetails: IDocumentURLDetails = {
                collectionId,
                containerNodeId,
                nodeIds,
                selectedDetail,
            };
            getIFrameUrl(documentURLDetails).then((iFrameURLUpdateDetails: IIFrameURLUpdateDetails) => {
                if (!!iFrameURLUpdateDetails.invalidNodesMessage) {
                    setInvalidNodeMessage(iFrameURLUpdateDetails.invalidNodesMessage);
                    setDisplayInvalidNodeMessage(true);
                }
                iframe.setUrl(iFrameURLUpdateDetails.updatedIFrameURL ?? IFrameDocumentRoutes.get(RoutePath.Documents) ?? '');
            });
        }
    }, [location.pathname]);

    return (
        <Page>
            <Row className='docRow pt-3'>
                <Col className='h-100 pe-0'>
                    <Card fluid className='h-100 utopia-iframe-border-overlay'>
                        {!!getShowOverlay() && <div className='overlay'></div>}
                        {/* Use the iframe until we make this page native */}
                        <IFrame name='utopiaIframe' className='h-100 m-0' src={iframe.url} dataId='documentsIframe' />
                    </Card>
                </Col>
            </Row>
            <Modal toggle={hideInvalidNodeMessage} title={t(DocumentsTKeys.InvalidNodeTitle)} isOpen={displayInvalidNodeMessage} ctas={confirmationCTAs}>
                <Modal.Body>{`${invalidNodeMessage}`}</Modal.Body>
            </Modal>
        </Page>
    );
};
