import React from 'react';
import { useRouteValidator } from '../hooks/useRouteValidator';
import { RoutePath } from '../hooks/Routing/routingTypes';
import { PageNotFound } from '../features/_errorHandling/pageNotFound/pageNotFound';

export const AuthRoute = (props: any) => {
    const { path, children } = props;

    const { canGoToRoute } = useRouteValidator();

    return canGoToRoute(path as RoutePath) ? children : <PageNotFound />;
};