import React from 'react';
import { useLayerContext } from '../../../../context/layer/LayerContext';
import { RoutePath } from '../../../../hooks/Routing/routingTypes';
import { AtlantisTour, AtlantisTourStep } from '../../AtlantisTour/AtlantisTour';
import { TourType } from '../../tourTypes';

export const CreateACabinet = () => {

    //const { t } = useSafeTranslation(TranslationFiles.WelcomeTour);

    const { isTourOpen } = useLayerContext();

    const tourType = TourType.CreateACabinet;

    const steps: AtlantisTourStep[] = [
        {
            selector: '[data-id=sideNavDocuments] > li > span',
            content: 'Select Documents',
            isNavigationStep: true,
            includeWhen: () => window.location.pathname !== RoutePath.Documents
        },
        {
            selector: '[data-id=documentsIframe]',
            content: 'Hover over your account in the tree view, and click on the Cabinet icon that appears.',
            stepInteraction: true,
            position: [10,10]
        },
    ];

    return (
        <AtlantisTour steps={steps} isOpen={isTourOpen(tourType)} tourType={tourType} />
    );
};
