import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Text, Form, FormGroup, Label, Input, FormFeedback, Spinner } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, WelcomeModalTKeys, BrandingTKeys, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { useAuthContext } from '../../../../auth';
import { AccountOnboardingSteps, IAccountOnboardingBrandingProps } from '../AccountOnboardingTypes';
import { CheckMark } from './CheckMark';
import { BrandingImageModal } from '../../../../pages/Account/Settings/Branding/BrandingImageModal';
import { BrandingSaveConfirmModal } from '../../../../pages/Account/Settings/Branding/BrandingSaveConfirmModal';
import { CNAME_VALIDATION_DELAY, VALID_CNAME_CHARS, CNAME_CHARACTER_LIMIT, FORBIDDEN_ACCOUNT_NAME_CHARS, ACCOUNT_NAME_LIMIT, CnameState as DomainState } from '../../../../pages/Account/Settings/Branding/Branding';
import { useAccountApi } from '../../../../api/account/useAccountApi';
import { AccountBrandingDTO, CnameAvailableDTO } from '../../../../api/branding/brandingApiTypes';
import { useBrandingApi } from '../../../../api/branding/useBrandingApi';
import { useNodeApi } from '../../../../api/node/useNodeApi';

import './AccountOnboardingModalComponents.css';
export const AccountOnboardingBranding = (props: IAccountOnboardingBrandingProps) => {

    const { currentStep, setCurrentStep, setTempHideModal } = props;

    const { t: tBranding } = useSafeTranslation(TranslationFiles.Branding);
    const { t } = useSafeTranslation(TranslationFiles.WelcomeModal);
    const { authUser, userAccounts, updateCurrentAccountBranding } = useAuthContext();
    const { updateAccountNameInUtopia } = useAccountApi();
    const { saveBranding, getBrandingByAccountIdentifier, getCnameAvailable } = useBrandingApi();
    const { updateAccountWorkspaceName } = useNodeApi();

    const [accountName, setAccountName] = useState<string | undefined>(undefined);
    const [originalAccountName, setOriginalAccountName] = useState<string | undefined>(undefined);
    const [accountNameError, setAccountNameError] = useState<string | undefined>(undefined);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const [accountLogo, setAccountLogo] = useState<string>('');
    const [originalAccountLogo, setOriginalAccountLogo] = useState<string | undefined>('');

    const [domainName, setDomainName] = useState<string>('');
    const [originalDomainName, setOriginalDomainName] = useState<string | undefined>(undefined);
    const [domainNameError, setDomainNameError] = useState<string | undefined>(undefined);
    const [domainState, setDomainState] = useState<DomainState>(DomainState.Unset);

    const [logoModalIsOpen, setLogoModalIsOpen] = useState<boolean>(false);
    const [clearAccountLogo, setClearAccountLogo] = useState<boolean>(false);

    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

    const [isCnameChecking, setIsCnameChecking] = useState<boolean>(false);

    const editBranding = () => {
        setCurrentStep(AccountOnboardingSteps.SetBranding);
    };

    const doesBrandingExist = () => {
        return !!originalAccountLogo || !!originalDomainName;
    };

    const generateBrandingObj = () => {
        if (!authUser?.accountID || !userAccounts) {
            return null;
        }

        const accountIdentifier = userAccounts.get(authUser.accountID)?.identifier;

        if (!accountIdentifier) {
            return null;
        }

        const branding: AccountBrandingDTO = {
            accountIdentifier,
        };

        if (domainName !== '') {
            branding.cName = domainName;
        }

        if (accountName !== '') {
            branding.accountName = accountName;
        }

        if (accountLogo !== '') {
            branding.accountLogoImgString = accountLogo;
        }

        return branding;
    };

    const onAccountNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newAccountName = event.target.value.slice(0, ACCOUNT_NAME_LIMIT);
        const forbiddenCharacters = event.target.value.match(FORBIDDEN_ACCOUNT_NAME_CHARS);

        if (event.target.value.length > ACCOUNT_NAME_LIMIT) {
            setAccountNameError(t(WelcomeModalTKeys.AccountOnboardingAccountNameMaxSizeError));
        } else if (event.target.value.length === 0 && !!doesBrandingExist()) {
            setAccountNameError(t(WelcomeModalTKeys.AccountOnboardingAccountNameEmptyError));
        } else if (event.target.value && forbiddenCharacters) {
            const uniqueArray = forbiddenCharacters.filter(function (item, pos) {
                return forbiddenCharacters.indexOf(item) == pos;
            });
            setAccountNameError(t(WelcomeModalTKeys.AccountOnboardingAccountNameInvalidCharactersError) + ` ${uniqueArray.join('')}`);
        } else {
            setAccountNameError(undefined);
        }

        setAccountName(newAccountName);
    };

    const updateUtopiaAccountName = async () => {
        await updateAccountNameInUtopia(authUser?.accountID as number, accountName as string);
        await updateAccountWorkspaceName(authUser?.accountID as number, accountName as string);
        setOriginalAccountName(accountName);
    };

    const updateBranding = async () => {
        const branding = generateBrandingObj();

        if (!branding || ((accountName === originalAccountName || accountName === '') && domainName === originalDomainName && accountLogo === originalAccountLogo)) {
            return;
        }

        try {
            await saveBranding(branding);
            updateCurrentAccountBranding(branding);

            setOriginalAccountName(accountName);
            setOriginalDomainName(domainName);
            setOriginalAccountLogo(accountLogo);
        } catch (error) {
            console.log(error);
        }
    };

    const getSubtext = () => {
        if (originalAccountName === 'My Account') {
            return t(WelcomeModalTKeys.AccountOnboardingAccountDefaultName);
        } else if (!!doesBrandingExist()) {
            return t(WelcomeModalTKeys.AccountOnboardingBrandingSubTitle);
        } else {
            return t(WelcomeModalTKeys.AccountOnboardingBrandingSubTitleSingular);
        }
    };

    const canSubmit = () => {
        return (!accountNameError && accountName && !domainNameError && (domainState == DomainState.Unset || domainState == DomainState.Valid));
    };

    const updateNameAndBranding = async () => {
        setIsUpdating(true);

        await updateBranding();

        if (accountName !== originalAccountName && accountName !== '') {
            await updateUtopiaAccountName();
        }

        setIsUpdating(false);
    };

    const handleSave = async () => {
        setIsConfirmModalOpen(false);

        await startCnameExistsCheck();

        if (!canSubmit()) {
            return;
        }

        updateNameAndBranding();

        setCurrentStep(AccountOnboardingSteps.ShowReviewView);
    };

    const continueOnboarding = () => {
        if (isUpdating) {
            return;
        } else if (originalAccountName !== accountName) {
            setIsConfirmModalOpen(true);
        } else {
            handleSave();
        }
    };

    const startCnameExistsCheck = async () => {
        setDomainState(DomainState.Validating);

        if (!isCnameChecking && !!domainName) {
            setIsCnameChecking(true);

            const cnameAvailableResponse: CnameAvailableDTO = await getCnameAvailable(domainName);

            setIsCnameChecking(false);
            if (cnameAvailableResponse.hasErrorMessage) {
                setDomainNameError(cnameAvailableResponse.errorMessage);
                setDomainState(DomainState.Invalid);
                return false;
            } else {
                setDomainState(DomainState.Valid);
                return true;
            }
        }
    };

    const onDomainInputBlur = () => startCnameExistsCheck();

    const onDomainNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDomainNameError(undefined);
        setDomainName(event.target.value);
    };

    const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        continueOnboarding();
    };

    const removeLogo = () => {
        if (!!doesBrandingExist()) {
            setAccountLogo('');
        }
    };

    const updateLogo = (logo: string) => {
        if (!!doesBrandingExist()) {
            setAccountLogo(logo);
        }

        onLogoModalClose();
    };

    const openLogoModal = () => {
        setLogoModalIsOpen(true);
        setTempHideModal(true);
    };

    const onLogoModalClose = () => {
        setLogoModalIsOpen(false);
        setTempHideModal(false);
    };

    const setupBranding = async () => {
        const accountIdentifier = userAccounts?.get(authUser?.accountID as number)?.identifier;

        if (!!accountIdentifier) {
            const branding = await getBrandingByAccountIdentifier(accountIdentifier);

            setDomainName(branding.cName ?? '');
            setOriginalDomainName(branding.cName);
            if (!!branding.cName) {
                setDomainState(DomainState.Valid);
            }

            setAccountLogo(branding.accountLogoImgString ?? '');
            setOriginalAccountLogo(branding.accountLogoImgString);
        }
    };

    useEffect(() => {
        if (accountName === undefined) {
            const name = userAccounts?.get(authUser?.accountID as number)?.accountName;

            if (name === 'My Account') {
                setAccountName('');
                setOriginalAccountName(name);
                setCurrentStep(AccountOnboardingSteps.SetBranding);
            } else {
                setAccountName(name as string);
                setOriginalAccountName(name);
            }
        }
    }, [accountName]);

    useEffect(() => {
        let typingCnameTimeout: NodeJS.Timeout | undefined;
        setDomainState(DomainState.Typing);

        if (domainName === '') {
            setDomainState(DomainState.Unset);
            setDomainNameError(undefined);
        } else if (domainName.length > CNAME_CHARACTER_LIMIT) {
            setDomainNameError(tBranding(BrandingTKeys.DomainCharacterLimit, { limit: CNAME_CHARACTER_LIMIT }));
        } else if (!VALID_CNAME_CHARS.test(domainName)) {
            setDomainNameError(tBranding(BrandingTKeys.DomainValidCharacters));
        } else if (domainName.charAt(0) == '-' || domainName.charAt(domainName.length - 1) == '-') {
            setDomainNameError(tBranding(BrandingTKeys.DomainHyphenFirstLast));
        } else if (domainName.length > 3 && domainName.charAt(2) == '-' && domainName.charAt(3) == '-') {
            setDomainNameError(tBranding(BrandingTKeys.DomainHyphenConsecutive));
        } else {
            typingCnameTimeout = setTimeout(() => {
                startCnameExistsCheck();
            }, CNAME_VALIDATION_DELAY);
        }
        return () => clearTimeout(typingCnameTimeout);
    }, [domainName]);

    useEffect(() => {
        if (originalDomainName === undefined || originalAccountLogo === undefined) {
            setupBranding();
        }
    }, []);

    return (
        <>
            {logoModalIsOpen && <BrandingImageModal isOpen={logoModalIsOpen} base64Image={accountLogo} clearFile={clearAccountLogo} setIsOpen={onLogoModalClose} setBase64Image={updateLogo} setClearFile={setClearAccountLogo} />}

            {isConfirmModalOpen && <BrandingSaveConfirmModal isOpen={isConfirmModalOpen} toggle={() => setIsConfirmModalOpen(false)} onConfirm={handleSave} />}

            {currentStep === AccountOnboardingSteps.SetBranding && (
                <Form onSubmit={handleFormSubmit}>
                    <Row className='mb-4'>
                        <Col sm='auto' className='empty-padding-left'>
                            <CheckMark isFinished={false} showLine={true} />
                        </Col>
                        <Col className='pt-2'>
                            <Row className='mb-3'>
                                <Text size='xl'>{!!doesBrandingExist() ? t(WelcomeModalTKeys.AccountOnboardingBrandingTitle) : t(WelcomeModalTKeys.AccountOnboardingAccountTitle)}</Text>
                            </Row>

                            <Row className='mb-2'>
                                <Text size='lg'>{getSubtext()}</Text>
                            </Row>

                            <Row className='mb-3'>
                                <FormGroup>
                                    <Label for='accountName'>{t(WelcomeModalTKeys.AccountOnboardingAccountTitle)}</Label>
                                    <Input className='input-name' required id='accountName' dataId='accountName' name='accountName' placeholder='Enter text' type='text' value={accountName} onChange={onAccountNameChange} invalid={!!accountNameError} />
                                    <FormFeedback>
                                        <FontAwesomeIcon icon='triangle-exclamation' size='sm' className='icon-warning' /> {accountNameError}
                                    </FormFeedback>
                                </FormGroup>
                            </Row>

                            {!doesBrandingExist() && (
                                <Row className='mb-2 pt-3'>
                                    <Text size='md'>{t(WelcomeModalTKeys.AccountOnboardingAccountNameInfo)}</Text>
                                </Row>
                            )}

                            {!!doesBrandingExist() && (
                                <>
                                    {!!originalDomainName && originalDomainName !== '' && (
                                        <Row className='mb-3'>
                                            <FormGroup>
                                                <Label for='domainName' className='domain-label'>
                                                    {t(WelcomeModalTKeys.AccountOnboardingDomainTitle)} <Text muted>({t(WelcomeModalTKeys.AccountOnboardingOptional)})</Text>
                                                </Label>
                                                <Input className='input-domain' required id='domainName' dataId='domainName' name='domainName' placeholder='Enter text' type='text' value={domainName} onChange={onDomainNameChange} onBlur={onDomainInputBlur} invalid={!!domainNameError} valid={!domainNameError && domainName.length > 0 && domainState == DomainState.Valid && domainName !== originalDomainName} disabled={isCnameChecking} />
                                                <Text muted>.revverdocs.com</Text>
                                                {isCnameChecking && <Spinner size='sm' className='ms-2' color='primary' />}
                                                <FormFeedback valid={!domainNameError && domainName.length > 0 && domainState == DomainState.Valid}>
                                                    {!!domainNameError && domainNameError}
                                                    {(!domainNameError && domainName.length > 0 && domainState == DomainState.Valid && domainName !== originalDomainName) && tBranding(BrandingTKeys.ValidDomainMessage)}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Row>
                                    )}

                                    <Row>
                                        <Text className='mb-2'>{t(WelcomeModalTKeys.AccountOnboardingCompanyLogoTitle)}</Text>
                                        <Text className='mb-2'>{t(WelcomeModalTKeys.AccountOnboardingLogoIssuesText)}</Text>
                                        {accountLogo !== '' && (
                                            <div className='company-logo-wrapper'>
                                                <FontAwesomeIcon icon={['fal', 'times-circle']} size='lg' className='company-logo-cancel-icon' onClick={removeLogo} />
                                                <img src={accountLogo} alt='Company Logo' className='company-logo' />
                                                {/* <div className='company-logo-size-text mt-2' onClick={openLogoModal}>
                                                    {t(WelcomeModalTKeys.AccountOnboardingBrandingAdjustSize)}
                                                </div> */}
                                            </div>
                                        )}
                                        {accountLogo === '' && (
                                            <Button className='d-flex align-items-center company-logo-button mt-1' color='primary' type='button' emphasis='med' dataId='uploadCompanyLogo' onClick={openLogoModal}>
                                                <FontAwesomeIcon icon='upload' className='me-2' size='lg' />
                                                {t(WelcomeModalTKeys.AccountOnboardingBrandingUploadImage)}
                                            </Button>
                                        )}
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Button color='primary' onClick={continueOnboarding} className='btn-lg float-end' disabled={!canSubmit()}>
                                Next
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}
            {currentStep === AccountOnboardingSteps.ShowReviewView && (
                <Row className='mb-3'>
                    <Col sm='auto' className='empty-padding-left'>
                        <CheckMark isFinished={true} showLine={false} />
                    </Col>
                    <Col className='pt-2'>
                        <Text size='xl'>
                            {t(WelcomeModalTKeys.AccountOnboardingAccountNameEntered) + accountName}
                            <FontAwesomeIcon icon='edit' className='icon-edit' onClick={editBranding} />
                        </Text>
                    </Col>
                </Row>
            )}
        </>
    );
};
