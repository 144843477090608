import { useState } from 'react';
import { RoutePath } from './Routing/routingTypes';
import { useRouteValidator } from './useRouteValidator';
import { TourType } from '../components/Tours/tourTypes';

export interface TourGroup {
    displayName: string;
    tours: Tour[];
}

export interface Tour {
    type: TourType;
    route?: RoutePath;
    displayName: string;
}

export const useAtlantisTours = () => {

    const { canGoToRoute } = useRouteValidator();

    const [isTourMenuOpen, setIsTourMenuOpen] = useState(false);
    const [currentTour, setCurrentTour] = useState<TourType>();

    const toggleTourMenu = () => {
        setIsTourMenuOpen(current => !current);
    };

    const toggleTour = (tourType: TourType) => {
        setCurrentTour(openTour => openTour === tourType ? undefined : tourType);
    };

    const isTourOpen = (tourType: TourType) => {
        return currentTour === tourType;
    };

    const TourGroups: TourGroup[] = [
        // HOME
        {
            displayName: 'Home', tours:
                [
                    { type: TourType.Welcome, displayName: 'Welcome Tour' }
                ]
        },
        // DOCUMENTS
        {
            displayName: 'Documents', tours:
                [
                    { type: TourType.RestoreDeletedFile, displayName: 'Restore a deleted file', route: RoutePath.RecycleBin },
                    { type: TourType.CreateACabinet, displayName: 'Create a Cabinet', route: RoutePath.Documents },
                    { type: TourType.ApplyAFolderTemplate, displayName: 'Apply a Folder Template', route: RoutePath.Documents }
                ]
        },
        // WORK
        {
            displayName: 'Work', tours:
                [
                    { type: TourType.HowToUseESignature, displayName: 'How to use eSignature', route: RoutePath.ESignatureAll }
                ]
        },
        // PEOPLE
        {
            displayName: 'People', tours:
                [
                    { type: TourType.CreateNewUser, displayName: 'Create a new user', route: RoutePath.UserNew }
                ]
        },
        // ACCOUNT
        {
            displayName: 'Account', tours:
                [

                ]
        },
    ];

    const getTourGroups = () => {
        const tourGroupsWithFilteredTours = TourGroups.map(tg => {
            const filteredTourGroup = { ...tg };

            filteredTourGroup.tours = filteredTourGroup.tours.filter(t => !t.route || canGoToRoute(t.route));

            return filteredTourGroup;
        });

        return tourGroupsWithFilteredTours.filter(tg => tg.tours.length > 0);
    };

    return {
        getTourGroups,

        isTourOpen,
        toggleTour,

        isTourMenuOpen,
        toggleTourMenu,
    };
};