import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { EfcCallToActionInfo, Page, Tab } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, useSafeTranslation, MetadataTKeys } from '../../../../hooks/useSafeTranslation';
import { useResizableColUtilities } from '../../../../hooks/useResizableColUtilities';
import { useRouteValidator } from '../../../../hooks/useRouteValidator';
import { useRouting } from '../../../../hooks/Routing/useRouting';
import { MetadataRoutePath, RoutePath } from '../../../../hooks/Routing/routingTypes';
import { useLayerContext } from '../../../../context/layer/LayerContext';
import { useMetadataUtilities } from '../../../../hooks/useMetadataUtilities';
import { MDFieldModal } from '../../_fields/_modal/mdFieldModal/mdFieldModal';
import { MDGroupModal } from '../../_groups/_modal/mdGroupModal/mdGroupModal';
import { MDFieldsManagement } from '../../_fields/mdFieldsManagement/mdFieldsManagement';
import { MDGroupsManagement } from '../mdGroupsManagement/mdGroupsManagement';
import { MDManagementSideSheet } from '../_sidesheet/mdManagementSideSheet/mdManagementSideSheet';
import { MDFieldDTO } from '../../../../api/mdFields/mdFieldApiTypes';
import { MDGroupDTO } from '../../../../api/mdGroups/mdGroupsApiTypes';
import './metadataManagementPage.css';

const FieldsTabRoutes = new Set<MetadataRoutePath>([
    RoutePath.MetadataIndex,
    RoutePath.MDFields,
]);

const GroupsTabRoutes = new Set<MetadataRoutePath>([
    RoutePath.MDGroups,
]);

export const MetadataManagementPage: FC = () => {

    const { openModal } = useLayerContext();
    const { createInitialMDField, createInitialMDGroup } = useMetadataUtilities();
    const { MIN_COLLAPSED_WIDTH, getSideSheetWidth, getRowSubClasses } = useResizableColUtilities();
    const { routeToMDFields, routeToMDGroups } = useRouting();
    const { canGoToRoute } = useRouteValidator();
    const { t } = useSafeTranslation(TranslationFiles.Metadata);

    const [refreshMDFieldsSentinalKey, setRefreshMDFieldsSentinalKey] = useState(0); // playing with this pattern, basically this is updated every time a field is changed or added so that the children can just listen for changes
    const [refreshMDGroupsSentinalKey, setRefreshMDGroupsSentinalKey] = useState(0); // playing with this pattern, basically this is updated every time a group is changed or added so that the children can just listen for changes
    const [selectedMDField, setSelectedMDField] = useState<MDFieldDTO>(createInitialMDField());
    const [selectedMDGroup, setSelectedMDGroup] = useState<MDGroupDTO>(createInitialMDGroup());

    const currentRoute = `${location.pathname}` as MetadataRoutePath;

    function openAddMDFieldModal() {
        openModal(() =>
            <MDFieldModal onSubmitField={() => setRefreshMDFieldsSentinalKey(prevState => prevState + 1)} />
        );
    }

    function openAddMDGroupModal() {
        openModal(() =>
            <MDGroupModal onSubmitGroup={() => setRefreshMDGroupsSentinalKey(prevState => prevState + 1)} />
        );
    }

    function openRevverLibrary() {
        console.log('Open Revver Library');
    }

    const ctas: EfcCallToActionInfo[] = [
        {
            text: t(MetadataTKeys.AddFromLibrary),
            dataId: 'btnAddFromLibrary',
            onClick: openRevverLibrary,
            show: () => true,
        },
        {
            text: t(MetadataTKeys.CreateField),
            dataId: 'btnCreateMDField',
            onClick: openAddMDFieldModal,
            show: () => FieldsTabRoutes.has(currentRoute),
        },
        {
            text: t(MetadataTKeys.CreateGroup),
            dataId: 'btnCreateMDGroup',
            onClick: openAddMDGroupModal,
            show: () => GroupsTabRoutes.has(currentRoute),
        },
    ];

    useEffect(() => {
        if (currentRoute == RoutePath.MetadataIndex) {
            routeToMDFields();
        }
    }, [location.pathname]);

    return (
        <Row className={`metadataRow pt-3 ${getSideSheetWidth() > MIN_COLLAPSED_WIDTH ? '' : 'w-100'}`} >
            <Col className={`${getRowSubClasses('metadata')}`}>
                <Page
                    title={t(MetadataTKeys.MetadataTitle)}
                    icon={{ icon: 'code' }}
                    className='metadata-page'
                    callsToAction={ctas}
                    useDropdownCallToAction={true}
                    dropdownText={(FieldsTabRoutes.has(currentRoute) ? t(MetadataTKeys.AddNewField) : t(MetadataTKeys.AddNewGroup))}
                >
                    {canGoToRoute(RoutePath.MDFields) &&
                        <Tab active={FieldsTabRoutes.has(currentRoute)} onClick={() => routeToMDFields()}>
                            {t(MetadataTKeys.FieldsTab)}
                        </Tab>
                    }
                    {canGoToRoute(RoutePath.MDGroups) &&
                        <Tab active={GroupsTabRoutes.has(currentRoute)} onClick={() => routeToMDGroups()}>
                            {t(MetadataTKeys.GroupsTab)}
                        </Tab>
                    }

                    <Tab.Content activeTab={currentRoute}>
                        <Tab.Pane tabId={RoutePath.MDFields}>
                            <MDFieldsManagement selectedMDFieldState={[selectedMDField, setSelectedMDField]} active={FieldsTabRoutes.has(currentRoute)} refreshMDFieldsSentinalKeyState={[refreshMDFieldsSentinalKey, setRefreshMDFieldsSentinalKey]} />
                        </Tab.Pane>
                        <Tab.Pane tabId={RoutePath.MDGroups}>
                            <MDGroupsManagement selectedMDGroupState={[selectedMDGroup, setSelectedMDGroup]} active={GroupsTabRoutes.has(currentRoute)} refreshMDGroupsSentinalKeyState={[refreshMDGroupsSentinalKey, setRefreshMDGroupsSentinalKey]} />
                        </Tab.Pane>
                    </Tab.Content>
                </Page>
            </Col>

            <MDManagementSideSheet currentRoute={currentRoute} selectedMDField={selectedMDField} selectedMDGroup={selectedMDGroup} refreshMDFieldsSentinalKeyState={[refreshMDFieldsSentinalKey, setRefreshMDFieldsSentinalKey]} refreshMDGroupsSentinalKeyState={[refreshMDGroupsSentinalKey, setRefreshMDGroupsSentinalKey]} />

        </Row>
    );
};
