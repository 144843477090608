import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { ButtonToolbar, Col, Form, FormFeedback, Modal, ModalBody, Row } from 'reactstrap';
import { closestCorners, DndContext, useSensor, useSensors, PointerSensor, TouchSensor, KeyboardSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Label, Input, FormGroup, EfcCallToActionInfo, Text, FullTable, EfcTableColumnInfo, CallToAction } from '@efilecabinet/efc-atlantis-components';
import { MDGroupModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useBootstrapBreakpoints } from '../../../../../hooks/bootstrap/useBootstrapBreakpoints';
import { IUseModalProps } from '../../../../../hooks/useModal';
import { ScreenSize } from '../../../../../hooks/bootstrap/bootstrapTypes';
import { useMDFieldApi } from '../../../../../api/mdFields/useMDFieldApi';
import { useAuthContext } from '../../../../../auth';
import { useMDGroupsApi } from '../../../../../api/mdGroups/useMDGroupsApi';
import { useMetadataUtilities } from '../../../../../hooks/useMetadataUtilities';
import { MDAddedField } from '../mdAddedField/mdAddedField';
import { AddedFieldsZeroState } from '../addedFieldsZeroState/addedFieldsZeroState';
import { MDGroupDTO } from '../../../../../api/mdGroups/mdGroupsApiTypes';
import { MDFieldDTO, MDFieldTypes } from '../../../../../api/mdFields/mdFieldApiTypes';
import './mdGroupModal.css';

export interface MDGroupModalProps extends IUseModalProps {
    mdGroupToEdit?: MDGroupDTO; // optional, if not present, the modal will be in add mode
    onSubmitGroup: (mdGroup: MDGroupDTO) => void;
}

export const MDGroupModal = ({ isOpen, close, mdGroupToEdit, onSubmitGroup, ...otherProps }: MDGroupModalProps) => {

    const nameInputRef = useRef<HTMLInputElement>(null);

    const { authUser, hasAuthUser } = useAuthContext();
    const { screenSize } = useBootstrapBreakpoints();
    const { getAccountMDFields } = useMDFieldApi();
    const { createMDGroup, updateMDGroup } = useMDGroupsApi();
    const { createInitialMDGroup } = useMetadataUtilities();
    const { t } = useSafeTranslation(TranslationFiles.MDGroupModal);

    const [allFieldsLoaded, setAllFieldsLoaded] = useState(false);
    const [mdFields, setMDFields] = useState<MDFieldDTO[]>([]);
    const [mdGroup, setMDGroup] = useState<MDGroupDTO>(mdGroupToEdit ?? createInitialMDGroup());
    const [isFormValid, setIsFormValid] = useState<boolean>(true);
    const [textNameError, setTextInputError] = useState('');

    const BATCH_SIZE = 100;

    const MAX_INPUT_CHARS = 250;
    const NAME_REGEX = /[^0-9a-zA-Z#()?._/\-\\]+/ig;

    function clearForm() {
        setMDGroup(mdGroup);
        setIsFormValid(true);
        setTextInputError('');
    }

    async function handleEdit() {
        if (!!mdGroup.name && !!hasAuthUser && !!mdGroup.id) {
            const updatedMDGroup = await updateMDGroup(mdGroup);
            onSubmitGroup(updatedMDGroup);
        }
    }

    async function handleSubmit() {
        if (!!mdGroup.name && !!hasAuthUser) {
            mdGroup.accountId = authUser?.accountID.toString();

            handleClose();

            const newMDGroup = await createMDGroup(mdGroup);
            onSubmitGroup(newMDGroup);
        }
    }

    function handleClose() {
        clearForm();
        !!close && close();
    }

    function focusNameInput() {
        if (isOpen) {
            setTimeout(() => {
                (nameInputRef?.current as any)?.focus();
            });
        }
    }

    async function validateTextValue() {
        let error = '';

        if (mdGroup.name == '') {
            error = t(MDGroupModalTKeys.BlankNameError);
        } else if (mdGroup.name.match(NAME_REGEX)) {
            error = t(MDGroupModalTKeys.AlphanumericNameError);
            //} else if (await checkMDGroupNameExists({accountId: authUser?.accountID.toString() as string, name: mdField.name})) {
            //    error = t(MDGroupModalTKeys.DuplicateNameError);
        }

        setTextInputError(error);
    }

    function addFieldToGroup(id: string) {
        const mdField = mdFields.find(field => field.id === id.toString());
        if (mdField && !mdGroup.metadataFields.find(field => field.id === id)) {
            const groupFields = mdGroup.metadataFields;
            groupFields.push({ ...mdField, required: false });
            setMDGroup({ ...mdGroup, metadataFields: groupFields });
        }
    }

    function removeFieldFromGroup(id: string) {
        setMDGroup((prevState) => ({ ...prevState, metadataFields: prevState.metadataFields.filter(field => field.id !== id) }));
    }

    function toggleFieldRequired(id: string, isChecked: boolean) {
        setMDGroup((prevState) => ({
            ...prevState,
            metadataFields: prevState.metadataFields.map(field => {
                if (field.id === id) {
                    return {
                        ...field,
                        required: isChecked
                    };
                }
                return field;
            }),
        }));
    }

    function getFieldPos(id: number) { return mdGroup.metadataFields.findIndex(field => field.id === id.toString()); }

    function handleDragEnd(event: any) {
        const { active, over } = event;

        if (active.id === over.id) return;

        setMDGroup((prevState) => ({ ...prevState, metadataFields: arrayMove(prevState.metadataFields, getFieldPos(active.id), getFieldPos(over.id)) }));

    }

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: 100,
                tolerance: 10,
            },
        }),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );

    const getCtas = (): EfcCallToActionInfo[] => {
        return [
            {
                text: t(MDGroupModalTKeys.CancelCta),
                emphasis: 'med',
                color: 'secondary',
                onClick: handleClose,
                dataId: 'btnCloseMetadataGroup'
            },
            {
                text: t(MDGroupModalTKeys.SaveCta),
                emphasis: 'high',
                color: 'primary',
                disabled: !mdGroup.name || !isFormValid,
                onClick: !!mdGroup.id ? handleEdit : handleSubmit,
                dataId: 'btnSaveMetadataGroup'
            }
        ];
    };

    const getAddFieldCta = (field: any): EfcCallToActionInfo => {
        return {
            text: t(MDGroupModalTKeys.AddFieldCta),
            emphasis: 'med',
            onClick: () => addFieldToGroup(field.id),
        };
    };

    const getColumns = (): (string | EfcTableColumnInfo)[] => {
        return [
            { name: 'name', searchBy: true, displayName: t(MDGroupModalTKeys.NameColumn), widthPct: 35 },
            { name: 'type', searchBy: true, isEnum: true, enumValues: Object.keys(MDFieldTypes).filter((o) => isNaN(parseFloat(o))), displayName: t(MDGroupModalTKeys.TypeColumn), widthPct: 15 },
            { name: 'actions', displayName: '', widthPct: 20, useCta: getAddFieldCta }
        ];
    };

    useEffect(() => {
        return () => {
            clearForm();
        };
    }, []);

    useEffect(() => {
        setIsFormValid(!textNameError);
    }, [textNameError]);

    useEffect(() => {
        focusNameInput();

        let componentUnmounted = false;

        async function loadAccountMDFieldsAsync() {
            if (!allFieldsLoaded) {
                await getAccountMDFields(authUser?.accountID as number, mdFields.length, BATCH_SIZE).then((fields) => {
                    if (!componentUnmounted) {
                        if (fields.length < BATCH_SIZE) {
                            setAllFieldsLoaded(true);
                        }

                        setMDFields(fields);
                    }
                });
            }
        }

        if (!!isOpen && !!hasAuthUser) {
            loadAccountMDFieldsAsync();
        }

        return () => {
            componentUnmounted = true;
        };

    }, [isOpen]);

    return (
        <Modal className={`xxl-group-modal ${screenSize < ScreenSize.s ? 'md-group-mobile-modal-spacing' : ''}`} 
            toggle={handleClose} 
            isOpen={isOpen} 
            size='xl' 
            unmountOnClose={true} 
            {...otherProps}>

            {/* Header */}
            <div className='modal-header xxl-group-modal-header px-3'>
                <div className='d-flex flex-wrap align-items-center justify-content-start w-75'>
                    <div className='d-flex align-items-center'>
                        <h1 className='xxl-group-modal-header-title'>{t(MDGroupModalTKeys.AddMetadata)}</h1>
                    </div>
                </div>
                <button type="button" 
                    className="btn-close" 
                    data-bs-dismiss="modal" 
                    aria-label="Close" 
                    onClick={handleClose} />
            </div>

            {/* Body */}
            <ModalBody className='xxl-group-modal-body'>
                <div className='h-100 xxl-group-modal-body-card'>
                    <Row>
                        <Form className='p-0'>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Label className='xxl-group-modal-body-title' for='nameInputGroupText'>{t(MDGroupModalTKeys.ModalTitle)}</Label>
                                        <Input innerRef={nameInputRef}
                                            id='nameInputGroupText'
                                            name='nameInputGroupText'
                                            dataId='txtNameInputText'
                                            className='md-group-modal-name-size'
                                            value={mdGroup.name}
                                            maxLength={MAX_INPUT_CHARS}
                                            onChange={(event) => setMDGroup({ ...mdGroup, name: event.target.value })}
                                            invalid={!!textNameError}
                                            onBlur={validateTextValue}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' && !!mdGroup.name) {
                                                    handleSubmit();
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormFeedback className={!!textNameError ? 'd-block' : ''} valid={!textNameError}>
                                            {textNameError}
                                        </FormFeedback>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>
                    </Row>
                    <Row className='h-100 xxl-group-modal-col'>
                        <Col className='h-100 px-0'>
                            <div className='h-100 xxl-group-modal-col-scroll'>
                                <Text semibold tag='h6'>{t(MDGroupModalTKeys.SelectFields)}</Text>
                                <Row className='fields-to-add-col'>
                                    <div>
                                        <FullTable searchable={false} 
                                            filterable={false} 
                                            selectable={false} 
                                            data={mdFields} 
                                            columns={getColumns()} />
                                    </div>
                                </Row>
                                <Text semibold tag='h6'>{t(MDGroupModalTKeys.RevverLibrary)}</Text>
                                <Row className='revver-library-col'>
                                    <div>
                                        <FullTable searchable={false} 
                                            filterable={false} 
                                            selectable={false} 
                                            data={[{ name: 'Invoice Number', type: 6 }, { name: "Invoice Date", type: 3 }, { name: "Customer Phone Number", type: 4 }, { name: "Customer Address", type: 2 }] as MDFieldDTO[]} 
                                            columns={getColumns()} />
                                    </div>
                                </Row>
                            </div>
                        </Col>

                        <div className='xxl-group-modal-divider' />

                        <Col className='h-100 xxl-group-modal-col-scroll'>
                            <Text semibold tag='h5'>{t(MDGroupModalTKeys.AddedFields)}</Text>
                            {mdGroup.metadataFields.length < 1
                                ? <AddedFieldsZeroState />
                                : (
                                    <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
                                        <SortableContext items={mdGroup.metadataFields} strategy={verticalListSortingStrategy}>
                                            {
                                                mdGroup.metadataFields.map(field =>
                                                    <MDAddedField key={field.id} field={field} onRemove={removeFieldFromGroup} onToggleRequired={toggleFieldRequired} />
                                                )
                                            }
                                        </SortableContext>
                                    </DndContext>
                                )
                            }
                        </Col>
                    </Row>
                </div>
            </ModalBody>
                
            {/* Footer from EfcModalFooter */}
            <ButtonToolbar className='xxl-group-modal-footer'>
                {getCtas().map((cta: EfcCallToActionInfo, index: number) => (
                    <CallToAction key={index} {...cta} />
                ))}
            </ButtonToolbar>

        </Modal>
    );
};
