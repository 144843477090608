import { useNavigate } from 'react-router';
import { RoutePath, SecurityPoliciesRouteParams } from './routingTypes';

export const useSecurityPoliciesRouting = () => {

    const navigate = useNavigate();

    const getLinkToSecurityPolicies = () => {
        return RoutePath.SecurityPolicies;
    };

    const routeToSecurityPolicies = () => {
        navigate(getLinkToSecurityPolicies());
    };

    const getLinkToSecurityPolicyNew = () => {
        return RoutePath.SecurityPolicyNew;
    };

    const routeToSecurityPolicyNew = () => {
        navigate(getLinkToSecurityPolicyNew());
    };

    const getLinkToSecurityPolicyEdit = (policyId: string) => {
        return RoutePath.SecurityPolicyEdit.replace(SecurityPoliciesRouteParams.PolicyId, policyId);
    };

    const routeToSecurityPolicyEdit = (policyId: string) => {
        navigate(getLinkToSecurityPolicyEdit(policyId));
    };

    return {
        getLinkToSecurityPolicies,
        getLinkToSecurityPolicyNew,
        getLinkToSecurityPolicyEdit,
        routeToSecurityPolicies,
        routeToSecurityPolicyNew,
        routeToSecurityPolicyEdit,
    };
};
