import React, { useEffect, useState } from 'react';
import { EfcLink, Text, Tooltip } from '@efilecabinet/efc-atlantis-components';
import { useUtilities } from '../../../../../hooks/useUtilities';
import { TranslationFiles, useSafeTranslation, FileVersionsTKeys } from '../../../../../hooks/useSafeTranslation';
import { useLayerContext } from '../../../../../context/layer/LayerContext';
import { useNodeDetailsContext } from '../../../../../context/nodeDetails/NodeDetailsContext';
import { FullScreenPreviewerModal } from '../../../../Previewer/PreviewerModals/FullScreenPreviewerModal/FullScreenPreviewerModal';
import './FileVersion.css';
import { FileVersionDTO } from '../../../../../api/fileVersions/fileVersionApiTypes';

interface FileVersionDateLinkProps {
    fileVersion: FileVersionDTO;
    className?: string;
}

export const FileVersionDateLink = ({ fileVersion, className }: FileVersionDateLinkProps) => {
    const { formatDateTime } = useUtilities();
    const { t } = useSafeTranslation(TranslationFiles.FileVersions);
    const { openModal } = useLayerContext();
    const { activeNode, isLoadingNode } = useNodeDetailsContext();

    const [isToolTipOpen, setIsToolTipOpen] = useState<boolean>(false);
    const [tooltipTarget, setTooltipTarget] = useState<string>(`version-date-${fileVersion.id}`);

    const toggleTooltip = () => setIsToolTipOpen((prevState) => !prevState);

    const handleVersionDateClick = () => {
        if(!!activeNode && !!fileVersion.id && !isLoadingNode) {
            openModal((closeModalFn) => 
                <FullScreenPreviewerModal node={activeNode} fileVersionId={fileVersion.id} onClose={closeModalFn} />);
        } else {
            console.log('There was an error opening the full screen previewer modal. Invalid node.');
        }
    };

    useEffect(() => {
        const randomId = Math.floor(1000 + Math.random() * 9000);
        setTooltipTarget(`version-date-${randomId}`);
    }, []);

    return (
        <>
            {!!fileVersion.allowPreview ? (
                <span className={className}>
                    <EfcLink color='primary' id={tooltipTarget} className='underline-hover' onClick={handleVersionDateClick} dataId={`file-version-created-${fileVersion.id}`}>
                        {formatDateTime(fileVersion.createdOn)}
                    </EfcLink>
                    <Tooltip target={tooltipTarget} isOpen={isToolTipOpen} toggle={toggleTooltip} placement='bottom'>
                        {t(FileVersionsTKeys.PreviewTooltip)}
                    </Tooltip>
                </span>
            ) : (
                <span className={className}>
                    <Text muted dataId={`file-version-created-disabled-${fileVersion.id}`}>
                        {formatDateTime(fileVersion.createdOn)}
                    </Text>
                </span>
            )}
        </>
    );
};
