import { IconPack, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { faCircleA, faCircleB, faCircleC, faCircleD, faCircleE, faCircleF, faCircleG, faCircleH, faCircleI, faCircleJ, faCircleK, faCircleL, faCircleM, faCircleN, faCircleO, faCircleP, faCircleQ, faCircleR, faCircleS, faCircleT, faCircleU, faCircleV, faCircleW, faCircleX, faCircleY, faCircleZ } from '@fortawesome/pro-duotone-svg-icons';
import { useColors } from '../hooks/useColors';
import { NodeType } from '../api/node/nodeApiTypes';

export const useIcons = () => {

    const colors = useColors();

    // Spinner Icon
    const spinnerIconProps: FontAwesomeIconProps = { icon: ['far', 'circle-notch'], spin: true };

    // Node Icons
    const accountIconProps: FontAwesomeIconProps = { icon: 'building' };
    const cabinetIconProps: FontAwesomeIconProps = { icon: 'folder' };
    const drawerIconProps: FontAwesomeIconProps = { icon: 'folder' };
    const newFolderIconProps: FontAwesomeIconProps = { icon: 'folder' };
    const fileIconProps: FontAwesomeIconProps = { icon: ['far', 'file'] };
    const pdfIconProps: FontAwesomeIconProps = { icon: ['far', 'file-pdf'], color: colors.pdfRed };
    const docIconProps: FontAwesomeIconProps = { icon: ['far', 'file-word'], color: colors.wordBlue };
    const excelIconProps: FontAwesomeIconProps = { icon: ['far', 'file-excel'], color: colors.excelGreen };
    const powerpointIconProps: FontAwesomeIconProps = { icon: ['far', 'file-powerpoint'], color: colors.powerpointOrange };
    const csvIconProps: FontAwesomeIconProps = { icon: ['far', 'file-csv'] };
    const textFileIconProps: FontAwesomeIconProps = { icon: ['far', 'file-lines'] };
    const zipFileIconProps: FontAwesomeIconProps = { icon: ['far', 'file-zipper'], color: colors.utopiaZip };
    const movieIconProps: FontAwesomeIconProps = { icon: ['far', 'file-video'], color: colors.utopiaMovie };
    const imageIconProps: FontAwesomeIconProps = { icon: ['far', 'file-image'], color: colors.utopiaPhoto };
    const audioIconProps: FontAwesomeIconProps = { icon: ['far', 'file-audio'], color: colors.utopiaAudio };
    const emailIconProps: FontAwesomeIconProps = { icon: ['far', 'envelope'] };
    const spreadsheetIconProps: FontAwesomeIconProps = { icon: ['far', 'file-spreadsheet'], color: colors.utopiaExcel };
    const invoiceIconProps: FontAwesomeIconProps = { icon: ['far', 'file-invoice'], color: colors.utopiaPowerPoint };

    const getNodeIconProps = (nodeType: NodeType, extension?: string): FontAwesomeIconProps => {
        switch (nodeType) {
            case NodeType.Account:
                return accountIconProps;
            case NodeType.Cabinet:
                return cabinetIconProps;
            case NodeType.Drawer:
                return drawerIconProps;
            case NodeType.Folder:
                return newFolderIconProps;
            case NodeType.File:
            case NodeType.TemplateFile:
                switch (extension) {
                    case '.pdf':
                        return pdfIconProps;
                    case '.doc':
                    case '.docx':
                        return docIconProps;
                    case '.xls':
                    case '.xlsx':
                        return excelIconProps;
                    case '.ppt':
                    case '.pptx':
                        return powerpointIconProps;
                    case '.gddoc':
                    case '.rtf':
                    case '.txt':
                        return textFileIconProps;
                    case '.png':
                    case '.jpg':
                    case '.jpeg':
                    case '.gif':
                    case '.bmp':
                    case '.tif':
                    case '.tiff':
                    case '.svg':
                        return imageIconProps;
                    case '.eml':
                        return emailIconProps;
                    case '.mov':
                    case '.mpg':
                    case '.mp4':
                        return movieIconProps;
                    case '.wav':
                    case '.mp3':
                        return audioIconProps;
                    case '.zip':
                        return zipFileIconProps;
                    case '.csv':
                        return csvIconProps;
                    case '.gdspreadsheet':
                        return spreadsheetIconProps;
                    case '.gdpres':
                        return invoiceIconProps;
                    default:
                        return fileIconProps;
                }
            default:
                return fileIconProps;
        }
    };
    
    // Only need to call this once on startup of the app (currently in App.tsx)
    const loadIcons = () => {
        library.add(fas as IconPack, far as IconPack, fal as IconPack, faCircleA, faCircleB, faCircleC, faCircleD, faCircleE, faCircleF,
            faCircleG, faCircleH, faCircleI, faCircleJ, faCircleK, faCircleL, faCircleM, faCircleN,
            faCircleO, faCircleP, faCircleQ, faCircleR, faCircleS, faCircleT, faCircleU, faCircleV,
            faCircleW, faCircleX, faCircleY, faCircleZ,
        );
    };

    return {
        spinnerIconProps,
        getNodeIconProps,
        loadIcons,
    };
};
