import React, { useEffect, useRef } from 'react';
import { EfcCallToActionInfo, Form, FormGroup, Input, Label, Modal } from '@efilecabinet/efc-atlantis-components';
import { AtlantisTaskDTO, AtlantisTaskStatus } from '../../../../api/tasks/taskApiTypes';
import { IUseModalProps } from '../../../../hooks/useModal';
import { AddTaskModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { useForm } from '../../../../hooks/useForm';

interface AddTaskModalProps extends IUseModalProps {
    submitTask: (text: AtlantisTaskDTO) => void;
}

interface ITaskForm {
    taskText: string;
}

export const AddTaskModal = (props: AddTaskModalProps) => {
    const { isOpen, close, submitTask } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const { t } = useSafeTranslation(TranslationFiles.AddTaskModal);

    const initModel: ITaskForm = {
        taskText: '',
    };
    const taskForm = useForm(initModel);

    const clearForm = () => {
        taskForm.setObject(initModel);
    };

    const handleSubmit = () => {
        submitTask({ taskId: '', text: taskForm.model.taskText, status: AtlantisTaskStatus.Assigned });
        clearForm();
        !!close && close();
    };

    const handleCancel = () => {
        clearForm();
        !!close && close();
    };

    const MAX_INPUT_CHARS = 250;

    const ctas: EfcCallToActionInfo[] = [
        { text: t(AddTaskModalTKeys.Cancel), emphasis: 'med', color: 'primary', onClick: handleCancel, dataId: 'btnCancelAddTask' },
        { text: t(AddTaskModalTKeys.Add), emphasis: 'high', color: 'primary', disabled: !taskForm.model.taskText, onClick: handleSubmit, dataId: 'btnAddTask' },
    ];

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                (inputRef?.current as any)?.focus();
            });
        }
    }, [isOpen]);

    return (
        <Modal title={t(AddTaskModalTKeys.Title)} toggle={handleCancel} isOpen={isOpen} ctas={ctas} unmountOnClose={true}>
            <Modal.Body>
                <Form>
                    <FormGroup>
                        <Label for='taskText'>{t(AddTaskModalTKeys.Label)}</Label>
                        <Input
                            innerRef={inputRef}
                            id='taskText'
                            name='taskText'
                            dataId='txtTaskText'
                            placeholder={t(AddTaskModalTKeys.PlaceHolder)}
                            value={taskForm.model.taskText}
                            maxLength={MAX_INPUT_CHARS}
                            onChange={taskForm.onPropChanged}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSubmit();
                                    e.preventDefault();
                                }
                            }}
                        />
                    </FormGroup>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
