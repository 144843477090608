import React, { useEffect, useState } from 'react';
import { Alert, Button, FormGroup, Input, Label, Modal, Text } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, UsersTKeys, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { ThemeEnum } from '../../../hooks/useColors';
import { NestedCollapse } from '../../../components/NestedCollapse';
import { useIcons } from '../../../hooks/useIcons';
import { ToastMessage } from '../../../hooks/useToastMessages';
import { useLayerContext } from '../../../context/layer/LayerContext';
import { RoleDTO } from '../../../api/roles/roleApiTypes';
import './deleteUserConfirmationModal.css';

interface DeleteUserConfirmationModalProps {
    onClose: () => void;
    roles: RoleDTO[];
    type: 'SHOW_CONFIRM_DELETE_USER_MODAL' | 'SHOW_CONFIRM_REMOVE_LICENSE_MODAL';
    editSingleRole?: boolean;
}

interface DeleteMessageToUtopia {
    type: string;
    roles: RoleDTO[];
    revoke: boolean;
}

export const DeleteUserConfirmationModal = ({ onClose, roles, type, editSingleRole }: DeleteUserConfirmationModalProps) => {
    const { t } = useSafeTranslation(TranslationFiles.Users);
    const { spinnerIconProps } = useIcons();
    const { showToastMessage } = useLayerContext();

    const [hasError, setHasError] = useState(false);
    const [revoke, setRevoke] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const title = type === 'SHOW_CONFIRM_DELETE_USER_MODAL' ? t(UsersTKeys.DeleteModalTitle) : t(UsersTKeys.RemoveModalTitle);
    const body = type === 'SHOW_CONFIRM_DELETE_USER_MODAL' ? t(UsersTKeys.DeleteModalBody, { count: roles?.length }) : t(UsersTKeys.RemoveModalBody, { count: roles?.length });

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => setRevoke(e.target.checked);

    const handleConfirm = () => {
        setShowSpinner(true);
        const confirmType = type === 'SHOW_CONFIRM_DELETE_USER_MODAL' ? 'CONFIRM_DELETE_USER' : editSingleRole ? 'CONFIRM_REMOVE_SINGLE_LICENSE' : 'CONFIRM_REMOVE_LICENSE';
        const userIframe = document.querySelector('[data-id="userIframe"]') as HTMLIFrameElement;
        userIframe?.contentWindow?.postMessage({ type: confirmType, roles, revoke } as DeleteMessageToUtopia, '*');
    };

    const handleResponse = (e: MessageEvent) => {
        const { type, success, revoke, roles } = e.data;
        if (type === 'DELETE_USER_RESULT' || type === 'REMOVE_LICENSE_RESULT') {
            setShowSpinner(false);

            if (!success) {
                setHasError(true);
                return;
            }

            let message = '';

            if (revoke) {
                message = type === 'DELETE_USER_RESULT' ? t(UsersTKeys.DeleteRevokeSuccess, { count: roles?.length, displayName: roles[0].name }) : t(UsersTKeys.RemoveRevokeSuccess, { count: roles?.length });
            } else {
                message = type === 'DELETE_USER_RESULT' ? t(UsersTKeys.DeleteSuccess, { count: roles?.length, displayName: roles[0].name }) : t(UsersTKeys.RemoveSuccess, { count: roles?.length });
            }

            const toastData = {
                color: ThemeEnum.Success,
                identifier: 'delete-user-result-toast',
                message,
                timeout: 5000,
            } as ToastMessage;

            showToastMessage(toastData);
            onClose();
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleResponse);

        return () => {
            window.removeEventListener('message', handleResponse);
        };
    }, []);

    return (
        <Modal isOpen={true} toggle={onClose} title={title} dataId='delete-user-confirmation-modal'>
            <Modal.Body>
                {hasError && (
                    <Alert color={ThemeEnum.Danger} className='mb-3'>
                        {type === 'SHOW_CONFIRM_DELETE_USER_MODAL' ? t(UsersTKeys.DeleteError, { count: roles?.length }) : t(UsersTKeys.RemoveError, { count: roles?.length })}
                    </Alert>
                )}
                <Text className='d-block mt-2 mb-4'>{body}</Text>
                <ul className='modal-body-scrollable'>
                    {roles?.map((role, index) => (
                        <li key={index}>{role.name}</li>
                    ))}
                </ul>
                <NestedCollapse title={t(UsersTKeys.DeleteAdvancedOptionLabel)} innerClassName=''>
                    <FormGroup check className='pointer'>
                        <Label check>
                            <Input type='checkbox' checked={revoke} onChange={(e) => handleCheckboxChange(e)} dataId='revoke-permissions-and-shares' />
                            {t(UsersTKeys.DeleteAdvancedOption, { count: roles?.length })}
                        </Label>
                    </FormGroup>
                    <Alert color={ThemeEnum.Warning} className='mt-2 warning'>
                        {t(UsersTKeys.DeleteWarning)}
                    </Alert>
                </NestedCollapse>
            </Modal.Body>
            <Modal.Footer>
                <Button emphasis='med' onClick={onClose} disabled={showSpinner} dataId='delete-user-modal-cancel'>
                    {t(UsersTKeys.Cancel)}
                </Button>
                <Button color={ThemeEnum.Danger} icon={showSpinner ? spinnerIconProps : undefined} onClick={handleConfirm} disabled={showSpinner} dataId='delete-user-modal-confirm'>
                    {t(UsersTKeys.Confirm)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
