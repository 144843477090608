import React, { useState } from 'react';
import { EfcFeedItemInfo } from '@efilecabinet/efc-atlantis-components';
import { ESignatureTaskModal } from '../features/_toDoList/__shared/eSignatureTaskModal/eSignatureTaskModal';
import { useRouting } from './Routing/useRouting';
import { useModal } from './useModal';
import { useUtopiaRedirect } from './useUtopiaRedirect';
import { useAuthContext } from '../auth';
import { TranslationFiles, useSafeTranslation, UtopiaNotificationsConverterTKeys } from './useSafeTranslation';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { UtopiaESignatureTaskDTO, UtopiaTaskNotificationDTO, UtopiaDocRequestTaskDTO, UtopiaWorkflowTaskDTO, UtopiaNotificationDTO, UtopiaNotificationTypeEnum, IUtopiaAlert } from '../api/notifications/notificationApiTypes';
import { SystemTypeEnum, SystemActionEnum } from '../api/sharedApiTypes';
import { IToDo, SourceTypeEnum } from '../features/_toDoList/toDoListTypes';

const CanNavigateToAlertTypes = new Set<SystemTypeEnum>([
    SystemTypeEnum.Node,
    SystemTypeEnum.NodeShare,
    SystemTypeEnum.TimeTrigger,
    SystemTypeEnum.NodeComment,
    SystemTypeEnum.DocumentRequest,
    SystemTypeEnum.WorkflowInstance,
    SystemTypeEnum.WorkflowInstanceStage
]);

export const useUtopiaNotificationsConverter = () => {
    const [utopiaESignatureTask, setUtopiaESignatureTask] = useState<UtopiaESignatureTaskDTO>({} as UtopiaESignatureTaskDTO);

    const eSignatureModalNew = useModal();
    const { t } = useSafeTranslation(TranslationFiles.UtopiaNotificationsConverter);

    const { routeToDocRequestInboxRequest, routeToWorkflowInstance } = useRouting();
    const { redirectUtopiaNotificationToAtlantis } = useUtopiaRedirect();
    const authContext = useAuthContext();

    const defaultIconProps: FontAwesomeIconProps = { icon: 'circle-check' };

    function isEsignatureTask(utopiaTaskNotification: UtopiaTaskNotificationDTO): utopiaTaskNotification is UtopiaESignatureTaskDTO {
        return (utopiaTaskNotification as UtopiaESignatureTaskDTO).senderDisplayName != undefined
            && (utopiaTaskNotification as UtopiaESignatureTaskDTO).externalType != undefined;
    }
    
    function isDocRequestTask(utopiaTaskNotification: UtopiaTaskNotificationDTO): utopiaTaskNotification is UtopiaDocRequestTaskDTO {
        return (utopiaTaskNotification as UtopiaDocRequestTaskDTO).documentRequestId != undefined
            && (utopiaTaskNotification as UtopiaDocRequestTaskDTO).senderDisplayName != undefined
            && (utopiaTaskNotification as UtopiaDocRequestTaskDTO).fileUploadCount != undefined
            && (utopiaTaskNotification as UtopiaDocRequestTaskDTO).formFillCount != undefined;
    }
    
    function isWorkflowTask(utopiaTaskNotification: UtopiaTaskNotificationDTO): utopiaTaskNotification is UtopiaWorkflowTaskDTO {
        return (utopiaTaskNotification as UtopiaWorkflowTaskDTO).workflowInstanceId != undefined
            && (utopiaTaskNotification as UtopiaWorkflowTaskDTO).workflowStageInstanceId != undefined
            && (utopiaTaskNotification as UtopiaWorkflowTaskDTO).workflowInstanceName != undefined;
    }

    function getIcon(notification: UtopiaNotificationDTO): FontAwesomeIconProps {
        switch (notification.systemType) {
            case SystemTypeEnum.DocumentRequest:
                return { icon: 'inbox' };
            case SystemTypeEnum.ESignatureTransaction:
                return { icon: ['far', 'file-signature'] };
            case SystemTypeEnum.WorkflowInstance:
            case SystemTypeEnum.WorkflowInstanceStage:
                return { icon: 'wand-magic-sparkles' };
            case SystemTypeEnum.Node:
                if (notification.actionType == SystemActionEnum.FileUpload) {
                    return { icon: ['far', 'upload'] };
                } else if (notification.actionType == SystemActionEnum.FileDownload) {
                    return { icon: 'download' };
                } else if (notification.actionType == SystemActionEnum.Delete) {
                    return { icon: 'trash-can' };
                } else {
                    return defaultIconProps;
                }
            case SystemTypeEnum.NodeComment:
                return { icon: 'comment' };
            case SystemTypeEnum.NodeShare:
                return { icon: 'share-nodes' };
            case SystemTypeEnum.Role:
                return { icon: 'building' };
            case SystemTypeEnum.TimeTrigger:
            case SystemTypeEnum.Account:
                return { icon: 'shield' };
            case SystemTypeEnum.ProgressNotification:
                if (notification.content.toLowerCase().includes('audit log')) {
                    return { icon: 'clock-rotate-left' };
                } else if (notification.content.toLowerCase().includes('search results')) {
                    return { icon: 'search' };
                } else if (notification.content.toLowerCase().includes('account template')) {
                    return { icon: 'building' };
                } else if (notification.actionType == SystemActionEnum.Completed) {
                    return defaultIconProps;
                } else {
                    return { icon: ['far', 'circle-xmark'] };
                }
            default:
                return defaultIconProps;
        }
    }

    function toFeedItem(utopiaNotification: UtopiaNotificationDTO): EfcFeedItemInfo {
        if (utopiaNotification.notificationType == UtopiaNotificationTypeEnum.Alert) {
            return {
                id: String(utopiaNotification.id),
                title: utopiaNotification.content,
                feedDate: new Date(utopiaNotification.dateQueued),
                icon: getIcon(utopiaNotification),
                subtitle: !!(utopiaNotification as IUtopiaAlert).supportText && utopiaNotification.systemType != SystemTypeEnum.NodeComment ? (utopiaNotification as IUtopiaAlert).supportText : undefined,
                blockText: !!(utopiaNotification as IUtopiaAlert).supportText && utopiaNotification.systemType == SystemTypeEnum.NodeComment ? (utopiaNotification as IUtopiaAlert).supportText : undefined,
                onClick: !!utopiaNotification.itemUrl && CanNavigateToAlertTypes.has(utopiaNotification.systemType) ? () => redirectUtopiaNotificationToAtlantis(utopiaNotification.itemUrl, authContext) : undefined,
            };
        } else {
            const toDoItem = toToDo(utopiaNotification as UtopiaTaskNotificationDTO);
            return {
                id: String(utopiaNotification.id),
                title: toDoItem.text,
                feedDate: new Date(utopiaNotification.dateQueued),
                icon: getIcon(utopiaNotification),
                onClick: toDoItem.cta.onClick,
            };
        }
    }

    function getDocRequestTaskText(docRequestTask: UtopiaDocRequestTaskDTO) {
        const { fileUploadCount, formFillCount } = docRequestTask;

        if (fileUploadCount > 0 && formFillCount > 0) {
            return t(UtopiaNotificationsConverterTKeys.DocRequestTextUploadAndFormFill, { fileUploadCount: fileUploadCount, formFillCount: formFillCount });
        }
        else if (fileUploadCount > 0) {
            return t(UtopiaNotificationsConverterTKeys.DocRequestTextUpload, { count: fileUploadCount });
        }
        else { // formFillCount > 0
            return t(UtopiaNotificationsConverterTKeys.DocRequestTextFormFill, { count: formFillCount });
        }
    }

    function openESignatureTaskModal(utopiaESignatureTask: UtopiaESignatureTaskDTO) {
        setUtopiaESignatureTask(utopiaESignatureTask);
        eSignatureModalNew.open();
    }

    function getDynamicTaskInfo(utopiaNotification: UtopiaTaskNotificationDTO) {
        if (isDocRequestTask(utopiaNotification)) {
            return {
                text: getDocRequestTaskText(utopiaNotification as UtopiaDocRequestTaskDTO),
                from: `${utopiaNotification.senderDisplayName}`,
                ctaText: t(UtopiaNotificationsConverterTKeys.DocRequestCtaText),
                ctaClick: () => routeToDocRequestInboxRequest(utopiaNotification.documentRequestId),
            };
        } else if (isEsignatureTask(utopiaNotification)) {
            return {
                text: utopiaNotification.content,
                from: `${utopiaNotification.senderDisplayName}`,
                ctaText: t(UtopiaNotificationsConverterTKeys.ESignatureCtaText),
                ctaClick: () => openESignatureTaskModal(utopiaNotification as UtopiaESignatureTaskDTO),
            };
        } else if (isWorkflowTask(utopiaNotification)) {
            if (utopiaNotification.actionType == SystemActionEnum.Assignment) {
                return {
                    text: t(UtopiaNotificationsConverterTKeys.WorkflowAssignmentText, { instanceName: utopiaNotification.workflowInstanceName }),
                    from: t(UtopiaNotificationsConverterTKeys.WorkflowFrom),
                    ctaText: t(UtopiaNotificationsConverterTKeys.WorkflowAssignmentCtaText),
                    ctaClick: () => routeToWorkflowInstance(utopiaNotification.accountID, utopiaNotification.workflowInstanceId.toString(), authContext),
                };
            } else {
                return {
                    text: t(UtopiaNotificationsConverterTKeys.WorkflowApprovalText, { instanceName: utopiaNotification.workflowInstanceName }),
                    from: t(UtopiaNotificationsConverterTKeys.WorkflowFrom),
                    ctaText: t(UtopiaNotificationsConverterTKeys.WorkflowApprovalCtaText),
                    ctaClick: () => routeToWorkflowInstance(utopiaNotification.accountID, utopiaNotification.workflowInstanceId.toString(), authContext),
                };
            }
        } else {
            return {
                text: (utopiaNotification as UtopiaNotificationDTO).content,
                from: t(UtopiaNotificationsConverterTKeys.DefaultFrom),
                ctaText: t(UtopiaNotificationsConverterTKeys.DefaultCtaText),
                ctaClick: () => {
                    /* do nothing*/
                },
            };
        }
    }

    function toToDo(utopiaNotification: UtopiaTaskNotificationDTO): IToDo {
        const dynamicUtopiaNotificationInfo = getDynamicTaskInfo(utopiaNotification);
        return {
            id: utopiaNotification.id.toString(),
            icon: getIcon(utopiaNotification),
            text: dynamicUtopiaNotificationInfo.text,
            from: dynamicUtopiaNotificationInfo.from,
            cta: {
                text: dynamicUtopiaNotificationInfo.ctaText,
                color: 'primary',
                emphasis: 'med',
                onClick: dynamicUtopiaNotificationInfo.ctaClick,
            },
            createdDate: utopiaNotification.dateQueued,
            completedDate: utopiaNotification.completedDate,
            dismissedDate: utopiaNotification.dismissedDate,
            sourceType: SourceTypeEnum.Utopia,
        };
    }

    return {
        eSignatureModal: eSignatureModalNew.render(<ESignatureTaskModal utopiaESignatureTask={utopiaESignatureTask} />),
        toFeedItem,
        toToDo,
    };
};
