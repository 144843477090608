import React, { useEffect, useState, useRef, ChangeEvent } from 'react';
import { Button, EfcLink, INode, Modal } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, UploadLinkTKeys, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { useAuthContext } from '../../../../auth';
import { useUploadLinkApi } from '../../../../api/uploadLinks/useUploadLinkApi';
import { CreateUploadLink } from './CreateUploadLink';
import { CreatedUploadLink } from './CreatedUploadLink';
import { ViewUploadLink } from './ViewUploadLink';
import { useIcons } from '../../../../hooks/useIcons';
import { ThemeEnum } from '../../../../hooks/useColors';
import { UploadLinkDTO } from '../../../../api/uploadLinks/uploadLinkApiTypes';
import './UploadLink.css';
import { useSettingsAndFeatures } from '../../../../context/settingsAndFeatures/SettingsFeaturesContext';

export interface UploadLinkProps {
    destroyModal: () => void;
    node: INode;
    uploadLink: UploadLinkDTO;
    type: string;
}

enum UploadLinkModalState {
    Create,
    Created,
    View,
}

export const UploadLinkModal = (props: UploadLinkProps) => {
    const { destroyModal, node, type, uploadLink } = props;

    const { t } = useSafeTranslation(TranslationFiles.UploadLinkModal);
    const { createUploadLink } = useUploadLinkApi();
    const { hasUserAccounts, userAccounts } = useAuthContext();
    const { spinnerIconProps } = useIcons();
    const { settings, hasSettings } = useSettingsAndFeatures();

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const create = type === 'SHOW_CREATE-UPLOAD-LINK_MODAL';
    const title = create ? t(UploadLinkTKeys.CreateTitle) : t(UploadLinkTKeys.ViewTitle);
    const CLOSE_MODAL_DELAY = 800;

    const [modalState, setModalState] = useState<UploadLinkModalState>(create ? UploadLinkModalState.Create : UploadLinkModalState.View);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [subject, setSubject] = useState<string>('');
    const [newUploadLink, setNewUploadLink] = useState<string>(uploadLink?.uploadLinkURL);
    const [error, setError] = useState<boolean>(false);
    const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
    const [copyBtnText, setCopyBtnText] = useState<string>(t(UploadLinkTKeys.CopyURL));
    const [learnMoreLink, setLearnMoreLink] = useState<string>();

    const copyAndClose = async () => {
        setCopyBtnText(t(UploadLinkTKeys.Copied));
        await navigator.clipboard.writeText(newUploadLink);

        timeoutRef.current = setTimeout(() => {
            destroyModal();
        }, CLOSE_MODAL_DELAY);
    };

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => setSubject(e.target.value);

    const toggleDisable = (value: boolean) => setDisableSubmit(value);

    const handleCreateUploadLink = async () => {
        if (!node.id) return;

        setError(false);
        setShowSpinner(true);

        try {
            const response = await createUploadLink(node.id, subject, node.accountID);

            if (response.errorMessage) throw new Error(response.errorMessage);

            setNewUploadLink(response.uploadLink.uploadLinkURL);
            setModalState(UploadLinkModalState.Created);
        } catch (error) {
            console.error(error);
            setError(true);
        } finally {
            setShowSpinner(false);
        }
    };

    useEffect(() => {
        if (!!hasUserAccounts && !!node) {
            const account = (userAccounts as any)?.get(node.accountID);
            setSubject(`Share documents with ${account.accountName}`);
        }
    }, [hasUserAccounts, node]);

    useEffect(() => {
        if (!!hasSettings) {
            setLearnMoreLink(settings?.uploadLinkLearnMoreUrl);
        }
    }, [hasSettings]);

    useEffect(() => {
        // Cleanup function to clear timeout when component unmounts
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return (
        <Modal title={title} toggle={destroyModal} isOpen={true} backdrop='static' dataId='upload-link-modal'>
            <Modal.Body>
                {modalState === UploadLinkModalState.Create && <CreateUploadLink {...props} subject={subject} error={error} toggleDisable={toggleDisable} handleInput={handleInput} />}
                {modalState === UploadLinkModalState.Created && <CreatedUploadLink nodeName={node.name} uploadLinkURL={newUploadLink} />}
                {modalState === UploadLinkModalState.View && <ViewUploadLink {...props} />}
            </Modal.Body>
            <Modal.Footer>
                {modalState === UploadLinkModalState.Create && (
                    <>
                        <Button emphasis='med' onClick={destroyModal} dataId='cancel-upload-link'>
                            {t(UploadLinkTKeys.Cancel)}
                        </Button>
                        <Button onClick={handleCreateUploadLink} dataId='create-upload-link' disabled={disableSubmit} icon={showSpinner ? spinnerIconProps : undefined}>
                            {t(UploadLinkTKeys.CreateLink)}
                        </Button>
                    </>
                )}
                {modalState !== UploadLinkModalState.Create && (
                    <div className='w-100 d-flex justify-content-between align-items-center'>
                        <EfcLink href={learnMoreLink} newWindow color={ThemeEnum.Primary}>
                            {learnMoreLink ? t(UploadLinkTKeys.LearnMore) : ''}
                        </EfcLink>
                        <Button onClick={copyAndClose} dataId='copy-close-upload-link'>
                            {copyBtnText}
                        </Button>
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    );
};
