import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, TooltipItem } from '@efilecabinet/efc-atlantis-components';
import { AuthContext, IAuthContext } from '../../../auth';
import { useColors } from '../../../hooks/useColors';
import { useCardSizes } from '../../../hooks/useCardSizes';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { TranslationFiles, useSafeTranslation, CollectionsTKeys } from '../../../hooks/useSafeTranslation';
import { CollectionDTO } from '../../../api/collections/collectionApiTypes';
import { useCollectionsApi } from '../../../api/collections/useCollectionsApi';
import 'react-loading-skeleton/dist/skeleton.css';
import './Collections.css';

export const Collections = () => {
    const { routeToCollections, getLinkToCollections, getLinkToCollection } = useRouting();
    const { user } = useContext(AuthContext) as IAuthContext;

    const [collectionsList, setCollectionsList] = useState<CollectionDTO[]>([]);

    const colors = useColors();
    const { t } = useSafeTranslation(TranslationFiles.Collections);
    const { getCardTitleSize, getCardIconSize } = useCardSizes();

    const { getCollections } = useCollectionsApi();

    const NUM_COLLECTIONS = 5;

    const filterCollections = (collection: CollectionDTO) => {
        return collection.id !== user?.preferences.defaultCollectionID;
    };

    useEffect(() => {
        getCollections().then((collections: CollectionDTO[]) => {
            setCollectionsList(collections);
        });
    }, []);

    return (
        <>
            {!!user && collectionsList.filter(filterCollections).length > 0 && (
                <>
                    <Card.Title tag={Link} to={getLinkToCollections()} className='mt-5 ms-1 no-decoration' dataId="lblCollections" size={getCardTitleSize()} bold icon={{ icon: 'folder-heart', pull: 'left', color: colors.atlantisFavsAndCollections, size: getCardIconSize() }}>
                        {t(CollectionsTKeys.CollectionsTitle)}
                    </Card.Title>

                    <>
                        {collectionsList
                            .filter(filterCollections)
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .slice(0, NUM_COLLECTIONS)
                            .map((collection) => (
                                <Card.Link dataId={`lbl-${collection.name}`} key={collection.id} tag={Link} to={getLinkToCollection(collection.id.toString())} className='ms-1 my-3' icon={{ icon: 'folder-heart', size: 'lg' }}>
                                    <TooltipItem innerClassName='truncated-text' id={collection.id.toString()} message={collection.name}>
                                        {collection.name}
                                    </TooltipItem>
                                </Card.Link>
                            ))}

                        {collectionsList.filter(filterCollections).length > NUM_COLLECTIONS && (
                            <div className='d-flex justify-content-center'>
                                <Button color='primary' emphasis='low' onClick={() => routeToCollections()}>
                                    {t(CollectionsTKeys.SeeAllText)}
                                </Button>
                            </div>
                        )}
                    </>
                </>
            )}
        </>
    );
};