export interface QueryMDFieldsDTO {
    accountId: string;
    mdFieldIds?: string[];
}

export interface ValidateFieldNameDTO {
    accountId: string;
    name: string;
}

export interface MDFieldDTO {
    id: string;
    accountId?: string;
    name: string;
    type: MDFieldTypes;
    appliedInstanceCount?: number;
    createdByUserId?: string;
    createdByDisplayName?: string;
    system?: boolean;

    textProps?: TextMDFieldDTO;
    numberProps?: NumberMDFieldDTO;
    addressProps?: AddressMDFieldDTO;
    dateProps?: DateMDFieldDTO;
    checkboxProps?: CheckboxMDFieldDTO;
    dropdownListProps?: DropdownListMDFieldDTO;
}

export enum MDFieldTypes {
    Text = 0,
    Email,
    Address,
    Date,
    PhoneNumber,
    Checkbox, //True/False
    Number,
    DropdownList,
}

export interface TextMDFieldDTO {
    textFormat: TextFormatTypes;
    allowedCharacters: AllowedCharacterTypes;
    minChars?: number;
    maxChars?: number;
    prefix?: string;
    sensitiveDataFormat?: SensitiveDataFormatDTO;
}

export enum TextFormatTypes {
    SingleLine = 0,
    MultiLine,
}

export enum AllowedCharacterTypes {
    Alphanumeric = 0,
    Alpha,
}

export interface SensitiveDataFormatDTO {
    sensitiveDataShown: boolean;
    firstChars?: number;
    lastChars?: number;
}

export interface NumberMDFieldDTO {
    numberFormat: NumberFormatTypes;
    minValue?: number;
    maxValue?: number;
    sensitiveDataFormat?: SensitiveDataFormatDTO;
}

export enum NumberFormatTypes {
    Number = 0,
    Percentage,
    Decimal,
    Currency,
}

export interface AddressMDFieldDTO {
    addressOne: boolean;
    addressTwo: boolean;
    city: boolean;
    state: boolean;
    postal: boolean;
    country: boolean;
}

export interface DateMDFieldDTO {
    dateFormat: DateFormatTypes;
}

export enum DateFormatTypes {
    mmddyyyy = 0,
    ddmmyyyy,
    yyyymmdd,
    monthddyyyy,
    ddmonthyyyy,
}

export interface CheckboxMDFieldDTO {
    checkboxChecked: boolean;
}

export interface DropdownListMDFieldDTO {
    dropdownListFormat: DropdownListFormatTypes;
    allowNewOptions: boolean;
    listValue?: MDOptionItemDTO[];
}

export interface MDOptionItemDTO {
    id: string;
    optionName: string;
    isDefault?: boolean;
}

export enum DropdownListFormatTypes {
    SingleSelect = 0,
    MultiSelect
}