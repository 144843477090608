import React, { useEffect, useState } from 'react';
import { Card, Carousel, UncontrolledCarousel } from '@efilecabinet/efc-atlantis-components';
import { useProductUpdatesApi } from '../../../api/productUpdates/useProductUpdatesApi';
import { ProductUpdateItem } from './ProductUpdateCardTemplates/ProductUpdateItem';
import { TranslationFiles, useSafeTranslation, ProductUpdatesTKeys } from '../../../hooks/useSafeTranslation';
import { useSettingsAndFeatures } from '../../../context/settingsAndFeatures/SettingsFeaturesContext';
import { useAuthContext } from '../../../auth';
import { useSystemPermissionsValidator } from '../../../hooks/useSystemPermissionsValidators';
import { ProductUpdateCardDTO, ProductUpdateTemplateTypes } from '../../../api/productUpdates/productUpdateApiTypes';
import { FeatureFlags } from '../../../context/settingsAndFeatures/settingsAndFeaturesTypes';
import { SystemPermissionEnum } from '../../../api/roles/roleApiTypes';

export interface IProductUpdatesProps {
    className?: string;
}

export const ProductUpdates = (props: IProductUpdatesProps) => {
    const { className } = props;
    const { getProductUpdateCards } = useProductUpdatesApi();
    const [cards, setCards] = useState<ProductUpdateCardDTO[]>([]);
    const { hasFeatureFlags, checkFeatureFlag } = useSettingsAndFeatures();

    const { t } = useSafeTranslation(TranslationFiles.ProductUpdates);

    const { authUser } = useAuthContext();
    const { hasPermissionOnAccount } = useSystemPermissionsValidator();

    const currentAccountId = authUser?.accountID ?? 0;

    const loadCardDataAsync = async () => {
        const data: ProductUpdateCardDTO[] = filterCardData(await getProductUpdateCards());
        setCards(data);
    };

    const filterCardData = (data?: ProductUpdateCardDTO[]) => {
        if (!data) {
            return [] as ProductUpdateCardDTO[];
        }

        return data.filter((d) => {
            if (d.templateType === ProductUpdateTemplateTypes.General) {
                return d;
            }

            if (d.templateType === ProductUpdateTemplateTypes.PreviewerX && !!hasFeatureFlags && checkFeatureFlag(FeatureFlags.PreviewerXEnabled)) {
                return d;
            }

            if (d.templateType === ProductUpdateTemplateTypes.Branding && !!hasFeatureFlags && checkFeatureFlag(FeatureFlags.BrandingEnabled) && hasPermissionOnAccount(currentAccountId, SystemPermissionEnum.SystemSettings)) {
                return d;
            }
        });
    };

    useEffect(() => {
        loadCardDataAsync();
    }, []);

    return (
        <>
            {!!cards.length && (
                <Card fluid borderless className={className}>
                    <Card.Body>
                        <Card.Title size='xl' bold icon={{ icon: ['far', 'lightbulb'] }}>
                            {t(ProductUpdatesTKeys.Title)}
                        </Card.Title>
                        <UncontrolledCarousel interval={false} selectorPosition={{ bottom: '-.7rem', left: '265px' }}>
                            {cards.map((card, index) => (
                                <Carousel.Item key={index}>
                                    <ProductUpdateItem {...card} />
                                </Carousel.Item>
                            ))}
                        </UncontrolledCarousel>
                    </Card.Body>
                </Card>
            )}
        </>
    );
};
