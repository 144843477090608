import { useNavigate } from 'react-router';
import { RoutePath } from './routingTypes';

export const useFormsRouting = () => {

    const navigate = useNavigate();

    const getLinkToForms = () => {
        return RoutePath.Forms;
    };

    const routeToForms = () => {
        navigate(getLinkToForms());
    };

    return {
        getLinkToForms,
        routeToForms,
    };
};
