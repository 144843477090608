import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { ResizableCol, Tab, Tabs } from '@efilecabinet/efc-atlantis-components';
import { useResizableColUtilities } from '../../../../../hooks/useResizableColUtilities';
import { SideSheetResizerSlider } from '../../../../../components/SideSheet/SideSheetResizerSlider';
import { SideSheet } from '../../../../../components/SideSheet/SideSheet';
import { SideSheetTabButton } from '../../../../../components/SideSheet/SideSheetButton';
import { MDFieldSideSheetContent } from '../mdFieldSideSheetContent/mdFieldSideSheetContent';
import { MDGroupSideSheetContent } from '../mdGroupSideSheetContent/mdGroupSideSheetContent';
import { MDFieldDTO } from '../../../../../api/mdFields/mdFieldApiTypes';
import { MDGroupDTO } from '../../../../../api/mdGroups/mdGroupsApiTypes';
import { SelectedDetail } from '../../../../../hooks/Routing/routingTypes';
import './mdManagementSideSheet.css';

interface MDManagementSideSheetProps {
    selectedMDField?: MDFieldDTO;
    selectedMDGroup?: MDGroupDTO;
    currentRoute: string;
    refreshMDFieldsSentinalKeyState: [number, Dispatch<SetStateAction<number>>];
    refreshMDGroupsSentinalKeyState: [number, Dispatch<SetStateAction<number>>];
}

export const MDManagementSideSheet: FC<MDManagementSideSheetProps> = ({ selectedMDField, selectedMDGroup, currentRoute, refreshMDGroupsSentinalKeyState }) => {
    const [selectedDetail, setSelectedDetail] = useState<string>('');
    const [sideSheetIsOpen, setSideSheetIsOpen] = useState<boolean>(false);

    const { MIN_EXPANDED_WIDTH, onSidesheetCollapse, onSidesheetExpand,
        handleSideSheetResize, getSideSheetEnabled, getSideSheetWidth,
        getCanResize, setShowOverlay } = useResizableColUtilities();

    const collapseSideSheet = () => {
        setSideSheetIsOpen(false);
        setSelectedDetail('');
        onSidesheetCollapse();
    };

    const expandSideSheet = () => {
        setSideSheetIsOpen(true);
        onSidesheetExpand();
    };

    // I'm suspicious of SelectedDetail, i think it relates to the node sidesheet and doesn't apply here. 
    const handleTabClick = (tab: SelectedDetail) => {
        const selectedTab = tab as string;

        if (selectedTab == selectedDetail && sideSheetIsOpen) {
            collapseSideSheet();
            setSelectedDetail('');
            return;
        }

        expandSideSheet();

        setSelectedDetail(selectedTab);
    };

    const isTabDisabled = () => !selectedMDField?.name && !selectedMDGroup?.name;

    useEffect(() => {
        if (!isTabDisabled() && !sideSheetIsOpen) {
            setSelectedDetail(SelectedDetail.Details);
            expandSideSheet();
        }
    }, [selectedMDField, selectedMDGroup]);

    useEffect(() => { collapseSideSheet(); }, []);

    return (
        <>
            {!!getCanResize() && getSideSheetEnabled() && (
                <SideSheetResizerSlider onResize={handleSideSheetResize} />
            )}

            <ResizableCol xs='auto' direction='left' className='h-100 pe-0 sidesheet-col' width={getSideSheetWidth()} minWidth={MIN_EXPANDED_WIDTH} canResize={getCanResize()} onStartResize={() => setShowOverlay(true)} onEndResize={() => setShowOverlay(false)}>

                <SideSheet>
                    <Tabs vertical>
                        <Tab className='sidesheet-tab'>
                            <SideSheetTabButton dataId={`sideSheet-tab-${SelectedDetail.Details}`} isDisabled={isTabDisabled()} icon={{ icon: ['far', 'circle-info'] }} isActive={selectedDetail == SelectedDetail.Details} onClick={() => handleTabClick(SelectedDetail.Details)} />
                        </Tab>
                    </Tabs>

                    <Tab.Content dataId='sideSheet-pane' className="h-100">
                        {currentRoute == '/metadata/fields' && selectedDetail === SelectedDetail.Details && !!sideSheetIsOpen && <MDFieldSideSheetContent onClose={collapseSideSheet} mdField={selectedMDField as MDFieldDTO} />}
                        {currentRoute == '/metadata/groups' && selectedDetail === SelectedDetail.Details && !!sideSheetIsOpen && <MDGroupSideSheetContent onClose={collapseSideSheet} mdGroup={selectedMDGroup as MDGroupDTO} refreshMDGroupsSentinalKeyState={refreshMDGroupsSentinalKeyState} />}
                    </Tab.Content>
                </SideSheet>
            </ResizableCol>
        </>
    );
};
