import React from 'react';
import { Page } from '@efilecabinet/efc-atlantis-components';
import { IFrame } from '../../../components/IFrame/IFrame';
import { TranslationFiles, useSafeTranslation, GovernanceTKeys } from '../../../hooks/useSafeTranslation';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import './governancePage.css';

export const GovernancePage = () => {
    const { t } = useSafeTranslation(TranslationFiles.Governance);

    const governanceUrl = '/admin/governance';
    const iframe = useUtopiaIFrame(governanceUrl);

    return (
        <Page title={t(GovernanceTKeys.GovernanceTitle)} icon={{ icon: 'shield' }} >

            {/* Use the iframe until we make this page native */}

            <IFrame className='iframe' src={iframe.url} />

            {/* Use the Tabs and Tab Panes once we make this page native */}

        </Page>
    );
};
