import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Nav } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, VerticalNavTKeys, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { useRouteValidator } from '../../../hooks/useRouteValidator';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { useZIndexManager } from '../../../hooks/useZIndexManager';
import { RoutePath } from '../../../hooks/Routing/routingTypes';
import { CustomToggle } from './CustomToggle/CustomToggle';
import { BottomNavIcon } from './BottomNavIcon';
import { AllFeaturesSlideout } from './AllFeaturesSlideout/AllFeaturesSlideout';
import './BottomNav.css';

export const BottomNav = () => {
    const { canGoToRoute } = useRouteValidator();
    const { t } = useSafeTranslation(TranslationFiles.VerticalNav);
    const { getLinkToDocuments, getLinkToHome } = useRouting();
    const { getBottomNavZIndex } = useZIndexManager();

    const [isAllFeaturesActive, setisAllFeaturesActive] = useState(false);

    const onSelectAllFeatures = () => {
        setisAllFeaturesActive((prev) => !prev);
    };

    return (
        <div className='text-center bottom-nav-bar' style={{ zIndex: `${getBottomNavZIndex()}` }}>
            <Nav className='bottom-nav-list'>
                <Nav.Item dataId='bottomNavHome' text={t(VerticalNavTKeys.Home)} onClick={isAllFeaturesActive ? onSelectAllFeatures : undefined}>
                    <Nav.Link to={getLinkToHome()} tag={Link} className='w-100 h-100'>
                        <BottomNavIcon iconName='house-chimney' isActive={location.pathname == RoutePath.Home && !isAllFeaturesActive} transformRight='right-1' />
                    </Nav.Link>
                </Nav.Item>
            </Nav>

            {canGoToRoute(RoutePath.Documents) && (
                <Nav className='bottom-nav-list'>
                    <Nav.Item dataId='bottomNavDocuments' text={t(VerticalNavTKeys.Documents)} onClick={isAllFeaturesActive ? onSelectAllFeatures : undefined}>
                        <Nav.Link to={getLinkToDocuments()} tag={Link} className='w-100 h-100'>
                            <BottomNavIcon iconName='folder' isActive={location.pathname.startsWith(RoutePath.Documents) && !isAllFeaturesActive} />
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            )}

            {/* Hide until page view is ready
            <Nav className='bottom-nav-list'>
                <Nav.Item dataId='bottomNavUpload' text={t(VerticalNavTKeys.Upload)} onClick={isAllFeaturesActive ? onSelectAllFeatures : undefined}>
                    <Nav.Link to={RoutePath.Upload} tag={Link} className='w-100 h-100'>
                        <BottomNavIcon iconName='upload' isActive={location.pathname.startsWith(RoutePath.Upload) && !isAllFeaturesActive} />
                    </Nav.Link>
                </Nav.Item>
            </Nav>*/}

            <Nav className='bottom-nav-list mt-1'>
                <Nav.Item dataId='bottomNavAllFeatures' text={t(VerticalNavTKeys.AllFeatures)} onClick={onSelectAllFeatures}>
                    <Nav.Link className='w-100 h-100'>
                        <CustomToggle isActive={isAllFeaturesActive} />
                    </Nav.Link>
                </Nav.Item>
            </Nav>

            <AllFeaturesSlideout isOpen={isAllFeaturesActive} collapseAllFeatures={onSelectAllFeatures} />
        </div>
    );
};
