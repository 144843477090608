import React, { useContext } from 'react';
import { ToastMessage } from '../../hooks/useToastMessages';
import { TourType } from '../../components/Tours/tourTypes';

export const LayerContext = React.createContext<ILayerContext | null>(null);

export const useLayerContext = (): ILayerContext => {
    return useContext(LayerContext) as ILayerContext;
};

export interface ILayerContext {
    toastMessages: ToastMessage[];
    showToastMessage: (children: ToastMessage) => void;
    closeToastMessage: (identifier?: string) => void;

    isTourMenuOpen: boolean;
    toggleTourMenu: () => void;
    isTourOpen: (type: TourType) => boolean;
    toggleTour: (type: TourType) => void;

    previewerXDocViewCount: number;
    updatePreviewerXDocViewCount: (userId: number) => void;

    // see https://internal.revverdocs.com/books/atlantis/page/modals for documentation on how to use this function
    openModal: (getModalComponent: (closeModalFn: () => void) => JSX.Element) => void;
}