import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, FormGroup, Input, Label, Text, Button, Spinner, FormFeedback } from '@efilecabinet/efc-atlantis-components';
import { NestedChecklist } from '../../../../components/NestedChecklist';
import { DockedBar } from '../../../../components/DockedBar/DockedBar';
import { BrandingTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { useAuthContext } from '../../../../auth';
import { BrandingImageModal } from './BrandingImageModal';
import { useForm } from '../../../../hooks/useForm';
import { CompanyLogoConfig } from './BrandingImageEditor';
import { BrandingSaveConfirmModal } from './BrandingSaveConfirmModal';
import { useLayerContext } from '../../../../context/layer/LayerContext';
import { ThemeEnum } from '../../../../hooks/useColors';
import { GuestWelcomePreview } from './GuestWelcomePreview';
import { GuestWelcomeCard } from './GuestWelcomePreview/GuestWelcomePreview';
import { SignInPagePreview } from './SignInPagePreview';
import { useAccountApi } from '../../../../api/account/useAccountApi';
import { AccountBrandingDTO } from '../../../../api/branding/brandingApiTypes';
import { useBrandingApi } from '../../../../api/branding/useBrandingApi';
import { useNodeApi } from '../../../../api/node/useNodeApi';

interface BrandingFormProps {
    accountLogo?: string;
    accountMessage?: string;
    accountName?: string;
    logoOnSignInPage?: boolean;
    logoWithMessage?: boolean;
    newCname?: string;
    originalCname?: string;
}

export enum CnameState {
    Unset,
    Typing,
    Validating,
    Valid,
    Invalid,
}

export const CNAME_VALIDATION_DELAY = 2000;
export const VALID_CNAME_CHARS = /^[A-Za-z0-9-]+$/;
export const CNAME_CHARACTER_LIMIT = 63;
export const FORBIDDEN_ACCOUNT_NAME_CHARS = /[\\/:*?"<>|]/g;
export const ACCOUNT_NAME_LIMIT = 45;

export const Branding = () => {
    const WELCOME_CHARACTER_LIMIT = 200;

    const { saveBranding, getCnameAvailable, getBrandingByAccountIdentifier } = useBrandingApi();
    const { t } = useSafeTranslation(TranslationFiles.Branding);
    const { authUser, userAccounts, updateCurrentAccountBranding } = useAuthContext();
    const { showToastMessage, toastMessages, closeToastMessage } = useLayerContext();
    const { updateAccountNameInUtopia } = useAccountApi();
    const { updateAccountWorkspaceName } = useNodeApi();

    const [branding, setBranding] = useState<AccountBrandingDTO | undefined>(undefined);
    const [accountName, setAccountName] = useState<string | undefined>(undefined);

    const [logoModalIsOpen, setLogoModalIsOpen] = useState<boolean>(false);
    const [clearAccountLogo, setClearAccountLogo] = useState<boolean>(false);
    const [accountLogo, setAccountLogo] = useState<string>('');
    const [canEditAccountLogo, setCanEditAccountLogo] = useState<boolean>(true);

    const [customizeSignIn, setCustomizeSignIn] = useState<boolean>(false);
    const [customizeWelcome, setCustomizeWelcome] = useState<boolean>(false);
    const [isDisabledLogoSignIn, setIsDisabledLogoSignIn] = useState<boolean>(true);
    const [isDisabledLogoWelcome, setIsDisabledLogoWelcome] = useState<boolean>(true);

    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
    const [msgCounterStyles, setMsgCounterStyles] = useState<string>('');

    const [cnameState, setCnameState] = useState<CnameState>(CnameState.Unset);
    const [cNameRequired, setCNameRequired] = useState<boolean>(false);
    const [guestPreviewCards, setGuestPreviewCards] = useState<GuestWelcomeCard>({});

    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isCnameChecking, setIsCnameChecking] = useState<boolean>(false);
    const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(false);

    const [hasFetchedBranding, setHasFetchedBranding] = useState<boolean>(false);

    const DEFAULT_MESSAGE = t(BrandingTKeys.DefaultAccountMessage);

    const formInitialState: BrandingFormProps = {
        accountLogo: '',
        accountMessage: '',
        accountName: '',
        logoOnSignInPage: false,
        logoWithMessage: false,
        newCname: '',
        originalCname: '',
    };

    const formValidation = (model: BrandingFormProps) => {
        const errors: Partial<BrandingFormProps> = {};
        if (!isLoading) {

            errors.accountName = undefined;
            errors.newCname = undefined;

            //ACCOUNT NAME
            const forbiddenCharacters = model.accountName?.match(FORBIDDEN_ACCOUNT_NAME_CHARS);
            if (!model.accountName) {
                errors.accountName = t(BrandingTKeys.AccountNameRequired);
            } else if (model.accountName && model.accountName.length > ACCOUNT_NAME_LIMIT) {
                errors.accountName = t(BrandingTKeys.AccountNameCharacterLimit, { limit: ACCOUNT_NAME_LIMIT });
            } else if (model.accountName && forbiddenCharacters) {
                const uniqueArray = forbiddenCharacters.filter(function (item, pos) {
                    return forbiddenCharacters.indexOf(item) == pos;
                });
                errors.accountName = `${t(BrandingTKeys.AccountNameInvalidCharacters)} ${uniqueArray.join('')}`;
            }

            //CNAME
            if (cNameRequired) {
                const cname = model.newCname ?? '';
                if (!cname && cNameRequired && cnameState === CnameState.Typing) {
                    errors.newCname = t(BrandingTKeys.DomainRequired);
                } else if (cname.length > CNAME_CHARACTER_LIMIT) {
                    errors.newCname = t(BrandingTKeys.DomainCharacterLimit, { limit: CNAME_CHARACTER_LIMIT });
                } else if (!VALID_CNAME_CHARS.test(cname)) {
                    errors.newCname = t(BrandingTKeys.DomainValidCharacters);
                } else if (cname.charAt(0) == '-' || cname.charAt(cname.length - 1) == '-') {
                    errors.newCname = t(BrandingTKeys.DomainHyphenFirstLast);
                } else if (cname.length > 3 && cname.charAt(2) == '-' && cname.charAt(3) == '-') {
                    errors.newCname = t(BrandingTKeys.DomainHyphenConsecutive);
                }
            }
        }

        return errors;
    };

    const brandingForm = useForm<BrandingFormProps>(formInitialState, formValidation);

    const handleRemoveLogo = () => {
        setAccountLogo('');
        setClearAccountLogo(true);
        setCanEditAccountLogo(true);
        setIsFormDirty(true);
        brandingForm.setModelProperty('accountLogo', '');
    };

    const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (brandingForm.model.accountName !== accountName) {
            handleModalConfirm();
        } else {
            handleSaveBranding();
        }
    };

    const handleSaveBranding = async () => {
        setIsLoading(true);
        setIsSaving(true);
        setIsConfirmModalOpen(false);

        if (!!toastMessages.length) {
            toastMessages.forEach((toast) => closeToastMessage(toast.identifier));
        }

        if (!!customizeWelcome && !brandingForm.model.accountMessage?.length) {
            setCustomizeWelcome(false);
        }

        if (!authUser?.accountID || !userAccounts) {
            setIsLoading(false);
            setIsSaving(false);
            return;
        }

        const accountIdentifier = userAccounts.get(authUser.accountID)?.identifier;

        if (!accountIdentifier) {
            setIsLoading(false);
            setIsSaving(false);
            return;
        }

        const branding: AccountBrandingDTO = {
            accountIdentifier,
            accountLogoImgString: brandingForm.model.accountLogo,
            accountMessage: brandingForm.model.accountMessage,
            accountName: brandingForm.model.accountName,
            cName: brandingForm.model.newCname,
            logoOnSignInPage: brandingForm.model.logoOnSignInPage,
            logoWithMessage: brandingForm.model.logoWithMessage,
        };

        try {
            const updatedBranding = await saveBranding(branding);
            setBranding(updatedBranding);

            if (accountName !== brandingForm.model.accountName && brandingForm.model.accountName !== '') {
                await updateAccountNameInUtopia(authUser?.accountID as number, brandingForm.model.accountName as string);
                await updateAccountWorkspaceName(authUser?.accountID as number, brandingForm.model.accountName as string);
                setAccountName(updatedBranding.accountName);
            }

            updateCurrentAccountBranding(branding);
            setIsFormDirty(false);
            showToastMessage({
                identifier: 'branding-save-toast',
                color: ThemeEnum.Success,
                message: t(BrandingTKeys.SuccessfullySaved),
                timeout: 2500,
            });
        } catch (error) {
            console.log(error);
            showToastMessage({
                identifier: 'branding-save-error-toast',
                color: ThemeEnum.Danger,
                message: t(BrandingTKeys.SaveBrandingFailure),
            });
        } finally {
            setIsLoading(false);
            setIsSaving(false);
        }
    };

    const checkIfCnameAvailable = async () => {
        if (!brandingForm.model.newCname || !customizeSignIn || isLoading) {
            if (brandingForm.model.newCname == branding?.cName) {
                setCnameState(CnameState.Valid);
            }
            return true;
        }

        if (!isCnameChecking && !!brandingForm.model.newCname) {
            setIsCnameChecking(true);
            try {
                const cnameAvailableResponse = await getCnameAvailable(brandingForm.model.newCname);
                if (!!cnameAvailableResponse.hasErrorMessage && cnameState !== CnameState.Unset) {
                    setCnameState(CnameState.Invalid);
                    brandingForm.addError('newCname', cnameAvailableResponse.errorMessage);
                    return false;
                } else {
                    setCnameState(CnameState.Valid);
                    return true;
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsCnameChecking(false);
            }
        }
    };

    const handleCancel = () => {
        setIsFormDirty(false);
        brandingForm.clearErrors();

        brandingForm.setModelProperty('accountLogo', branding?.accountLogoImgString);
        brandingForm.setModelProperty('accountMessage', branding?.accountMessage);
        brandingForm.setModelProperty('accountName', accountName);
        brandingForm.setModelProperty('logoOnSignInPage', branding?.logoOnSignInPage);
        brandingForm.setModelProperty('logoWithMessage', branding?.logoWithMessage);
        brandingForm.setModelProperty('newCname', branding?.cName);
        brandingForm.setModelProperty('originalCname', branding?.cName);

        if (!!accountLogo && accountLogo !== branding?.accountLogoImgString) {
            setAccountLogo(branding?.accountLogoImgString || '');
        }

        if (!!branding?.cName) {
            setCustomizeSignIn(true);
            setCnameState(CnameState.Valid);
        } else {
            setCustomizeSignIn(false);
            setCnameState(CnameState.Unset);
        }

        if (!!branding?.accountMessage && branding?.accountMessage !== `${DEFAULT_MESSAGE} ${accountName}.`) {
            setCustomizeWelcome(true);
        } else {
            setCustomizeWelcome(false);
        }
    };

    const handleModalConfirm = () => setIsConfirmModalOpen(true);

    const onFormChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!isLoading) {
            brandingForm.onPropChanged(event);
            setIsFormDirty(true);
        }
    };

    const onCustomizeSignInCheckboxChanged = () => {
        setCustomizeSignIn((prevState) => !prevState);
        onNestedCheckboxChanged();
    };

    const onNestedCheckboxChanged = () => {
        if (!isLoading) {
            setCustomizeWelcome((prevState) => !prevState);
            setIsFormDirty(true);
        }
    };

    const onCnameChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!isLoading) {
            setCnameState(CnameState.Typing);
            brandingForm.onPropChanged(event);
            setIsFormDirty(true);
        }
    };

    const onCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!isLoading) {
            brandingForm.onCheckChanged(event);
            setIsFormDirty(true);
        }
    };

    const populateGuestPreviewCards = () => {
        const cards: GuestWelcomeCard = {
            message: brandingForm.model.accountMessage,
            logoString: brandingForm.model.accountLogo || '',
            includeLogo: brandingForm.model.logoWithMessage,
            accountName: brandingForm.model.accountName,
        };
        setGuestPreviewCards(cards);
    };

    // Set accountLogo on branding form
    useEffect(() => {
        if (!!accountLogo && canEditAccountLogo) {
            setLogoModalIsOpen(false);
            brandingForm.setModelProperty('accountLogo', accountLogo);
            setIsFormDirty(true);
        } else {
            setLogoModalIsOpen(false);
            brandingForm.setModelProperty('accountLogo', accountLogo);
        }

        if (!accountLogo) {
            brandingForm.setModelProperty('logoOnSignInPage', false);
            brandingForm.setModelProperty('logoWithMessage', false);
        }
    }, [accountLogo]);

    // Load page data
    useEffect(() => {
        const loadExistingBranding = async () => {
            setIsLoading(true);

            if (!authUser?.accountID || !userAccounts) {
                setIsLoading(false);
                return;
            }

            const acctName = userAccounts.get(authUser.accountID)?.accountName;
            setAccountName(acctName);
            brandingForm.setModelProperty('accountName', acctName);

            const accountIdentifier = userAccounts.get(authUser.accountID!)?.identifier;
            if (!!accountIdentifier) {
                try {
                    const branding = await getBrandingByAccountIdentifier(accountIdentifier);

                    if (!!branding) {
                        setBranding(branding);

                        if (!!branding.accountLogoImgString) {
                            brandingForm.setModelProperty('accountLogo', branding.accountLogoImgString);
                            setAccountLogo(branding.accountLogoImgString);
                            setCanEditAccountLogo(false);
                        }

                        if (!!branding.accountMessage) {
                            brandingForm.setModelProperty('accountMessage', branding.accountMessage);
                            setCustomizeWelcome(true);
                        }

                        if (!!branding.logoWithMessage) {
                            brandingForm.setModelProperty('logoWithMessage', branding.logoWithMessage);
                        }

                        if (!!branding.cName) {
                            brandingForm.setModelProperty('originalCname', branding.cName);
                            brandingForm.setModelProperty('newCname', branding.cName);
                            setCustomizeSignIn(true);
                            setCnameState(CnameState.Valid);

                            if (!!branding.logoOnSignInPage) {
                                brandingForm.setModelProperty('logoOnSignInPage', branding.logoOnSignInPage);
                            }
                        }
                    } else {
                        setBranding({
                            accountIdentifier: '',
                            accountName: '',
                            accountLogoImgString: '',
                            accountMessage: '',
                            cName: '',
                            logoOnSignInPage: false,
                            logoWithMessage: false,
                        });
                        setCnameState(CnameState.Unset);
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage({
                        identifier: 'branding-fetch-error-toast',
                        color: ThemeEnum.Danger,
                        message: t(BrandingTKeys.FetchBrandingFailure),
                    });
                } finally {
                    setHasFetchedBranding(true);
                }
            }

            setIsLoading(false);
        };

        populateGuestPreviewCards();

        if (!branding && !!userAccounts && !!authUser && !hasFetchedBranding && !isLoading) {
            loadExistingBranding();
        }
    }, [userAccounts, authUser, branding, hasFetchedBranding]);

    // Sets cName to required if customizing checkbox is checked
    useEffect(() => {
        if (!customizeSignIn) {
            brandingForm.setModelProperty('newCname', '');
            brandingForm.clearError('newCname');
            brandingForm.setModelProperty('logoOnSignInPage', false);
            setCNameRequired(false);
        } else if (!!customizeSignIn) {
            setCNameRequired(true);
        }
        setCnameState(CnameState.Unset);
    }, [customizeSignIn]);

    // Check if cName is available on delay
    useEffect(() => {
        let typingCnameTimeout: NodeJS.Timeout | undefined;
        if (!!brandingForm.model.newCname && cnameState === CnameState.Typing && !brandingForm.errors.newCname) {
            typingCnameTimeout = setTimeout(() => {
                checkIfCnameAvailable();
            }, CNAME_VALIDATION_DELAY);
        }
        return () => clearTimeout(typingCnameTimeout);
    }, [brandingForm.model.newCname, CnameState, cnameState]);

    // Checks if logo is present before enabling 'include' checkbox for Sign In Page
    useEffect(() => {
        if (!!customizeSignIn && !!accountLogo) {
            setIsDisabledLogoSignIn(false);
        } else {
            setIsDisabledLogoSignIn(true);
        }
    }, [accountLogo, customizeSignIn]);

    // Checks if logo is present before enabling 'include' checkbox for Guest Welcome Message
    useEffect(() => {
        if (!!accountLogo) {
            setIsDisabledLogoWelcome(false);
        } else {
            setIsDisabledLogoWelcome(true);
        }
    }, [accountLogo]);

    // Update Guest Welcome Preview
    useEffect(() => {
        populateGuestPreviewCards();
    }, [brandingForm.model, accountName]);

    // Clear Guest Welcome Message if unchecked
    useEffect(() => {
        if (!customizeWelcome) {
            brandingForm.setModelProperty('accountMessage', null);
        }
    }, [customizeWelcome]);

    // Validate Guest Welcome Message
    useEffect(() => {
        if (!!brandingForm.model.accountMessage && brandingForm.model.accountMessage.trim().length > WELCOME_CHARACTER_LIMIT - 5 && !!customizeWelcome) {
            setMsgCounterStyles('invalid');
        } else if (!!brandingForm.model.accountMessage && brandingForm.model.accountMessage.trim().length <= WELCOME_CHARACTER_LIMIT && !!customizeWelcome) {
            brandingForm.clearError('accountMessage');
            setMsgCounterStyles('');
        }
    }, [brandingForm.model.accountMessage, customizeWelcome]);

    // Save button check
    useEffect(() => {
        const brandingFormErrorsFalsy = Object.values(brandingForm.errors).every(v => !v);
        if (!!isFormDirty && brandingFormErrorsFalsy && (cnameState == CnameState.Unset || cnameState == CnameState.Valid)) {
            setSaveButtonEnabled(true);
        } else {
            setSaveButtonEnabled(false);
        }
    }, [brandingForm.errors, isFormDirty, cnameState]);

    return (
        <>
            <Form onSubmit={handleFormSubmit}>
                <Container fluid className='ps-3 ps-sm-2'>
                    <h4 className='mb-4'>{t(BrandingTKeys.AccountSetUp)}</h4>
                    <FormGroup className='mb-4 pb-2'>
                        <Label for='accountName'>{t(BrandingTKeys.AccountName)}</Label>
                        <Text muted className='d-block mb-2 help-text'>
                            {brandingForm.model.accountName === 'My Account' ? t(BrandingTKeys.AccountNameAlternateHelpText) : t(BrandingTKeys.AccountNameHelpText)}
                        </Text>
                        <Row>
                            <Col xs={12} sm={10} md={6} lg={4}>
                                <Input required id='accountName' name='accountName' dataId='accountName' onChange={onFormChange} value={brandingForm.model.accountName} invalid={!!brandingForm.errors?.accountName} maxLength={ACCOUNT_NAME_LIMIT} />
                                <FormFeedback valid={!brandingForm.errors?.accountName}>{brandingForm.errors?.accountName}</FormFeedback>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup className='mb-4 pb-2'>
                        <Label for='accountLogo'>
                            {t(BrandingTKeys.CompanyLogo)}
                            <Text muted className='ms-1'>
                                ({t(BrandingTKeys.Optional)})
                            </Text>
                        </Label>
                        <Text muted className='d-block mb-2 mb-3 help-text'>
                            {t(BrandingTKeys.CompanyLogoHelpText)}
                        </Text>
                        {!accountLogo && canEditAccountLogo && (
                            <Button className='d-flex align-items-center' color='primary' type='button' emphasis='med' dataId='uploadCompanyLogo' onClick={() => setLogoModalIsOpen(true)}>
                                <FontAwesomeIcon icon={['far', 'upload']} className='me-2' size='lg' />
                                {t(BrandingTKeys.UploadCompanyLogo)}
                            </Button>
                        )}
                    </FormGroup>

                    {!!accountLogo && (
                        <div className='logo-preview-container my-4'>
                            <span data-id='removeLogo' className='remove-icon-container' onClick={handleRemoveLogo}>
                                <span className='fa-stack stack-container'>
                                    <FontAwesomeIcon icon={['far', 'circle-xmark']} className='fa-stack-1x remove-icon' size='lg' />
                                    <FontAwesomeIcon icon='circle' color='white' size='lg' />
                                </span>
                            </span>
                            <img src={accountLogo} alt='logo' width={CompanyLogoConfig.width} height={CompanyLogoConfig.height} className='logo-preview' />
                            {/* <EfcLink dataId='adjustSize' className='d-block mt-2 mx-auto adjust-size' color='primary' onClick={() => setLogoModalIsOpen(true)}>
                                {t(BrandingTKeys.AdjustSize)}
                            </EfcLink> */}
                        </div>
                    )}

                    <div className='border-top my-5'></div>

                    <h4 className='mb-4'>{t(BrandingTKeys.SignInPage)}</h4>
                    <Row>
                        <Col md={12} lg={6}>
                            <NestedChecklist optional id='customizeSignIn' name='customizeSignIn' dataId='customizeSignIn' checked={customizeSignIn} label={t(BrandingTKeys.CustomizeSignInPage)} onClick={onCustomizeSignInCheckboxChanged} tooltip={t(BrandingTKeys.CustomizeSignInTooltip)}>
                                <Label for='newCname' className='mt-2'>
                                    {t(BrandingTKeys.DomainName)}
                                </Label>
                                <Row>
                                    <Col xs={12} sm={10} md={8} lg={11} xl={10}>
                                        <div className='d-flex align-items-center cname-container'>
                                            <Text muted className='me-1'>
                                                https://
                                            </Text>
                                            <Input id='newCname' name='newCname' dataId='newCname' value={brandingForm.model.newCname} disabled={!customizeSignIn || isCnameChecking} onChange={onCnameChange} onBlur={checkIfCnameAvailable} invalid={!!brandingForm.errors?.newCname} valid={!brandingForm.errors?.newCname && cnameState == CnameState.Valid && brandingForm.model.newCname !== branding?.cName} />
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <Text muted className='ms-1'>
                                                    .revverdocs.com
                                                </Text>
                                                <div style={{ width: '40px' }} className='d-flex justify-content-end'>
                                                    {isCnameChecking && <Spinner size='sm' className='me-2' color='primary' />}
                                                </div>
                                            </div>
                                            {cNameRequired && (
                                                <FormFeedback valid={!brandingForm.errors?.newCname && cnameState == CnameState.Valid} className='cname-feedback'>
                                                    {brandingForm.errors?.newCname && brandingForm.errors?.newCname}
                                                    {(!brandingForm.errors?.newCname && cnameState == CnameState.Valid && brandingForm.model.newCname !== branding?.cName) && t(BrandingTKeys.ValidDomainMessage)}
                                                </FormFeedback>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <FormGroup check className='mt-4 pt-2'>
                                    <Input type='checkbox' id='logoOnSignInPage' name='logoOnSignInPage' dataId='logoOnSignInPage' onChange={onCheckChange} disabled={isDisabledLogoSignIn} checked={brandingForm.model.logoOnSignInPage} />
                                    <Label check for='logoOnSignInPage'>
                                        {t(BrandingTKeys.IncludeLogo)}
                                    </Label>
                                </FormGroup>
                            </NestedChecklist>
                        </Col>
                        <Col md={8} lg={6} className='mt-4 mt-lg-0'>
                            <p>{t(BrandingTKeys.SignInPreview)}</p>
                            <div className='border border-primary border-2 rounded p-2'>
                                <SignInPagePreview accountName={brandingForm.model.accountName} disabled={!customizeSignIn} logoString={brandingForm.model.accountLogo} includeLogo={brandingForm.model.logoOnSignInPage} />
                            </div>
                        </Col>
                    </Row>

                    <div className='border-top my-5'></div>

                    <h4 className='mb-4'>{t(BrandingTKeys.GuestWelcomeMessage)}</h4>
                    <Row className='pb-3 mb-5'>
                        <Col md={12} lg={6}>
                            <Text muted className='d-block mb-3' style={{ maxWidth: '571px' }}>
                                {t(BrandingTKeys.CustomizeGuestMessageHelpText)}
                            </Text>
                            <FormGroup check>
                                <Input type='checkbox' id='logoWithMessage' name='logoWithMessage' disabled={isDisabledLogoWelcome} dataId='logoWithMessage' onChange={onCheckChange} checked={brandingForm.model.logoWithMessage} />
                                <Label check for='includeLogoWithMsg'>
                                    {t(BrandingTKeys.IncludeLogoWithMessage)}
                                </Label>
                            </FormGroup>
                            <NestedChecklist optional id='customizeGuestWelcome' name='customizeGuestWelcome' dataId='customizeGuestWelcome' className={'mb-0 mb-lg-4'} checked={customizeWelcome} label={t(BrandingTKeys.CustomizeGuestMessage)} onClick={onNestedCheckboxChanged}>
                                {!!customizeWelcome && (
                                    <Row>
                                        <Col xs={12} md={8} lg={12} xl={10}>
                                            <Input type='textarea' id='accountMessage' name='accountMessage' dataId='accountMessage' style={{ height: '100px' }} onChange={onFormChange} disabled={!customizeWelcome} value={brandingForm.model.accountMessage} invalid={!!brandingForm.errors?.accountMessage} maxLength={WELCOME_CHARACTER_LIMIT} />
                                            <div className={`character-counter ${msgCounterStyles}`}>
                                                {brandingForm.model.accountMessage?.length || 0}/{WELCOME_CHARACTER_LIMIT}
                                            </div>
                                            <FormFeedback valid={!brandingForm.errors?.accountMessage} className={'character-counter-feedback'}>
                                                {brandingForm.errors?.accountMessage}
                                            </FormFeedback>
                                        </Col>
                                    </Row>
                                )}
                            </NestedChecklist>
                        </Col>
                        <Col md={8} lg={6} className='mt-4 mt-lg-0 mb-4 mb-lg-0'>
                            <p>{t(BrandingTKeys.GuestWelcomePreview)}</p>
                            <div className='border border-primary border-2 rounded p-2' id='guest-welcome-preview'>
                                <GuestWelcomePreview item={guestPreviewCards} />
                            </div>
                        </Col>
                    </Row>
                </Container>

                <DockedBar position='bottom' isDocked={isFormDirty} className='branding-docked-bar' barClassName='py-3 mx-2 mt-4'>
                    <Button dataId='cancelBrandingSave' emphasis='med' className='me-3' type='button' onClick={handleCancel}>
                        {t(BrandingTKeys.Cancel)}
                    </Button>
                    <Button dataId='saveBranding' disabled={!saveButtonEnabled} type='submit'>
                        <span className='d-flex align-items-center'>
                            {!!isSaving && <Spinner size='sm' className='me-2' />}
                            {t(BrandingTKeys.SaveChanges)}
                        </span>
                    </Button>
                </DockedBar>
            </Form>

            {logoModalIsOpen && <BrandingImageModal isOpen={logoModalIsOpen} base64Image={accountLogo} clearFile={clearAccountLogo} setIsOpen={setLogoModalIsOpen} setBase64Image={setAccountLogo} setClearFile={setClearAccountLogo} />}

            {isConfirmModalOpen && <BrandingSaveConfirmModal isOpen={isConfirmModalOpen} toggle={() => setIsConfirmModalOpen(false)} onConfirm={handleSaveBranding} />}

            {isLoading && (
                <div className='branding-page-overlay'>
                    <Spinner color='primary' />
                </div>
            )}
        </>
    );
};
