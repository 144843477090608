import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Page, RevverIcon } from '@efilecabinet/efc-atlantis-components';
import { ErrorTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { useAuthContext } from '../../../auth';
import { useColors } from '../../../hooks/useColors';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { RoutePath } from '../../../hooks/Routing/routingTypes';
import { ExperienceTypeEnum } from '../../../auth/AuthenticationTypes';
import pageNotFoundImg from '../../../assets/images/pageNotFound.svg';
import './pageNotFound.css';

export interface PageNotFoundProps {
    errorType?: 'PageNotFound' | 'BadLink';
}

export function PageNotFound(props: PageNotFoundProps) {

    const { errorType = 'PageNotFound' } = props;

    const [authUserAccountName, setAuthUserAccountName] = useState<string>();

    const { experienceType, authUser, handleLogout, userAccounts, hasCheckedAuthUser } = useAuthContext();
    const { t } = useSafeTranslation(TranslationFiles.Errors);
    const colors = useColors();
    const { routeToHome } = useRouting();

    const handleErrorButtonRouting = () => {
        if(!!authUser){
            return () => routeToHome();
        }else if(!authUser && errorType == 'PageNotFound') {
            return () => handleLogout();
        }
    };

    const getErrorTitle = () => {
        if(errorType == 'BadLink'){
            return t(ErrorTKeys.BadLinkTitle);
        }else{
            return t(ErrorTKeys.PageNotFoundTitle);
        }
    };

    const getErrorDescription = () => {
        if(errorType == 'BadLink'){
            return t(ErrorTKeys.BadLinkDescription);
        }else{
            return t(ErrorTKeys.PageNotFoundDescription);
        }
    };

    const getErrorButtonText = () => {
        if(!!authUser){
            return t(ErrorTKeys.HomeCtaText);
        }else{
            return t(ErrorTKeys.SignInCtaText);
        }
    };
    
    useEffect(() => {
        if(!!authUser?.accountID && !!userAccounts) {
            const account = userAccounts.get(authUser?.accountID);
            setAuthUserAccountName(account?.accountName);
        }
    }, [hasCheckedAuthUser]);

    return (
        <Page>
            <div className='page-not-found-content-area' style={{ backgroundImage: `url(${pageNotFoundImg})` }}>
                {!authUser && 
                    <RevverIcon 
                        className='page-not-found-revver-icon' 
                        to={RoutePath.Login} 
                        tag={Link} 
                        color={colors.atlantisEnabled} />
                }
                
                <h1 className='page-not-found-title'>{getErrorTitle()}</h1>
                <p className='page-not-found-description'>
                    {getErrorDescription()}
                    {!!authUserAccountName 
                        && errorType == 'BadLink' && 
                        <b>{`, "${authUserAccountName}"`}</b>
                    }
                </p>

                {(errorType == 'PageNotFound' || !!authUser && errorType == 'BadLink') 
                    && experienceType != ExperienceTypeEnum.Anonymous &&
                    <Button className='page-not-found-button' emphasis='high' onClick={handleErrorButtonRouting()}>{getErrorButtonText()}</Button>
                }
            </div>
        </Page>
    );
}