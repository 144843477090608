import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { TextRadioButton, EfcTextRadioButtonInfo, Label } from '@efilecabinet/efc-atlantis-components';
import { TextMDFieldModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../../../hooks/useSafeTranslation';
import { TextMDFieldAdvancedFormats } from '../textMDFieldAdvancedFormats/textMDFieldAdvancedFormats';
import { MDFieldDTO, TextFormatTypes, TextMDFieldDTO } from '../../../../../../../api/mdFields/mdFieldApiTypes';
import { TextFormatTypeKeyStrings } from '../../../../../metadataFieldTypes';

export interface TextMDFieldFormatsProps {
    mdField: MDFieldDTO;
    setMDField: Dispatch<SetStateAction<MDFieldDTO>>;
    setIsFormValid: (isFormValid: boolean) => void;
}

export const TextMDFieldFormats = (props: TextMDFieldFormatsProps) => {
    const { mdField, setMDField, setIsFormValid } = props;

    const { t } = useSafeTranslation(TranslationFiles.TextMDFieldModal);

    const getButtonInfos = (): EfcTextRadioButtonInfo[] => {
        return [
            { key: TextFormatTypes[TextFormatTypes.SingleLine], labelName: t(TextMDFieldModalTKeys.SingleLine), helperText: t(TextMDFieldModalTKeys.SingleLineHelpText), checked: mdField?.textProps?.textFormat == TextFormatTypes.SingleLine ?? true },
            { key: TextFormatTypes[TextFormatTypes.MultiLine], labelName: t(TextMDFieldModalTKeys.MultiLine), helperText: t(TextMDFieldModalTKeys.MultiLineHelpText), checked: mdField?.textProps?.textFormat == TextFormatTypes.MultiLine ?? false },
        ];
    };

    const onChecked = (button: EfcTextRadioButtonInfo) => {
        setMDField({ ...mdField, textProps: { ...mdField.textProps as TextMDFieldDTO, textFormat: TextFormatTypes[button.key as TextFormatTypeKeyStrings] } });
    };

    useEffect(() => {
        if (!mdField?.textProps?.textFormat) {
            setMDField({ ...mdField, textProps: { ...mdField.textProps as TextMDFieldDTO, textFormat: TextFormatTypes.SingleLine } });
        }
    }, []);

    return (
        <>
            <Row className='my-5'>
                <Col>
                    <Label className='mb-3'>{t(TextMDFieldModalTKeys.SelectFormat)}</Label>
                    <TextRadioButton buttonInfos={getButtonInfos()} onChecked={onChecked} />
                </Col>
            </Row>

            {mdField.textProps?.textFormat == TextFormatTypes.SingleLine &&
                <TextMDFieldAdvancedFormats mdField={mdField} setMDField={setMDField} setIsFormValid={setIsFormValid} />
            }
        </>
    );
};