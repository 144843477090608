import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import { Page, Tab } from '@efilecabinet/efc-atlantis-components';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { useRouteValidator } from '../../../hooks/useRouteValidator';
import { RoutePath, WorkflowRouteParams, WorkflowRoutePath } from '../../../hooks/Routing/routingTypes';
import { IFrame } from '../../../components/IFrame/IFrame';
import { TranslationFiles, useSafeTranslation, WorkflowTKeys } from '../../../hooks/useSafeTranslation';
import { AccountFeatureUsageHeader } from '../../../components/AccountFeatureUsageHeader/AccountFeatureUsageHeader';
import { AccountFeatureEnum } from '../../../api/accountFeatures/accountFeatureApiTypes';
import './workflowPage.css';

const IFrameRoutes: Map<WorkflowRoutePath, string> = new Map([
    [RoutePath.WorkflowIndex, '/workflows/instances'],
    [RoutePath.Workflows, '/workflows/main'],
    [RoutePath.WorkflowEdit, `/workflows/workflow/${WorkflowRouteParams.WorkflowId}`],
    [RoutePath.WorkflowInstances, '/workflows/instances'],
    [RoutePath.WorkflowInstanceEdit, `/workflows/Instance/${WorkflowRouteParams.InstanceId}`],
    [RoutePath.WorkflowDashboard, '/workflows/dashboard'],
    [RoutePath.WorkflowHistory, '/workflows/history'],
]);

const WorkflowsTabRoutes: Set<WorkflowRoutePath> = new Set([
    RoutePath.Workflows,
    RoutePath.WorkflowEdit,
]);

const InstancesTabRoutes: Set<WorkflowRoutePath> = new Set([
    RoutePath.WorkflowIndex,
    RoutePath.WorkflowInstances,
    RoutePath.WorkflowInstanceEdit
]);

export const WorkflowPage = () => {
    const { workflowId, instanceId } = useParams();
    const { routeToWorkflows, routeToWorkflowInstances, routeToWorkflowDashboard, routeToWorkflowHistory} = useRouting();
    const location = useLocation();

    const { t } = useSafeTranslation(TranslationFiles.Workflow);
    const { canGoToRoute } = useRouteValidator();

    const currentRoute =
        !!workflowId ? RoutePath.WorkflowEdit :
            !!instanceId ? RoutePath.WorkflowInstanceEdit :
                (`${location.pathname}` as WorkflowRoutePath);
    const iframe = useUtopiaIFrame(getIFrameUrl(currentRoute));

    function getIFrameUrl(route: WorkflowRoutePath) {
        return IFrameRoutes.get(route)
            ?.replace(WorkflowRouteParams.WorkflowId, workflowId ?? '')
            .replace(WorkflowRouteParams.InstanceId, instanceId ?? '') ?? '';
    }

    useEffect(() => {
        if (currentRoute == RoutePath.WorkflowIndex) {
            if (canGoToRoute(RoutePath.Workflows)) {
                routeToWorkflows();
            } else {
                routeToWorkflowInstances;
            }
        }
    }, []);

    useEffect(() => {
        iframe.setUrl(getIFrameUrl(currentRoute));
    }, [location.pathname]);

    return (
        <Page title={t(WorkflowTKeys.WorkflowTitle)} icon={{ icon: 'wand-magic-sparkles' }}>
            <AccountFeatureUsageHeader accountFeatureTypes={[AccountFeatureEnum.Workflow]} />

            {canGoToRoute(RoutePath.Workflows) && (
                <Tab active={WorkflowsTabRoutes.has(currentRoute)} onClick={() => routeToWorkflows()}>
                    {t(WorkflowTKeys.WorkflowsTab)}
                </Tab>
            )}
            {canGoToRoute(RoutePath.WorkflowInstances) && (
                <Tab active={InstancesTabRoutes.has(currentRoute)} onClick={() => routeToWorkflowInstances()}>
                    {t(WorkflowTKeys.InstancesTab)}
                </Tab>
            )}
            {canGoToRoute(RoutePath.WorkflowDashboard) && (
                <Tab active={currentRoute == RoutePath.WorkflowDashboard} onClick={() => routeToWorkflowDashboard()}>
                    {t(WorkflowTKeys.DashboardTab)}
                </Tab>
            )}
            {canGoToRoute(RoutePath.WorkflowHistory) && (
                <Tab active={currentRoute == RoutePath.WorkflowHistory} onClick={() => routeToWorkflowHistory()}>
                    {t(WorkflowTKeys.HistoryTab)}
                </Tab>
            )}

            {/* Use the iframe until we make this page native */}

            <IFrame className='iframe' src={iframe.url} />

            {/* Use the following Tab Panes once we make this page native */}

            {/*<Tab.Content activeTab={activeTab}>*/}
            {/*    <Tab.Pane tabId={0}>*/}
            {/*        Placeholder for Workflows*/}
            {/*    </Tab.Pane>*/}
            {/*    <Tab.Pane tabId={1}>*/}
            {/*        Placeholder for Instances*/}
            {/*    </Tab.Pane>*/}
            {/*    <Tab.Pane tabId={2}>*/}
            {/*        Placeholder for Dashboard*/}
            {/*    </Tab.Pane>*/}
            {/*    <Tab.Pane tabId={3}>*/}
            {/*        Placeholder for History*/}
            {/*    </Tab.Pane>*/}
            {/*</Tab.Content>*/}
        </Page>
    );
};
