import React, { Dispatch, FC, SetStateAction } from 'react';
import { Row } from 'reactstrap';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SidesheetPage } from '../../../../../components/SideSheet/SidesheetPage';
import { MDSideSheetInformationTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useLayerContext } from '../../../../../context/layer/LayerContext';
import { MDGroupModal } from '../../../_groups/_modal/mdGroupModal/mdGroupModal';
import { MDGroupDTO } from '../../../../../api/mdGroups/mdGroupsApiTypes';

interface MDmdGroupSideSheetContentProps {
    mdGroup: MDGroupDTO;
    onClose?: () => void;
    refreshMDGroupsSentinalKeyState: [number, Dispatch<SetStateAction<number>>];
}

export const MDGroupSideSheetContent: FC<MDmdGroupSideSheetContentProps> = ({ mdGroup, refreshMDGroupsSentinalKeyState, onClose }) => {

    const [refreshMDGroupsSentinalKey, setRefreshMDGroupsSentinalKey] = refreshMDGroupsSentinalKeyState;

    const { t } = useSafeTranslation(TranslationFiles.MDSideSheetInformation);
    const { openModal } = useLayerContext();

    function openMDGroupModal(mdGroupToEdit?: MDGroupDTO) {
        openModal(() =>
            <MDGroupModal mdGroupToEdit={mdGroupToEdit} onSubmitGroup={() => setRefreshMDGroupsSentinalKey(prevState => prevState + 1)} />
        );
    }

    return (
        <SidesheetPage title={t(MDSideSheetInformationTKeys.Title)} onClose={onClose}>
            <div className='pb-3'></div>
            <div>
                <Row className='mb-3'>
                    <div className='d-flex align-items-center'>
                        <Text className='me-3' size='lg' semibold> {mdGroup.name}</Text>
                        <FontAwesomeIcon icon='edit' className='ml-2 pointer' onClick={() => openMDGroupModal(mdGroup)} />
                    </div>
                </Row>
                {mdGroup.metadataFields.map((field) => (
                    <Row key={field.id} className='mb-2'>
                        <Text >{field.name}</Text>
                    </Row>
                ))}
            </div>
        </SidesheetPage>
    );
};