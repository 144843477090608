import { AccountFeatureDTO } from "../api/accountFeatures/accountFeatureApiTypes";
import { AccountBrandingDTO } from "../api/branding/brandingApiTypes";
import { UserFeedbackDTO } from "../api/feedback/feedbackApiTypes";
import { RecycleBinNodeDTO } from "../api/recycleBin/recycleBinApiTypes";
import { RoleDTO, SystemPermissionDTO, SystemPermissionsDTO, UserLicenseEnum } from "../api/roles/roleApiTypes";
import { OptInInfoDTO } from "../api/system/systemApiTypes";

export interface IAuthUser {
    accountID: number;
    userID: number;
    accessToken: string;
    refreshToken?: string;
    expiresIn?: number;
    mfaToken?: string;
    userType: number;
    displayName: string;
    multipleAccounts: boolean;
    samlConfigId?: number;
    eSigProduct?: number;
    systemPermissionsToAccounts: SystemPermissionsDTO[];
    userRoles: RoleDTO[];
    sessionExpirationMismatch: boolean;
}

export interface IAuthContext {
    accountFeatures: AccountFeatureDTO[];
    authUser: IAuthUser | null;
    hasAuthUser: boolean;
    user: IUser | null;
    hasUser: boolean;
    handleLoginWithToken: (token: string) => Promise<void>;
    handleLogout: () => void;
    handleSwitchAccounts: (roleId: number, onAccountSwitch?: () => void) => void;
    getIsAuthorized: () => Promise<boolean>;
    hasCheckedAuthUser: boolean;
    userAccounts: Map<number, IAuthAccount> | null;
    hasUserAccounts: boolean;
    openAccountSwitchRequiredModal: (accountId: number, onAccountSwitch: () => void) => void;
    optInInfo: OptInInfoDTO | null;
    groupManagerAccounts: number[];
    loginCount: number;
    previewerXSessionCount: number;
    apiOperationCache: Set<any>;
    setApiOperationCache: React.Dispatch<React.SetStateAction<Set<any>>>;
    experienceType: ExperienceTypeEnum;
    userCanViewDocumentRequests: boolean;
    userCanViewDocuments: boolean;
    showAdminAccountOnboarding: boolean;
    isNewUser: boolean;
    updateCurrentAccountBranding: (newBranding: AccountBrandingDTO) => void;
    loginIframeComplete: boolean;
    userFeedbackInfo: UserFeedbackDTO | null;
    handleNewSideSheetToggle: (optIn: boolean) => Promise<void>;
    atlantisSideSheetEnabled: boolean;
    handleMetadataToggle: (optIn: boolean) => void;
    getMetadataToggleStatus: () => boolean;
}

export interface IUser {
    id: number;
    securitySettings: IUserSecuritySettings;
    preferences: IUserPreferences;
    emailSettings: IEmailSetting[];
    CustomPreviewerStamps: RecycleBinNodeDTO;
    userName: string;
    sessionExpire: number;
}

export enum ExperienceTypeEnum {
    Standard,
    Guest,
    Anonymous,
}

export enum MfaTypeEnum {
    App = 0,
    Email = 1,
    Either = 2,
}

export interface IUserSecuritySettings {
    oldPassword: string;
    newPassword: string;
    mfaIsRequired: boolean;
    mfaType: MfaTypeEnum;
    mfaRequiredDays?: number;
    mobilePhoneNumber: string;
    mobilePhoneCountryCode: string;
}

export interface IUserPreferences {
    activeEmailSettingID?: number;
    checkOutOnDoubleClick?: boolean;
    alwaysConfirmAppend: boolean;
    deleteAppendedNode: boolean;
    defaultCollectionID?: number;
    timeZoneId: string;
    useStoreArea: boolean;
    searchDefaultByName: boolean;
    searchDefaultByProfileValue: boolean;
    searchDefaultByCreatedByName: boolean;
    searchDefaultByComment: boolean;
    searchDefaultByFullText: boolean;
    searchDefaultByPath: boolean;
    searchDefaultUseContainsWordsIn: boolean;
    skipSSOFullUserPrompt: boolean;
}

export enum EmailSettingTypeEnum {
    SMTP = 0,
    IMAP = 1,
}

export enum EmailAuthTypeEnum {
    UsernameAndPassword = 0,
    OAuth = 1,
}

export enum ExternalAuthenticationProviderEnum {
    GoogleDrive = 1,
    OneDrive = 2,
    Docusign = 3,
    Gmail = 5,
    Outlook = 6,
}

export interface IEmailSetting {
    id: number;
    accountID?: number;
    emailAddress: string;
    serverAddress: string;
    serverPort: number;
    userName: string;
    password: string;
    enableSSL: boolean;
    settingType: EmailSettingTypeEnum;
    isAccountSetting: boolean;
    failureCount: number;
    lastFailureMessage: string;
    inFailure: boolean;
    authType: EmailAuthTypeEnum;
    externalAuthorizationID?: number;
    externalAuthProviderType: ExternalAuthenticationProviderEnum;
    expiration: number;
    authToken: string;
}

export interface IAuthAccount {
    userID: number;
    roleID: number;
    accountID: number;
    accountName: string;
    userType: string;
    licenseType: UserLicenseEnum;
    accessToken: string;
    systemPermissions: SystemPermissionDTO[];
    identifier: string;
    branding?: AccountBrandingDTO;
}

export interface ISelectAuthAccounts {
    authAccounts: IAuthAccount[];
}
