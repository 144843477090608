import React, { ReactNode, useEffect, useState } from 'react';
import { ResizableCol, Tab, Tabs } from '@efilecabinet/efc-atlantis-components';
import { useRouting } from '../../hooks/Routing/useRouting';
import { useNodeDetailsContext } from '../../context/nodeDetails/NodeDetailsContext';
import { useFileUtilities } from '../../hooks/useFileUtilities';
import { useUtopiaIFrame } from '../../hooks/useUtopiaIFrame';
import { RoutePath, SelectedDetail } from '../../hooks/Routing/routingTypes';
import { SideSheetTabButton } from '../SideSheet/SideSheetButton';
import { ProfileSideSheetPage } from './Tabs/Profile/ProfileSideSheetPage';
import { NodeDetails } from './Tabs/NodeDetails/NodeDetails';
import { Notes } from './Tabs/Notes/Notes';
import { Permissions } from './Tabs/Permissions/Permissions';
import { Preview } from './Tabs/Preview/Preview';
import { SideSheet } from '../SideSheet/SideSheet';
import { IFrame } from '../IFrame/IFrame';
import { useResizableColUtilities } from '../../hooks/useResizableColUtilities';
import { SideSheetResizerSlider } from '../SideSheet/SideSheetResizerSlider';
import { matchRoutes, RouteObject, useLocation } from 'react-router';
import { usePreviewerContext } from '../../context/previewer/PreviewerContext';
import { useLayerContext } from '../../context/layer/LayerContext';
import { NodeType } from '../../api/node/nodeApiTypes';
import { useBootstrapBreakpoints } from '../../hooks/bootstrap/useBootstrapBreakpoints';
import '../SideSheet/SideSheet.css';

const iframedDetails: Set<string> = new Set([SelectedDetail.Governance, SelectedDetail.Automation, SelectedDetail.History]);

interface NodeSideSheetProps {
    enablePreviewer: boolean;
    showFullScreenButton: boolean;
}

export const NodeSideSheet = ({showFullScreenButton, enablePreviewer} : NodeSideSheetProps) => {
    const { routeToNodeDetail } = useRouting();
    const { nodeTypeIsNotFile } = useFileUtilities();
    const { fetchNode, activeNode } = useNodeDetailsContext();
    const { MIN_EXPANDED_WIDTH, onSidesheetCollapse, onSidesheetExpand, handleSideSheetResize, getSideSheetEnabled, getSideSheetWidth, getCanResize, setShowOverlay } = useResizableColUtilities();
    const { setSearchTerm } = usePreviewerContext();
    const iframe = useUtopiaIFrame();
    const { openModal } = useLayerContext();
    const { screenSize } = useBootstrapBreakpoints();

    const [sideSheetIsOpen, setSideSheetIsOpen] = useState<boolean>(false);
    const [initializePreviewer, setInitializePreviewer] = useState<boolean>(false);
    const [activeNodeIsTemplate, setActiveNodeIsTemplate] = useState<boolean>(false);
    const [currentNodeId, setCurrentNodeId] = useState<string>('');
    const [showSideSheet, setShowSideSheet] = useState(false);
    const [selectedDetail, setSelectedDetail] = useState<string>(''); //SelectedDetail.Details
    const [nodeIds, setNodeIds] = useState<string>('');

    const currentLocation = useLocation();
    const acceptableRoutes = [{ path: RoutePath.NodeDetail } as RouteObject, { path: RoutePath.Node } as RouteObject];

    const getNodeIds = () => nodeIds?.split(',') ?? [];

    const collapseSideSheet = () => {
        setSideSheetIsOpen(false);
        onSidesheetCollapse();
    };

    const expandSideSheet = () => {
        setSideSheetIsOpen(true);
        onSidesheetExpand();
    };

    const handleTabClick = (tab: SelectedDetail) => {
        const selectedTab = tab as string;

        if (selectedTab == selectedDetail && sideSheetIsOpen) {
            collapseSideSheet();
            setSelectedDetail('');
            return;
        }

        expandSideSheet();
        setSelectedDetail(selectedTab);

        if (!!nodeIds && location.pathname.startsWith(RoutePath.Documents)) {
            routeToNodeDetail(nodeIds.split(','), tab);
        }

    };

    const isTabDisabled = getNodeIds().length != 1;

    useEffect(() => {
        if (!!nodeIds && getSideSheetEnabled()) {
            setShowSideSheet(true);
            // TODO: Uncomment this once a check has been added to only show if the user has not seen the onboarding modal yet
            // if (screenSize >= ScreenSize.l) {
            //     openModal((closeModalFn) => <SidesheetOnboardingModal isOpen={true} destroyModal={closeModalFn} />);
            // }
        } else {
            setShowSideSheet(false);
        }
    }, [nodeIds]);

    useEffect(() => {
        if (getNodeIds().length > 1) {
            collapseSideSheet();
        }

        if (getNodeIds().length == 1 && currentNodeId != getNodeIds()[0]) {
            fetchNode(getNodeIds()[0]);
        }
        setCurrentNodeId(getNodeIds()[0]);
    }, [nodeIds]);

    useEffect(() => {
        const result = matchRoutes(acceptableRoutes, currentLocation);

        if (!!result && result.length == 1) {
            if (!!result[0].params['nodeIds']) {
                setNodeIds(result[0].params['nodeIds']);
            }
            else {
                setNodeIds('');
            }

            if (!!result[0].params['selectedDetail']) {
                setSelectedDetail(result[0].params['selectedDetail']);
            }
        }
        else {
            setNodeIds('');
            setSelectedDetail('');
            onSidesheetCollapse();
        }

    }, [location.pathname]);

    useEffect(() => {
        if (iframedDetails.has(selectedDetail) && !!activeNode?.id) {
            if (selectedDetail == SelectedDetail.Governance) {
                iframe.setUrl(`/nodedetailsstandalone/timeTriggers/${activeNode.id}`);
            } else if (selectedDetail == SelectedDetail.Automation) {
                iframe.setUrl(`/nodedetailsstandalone/eventTriggers/${activeNode.id}`);
            } else if (selectedDetail == SelectedDetail.History) {
                iframe.setUrl(`/nodedetailsstandalone/auditLogs/${activeNode.id}`);
            }
        }
    }, [selectedDetail, activeNode]);

    useEffect(() => {
        if (!!activeNode) {
            if (!enablePreviewer) {
                return;
            }

            if (nodeTypeIsNotFile(activeNode)) {
                setInitializePreviewer(false);

                setActiveNodeIsTemplate(activeNode.systemType === NodeType.Template);

                if (selectedDetail === SelectedDetail.Preview) {
                    setSelectedDetail(SelectedDetail.Details);
                }
            } else {
                setInitializePreviewer(true);
            }
        }
    }, [activeNode]);

    useEffect(() => {
        const sideSheetListener = async function (event: any) {
            if (!!event.data.key && event.data.key === 'previewerSearchInfo') {
                setSearchTerm(event.data.value.searchTerm);
                setNodeIds(event.data.value.selectedNodeIds);
            }

            if (!!event.data.key && event.data.key === 'templateNodeInfo') {
                setNodeIds(event.data.value);
            }
        };

        collapseSideSheet();

        window.addEventListener('message', sideSheetListener);

        return () => window.removeEventListener('message', sideSheetListener);
    }, []);

    return (
        <>
            {!!getCanResize() && showSideSheet && (
                <SideSheetResizerSlider onResize={handleSideSheetResize} />
            )}

            <ResizableCol hidden={!showSideSheet} xs='auto' direction='left' className='ps-3 pe-0 pt-3 sidesheet-col' width={getSideSheetWidth()} minWidth={MIN_EXPANDED_WIDTH} canResize={getCanResize()} onStartResize={() => setShowOverlay(true)} onEndResize={() => setShowOverlay(false)}>
                <SideSheet>
                    <Tabs vertical>
                        <Tab className='sidesheet-tab'>
                            <SideSheetTabButton dataId={`sideSheet-tab-${SelectedDetail.Details}`} isDisabled={isTabDisabled} icon={{ icon: ['far', 'circle-info'] }} isActive={selectedDetail == SelectedDetail.Details} onClick={() => handleTabClick(SelectedDetail.Details)} />
                        </Tab>
                        {initializePreviewer && enablePreviewer && (
                            <Tab className='sidesheet-tab'>
                                <SideSheetTabButton dataId={`sideSheet-tab-${SelectedDetail.Preview}`} isDisabled={isTabDisabled} icon={{ icon: 'eye' }} isActive={selectedDetail == SelectedDetail.Preview} onClick={() => handleTabClick(SelectedDetail.Preview)} />
                            </Tab>
                        )}

                        {!activeNodeIsTemplate && (
                            <>
                                <Tab className='sidesheet-tab'>
                                    <SideSheetTabButton dataId={`sideSheet-tab-${SelectedDetail.Metadata}`} isDisabled={isTabDisabled} icon={{ icon: 'clipboard-list' }} isActive={selectedDetail == SelectedDetail.Metadata} onClick={() => handleTabClick(SelectedDetail.Metadata)} />
                                </Tab>
                                <Tab className='sidesheet-tab'>
                                    <SideSheetTabButton dataId={`sideSheet-tab-${SelectedDetail.Permissions}`} isDisabled={isTabDisabled} icon={{ icon: 'users' }} isActive={selectedDetail == SelectedDetail.Permissions} onClick={() => handleTabClick(SelectedDetail.Permissions)} />
                                </Tab>
                                <Tab className='sidesheet-tab'>
                                    <SideSheetTabButton dataId={`sideSheet-tab-${SelectedDetail.Comments}`} isDisabled={isTabDisabled} icon={{ icon: 'comment' }} isActive={selectedDetail == SelectedDetail.Comments} onClick={() => handleTabClick(SelectedDetail.Comments)} />
                                </Tab>
                                <Tab className='sidesheet-tab'>
                                    <SideSheetTabButton dataId={`sideSheet-tab-${SelectedDetail.Governance}`} isDisabled={isTabDisabled} icon={{ icon: 'shield' }} isActive={selectedDetail == SelectedDetail.Governance} onClick={() => handleTabClick(SelectedDetail.Governance)} />
                                </Tab>
                                <Tab className='sidesheet-tab'>
                                    <SideSheetTabButton dataId={`sideSheet-tab-${SelectedDetail.Automation}`} isDisabled={isTabDisabled} icon={{ icon: 'lightbulb-gear' }} isActive={selectedDetail == SelectedDetail.Automation} onClick={() => handleTabClick(SelectedDetail.Automation)} />
                                </Tab>
                            </>
                        )}
                        <Tab className='sidesheet-tab'>
                            <SideSheetTabButton dataId={`sideSheet-tab-${SelectedDetail.History}`} isDisabled={isTabDisabled} icon={{ icon: 'clock-rotate-left' }} isActive={selectedDetail == SelectedDetail.History} onClick={() => handleTabClick(SelectedDetail.History)} />
                        </Tab>
                    </Tabs>

                    <Tab.Content dataId='sideSheet-pane' className="h-100">
                        <NodeDetails onClose={collapseSideSheet} active={selectedDetail === SelectedDetail.Details && !!sideSheetIsOpen} />
                        {enablePreviewer && <Preview onClose={collapseSideSheet} active={selectedDetail === SelectedDetail.Preview && !!sideSheetIsOpen} showFullScreenButton={showFullScreenButton} />}
                        {selectedDetail === SelectedDetail.Metadata && !!sideSheetIsOpen && <ProfileSideSheetPage nodeId={getNodeIds()[0]} onClose={collapseSideSheet} />}
                        {selectedDetail === SelectedDetail.Permissions && !!sideSheetIsOpen && <Permissions nodeId={getNodeIds()[0]} onClose={collapseSideSheet} />}
                        {selectedDetail === SelectedDetail.Comments && !!sideSheetIsOpen && <Notes nodeId={getNodeIds()[0]} onClose={collapseSideSheet} />}
                        <IFrame className='iframe' src={iframe.url} hidden={!iframedDetails.has(selectedDetail)} />
                    </Tab.Content>
                </SideSheet>
            </ResizableCol>
        </>
    );
};
