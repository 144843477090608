import { useNavigate } from 'react-router';
import { RoutePath, UserSettingsRouteParams } from './routingTypes';

export const useUserSettingsRouting = () => {

    const navigate = useNavigate();

    // My Settings Index

    const getLinkToMySettingsIndex = () => {
        return RoutePath.UserSettingsIndex;
    };

    const routeToMySettingsIndex = () => {
        navigate(getLinkToMySettingsIndex());
    };

    // My Settings Tab

    const getLinkToMySettings = () => {
        return RoutePath.UserSettings;
    };

    const routeToMySettings = () => {
        navigate(getLinkToMySettings());
    };

    // My Accounts Tab

    const getLinkToMyAccounts = () => {
        return RoutePath.MyAccounts;
    };

    const routeToMyAccounts = () => {
        navigate(getLinkToMyAccounts());
    };

    // Integrated Products Tab

    const getLinkToIntegratedProducts = () => {
        return RoutePath.IntegratedProducts;
    };

    const routeToIntegratedProducts = () => {
        navigate(getLinkToIntegratedProducts());
    };

    // Email Imports Tab

    const getLinkToUserEmailImports = () => {
        return RoutePath.UserEmailImports;
    };

    const routeToUserEmailImports = () => {
        navigate(getLinkToUserEmailImports());
    };

    const getLinkToUserEmailImport = (mappingId: string) => {
        return RoutePath.UserEmailImportEdit
            .replace(UserSettingsRouteParams.MappingId, mappingId);
    };

    const routeToUserEmailImport = (mappingId: string) => {
        navigate(getLinkToUserEmailImport(mappingId));
    };

    const getLinkToUserEmailImportNew = () => {
        return RoutePath.UserEmailImportNew;
    };

    const routeToUserEmailImportNew = () => {
        navigate(getLinkToUserEmailImportNew());
    };

    return {
        getLinkToMySettingsIndex,
        getLinkToMySettings,
        getLinkToMyAccounts,
        getLinkToIntegratedProducts,
        getLinkToUserEmailImports,
        getLinkToUserEmailImport,
        getLinkToUserEmailImportNew,
        routeToMySettingsIndex,
        routeToMySettings,
        routeToMyAccounts,
        routeToIntegratedProducts,
        routeToUserEmailImports,
        routeToUserEmailImport,
        routeToUserEmailImportNew,
    };
};
