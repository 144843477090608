import React, { useEffect, useState } from 'react';
import { Dropdown, Tooltip, Text } from '@efilecabinet/efc-atlantis-components';
import { ConfirmDeleteModal } from './ConfirmDeleteModal';
import { ConfirmPrimaryModal } from './ConfirmPrimaryModal';
import { useFileVersionApi } from '../../../../../api/fileVersions/useFileVersionApi';
import { useNodeDetailsContext } from '../../../../../context/nodeDetails/NodeDetailsContext';
import { TranslationFiles, useSafeTranslation, FileVersionsTKeys } from '../../../../../hooks/useSafeTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FileVersion.css';
import { FileVersionDTO } from '../../../../../api/fileVersions/fileVersionApiTypes';

interface FileVersionActionsProps {
    fileVersion: FileVersionDTO;
    className?: string;
}

export const FileVersionActions = ({ fileVersion, className }: FileVersionActionsProps) => {
    const { lockVersion } = useFileVersionApi();
    const { fetchNode, fetchFileVersions } = useNodeDetailsContext();
    const { t } = useSafeTranslation(TranslationFiles.FileVersions);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [isToolTipOpen, setIsToolTipOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isPrimaryModalOpen, setIsPrimaryModalOpen] = useState<boolean>(false);
    const [isLockOptionDisabled, setIsLockOptionDisabled] = useState<boolean>(false);
    const [isDeleteOptionDisabled, setIsDeleteOptionDisabled] = useState<boolean>(false);
    const [isPrimaryOptionDisabled, setIsPrimaryOptionDisabled] = useState<boolean>(false);
    const [tooltipTarget, setTooltipTarget] = useState<string>(`version-actions-${fileVersion.id}`);

    const toggleTooltip = () => setIsToolTipOpen((prevState) => !prevState);

    const toggleDropdown = () => setIsMenuOpen((prevState) => !prevState);

    const handleLockPreview = async (allowPreview: boolean) => {
        lockVersion({ ...fileVersion, allowPreview });
        fetchFileVersions(fileVersion.nodeId.toString());
        fetchNode(fileVersion.nodeId.toString());
    };

    useEffect(() => {
        const randomId = Math.floor(1000 + Math.random() * 9000);
        setTooltipTarget(`version-actions-${randomId}`);

        if (fileVersion.isActive) {
            setIsPrimaryOptionDisabled(true);
            setIsLockOptionDisabled(true);
            setIsDeleteOptionDisabled(true);
        }

        if (!fileVersion.allowPreview) {
            setIsPrimaryOptionDisabled(true);
        }
    }, []);

    return (
        <>
            <span className={className}>
                <Dropdown isOpen={isMenuOpen} toggle={toggleDropdown} dataId={`file-version-actions-dropdown-${fileVersion.id}`}>
                    <Dropdown.Toggle emphasis='low' icon={{ icon: 'ellipsis' }} size='sm' id={tooltipTarget} dataId={`file-version-actions-toggle-${fileVersion.id}`} />
                    <Dropdown.Menu dataId={`file-version-actions-menu-${fileVersion.id}`}>
                        <Dropdown.Item onClick={() => setIsPrimaryModalOpen(true)} className='px-0 ps-2 d-flex align-items-center mb-3 mt-2' disabled={isPrimaryOptionDisabled} dataId={`make-primary-${fileVersion.id}`}>
                            <FontAwesomeIcon icon='star' />
                            <Text className='ms-1' muted={isPrimaryOptionDisabled}>
                                {t(FileVersionsTKeys.MakePrimary)}
                            </Text>
                        </Dropdown.Item>
                        {!!fileVersion.allowPreview && (
                            <Dropdown.Item onClick={() => handleLockPreview(false)} className='px-0 ps-2 d-flex align-items-center my-3' disabled={isLockOptionDisabled} dataId={`lock-version-${fileVersion.id}`}>
                                <FontAwesomeIcon icon='lock' />
                                <Text className='ms-1' muted={isLockOptionDisabled}>
                                    {t(FileVersionsTKeys.LockPreview)}
                                </Text>
                            </Dropdown.Item>
                        )}
                        {!fileVersion.allowPreview && (
                            <Dropdown.Item onClick={() => handleLockPreview(true)} className='px-0 ps-2 d-flex align-items-center my-3' dataId={`unlock-version-${fileVersion.id}`}>
                                <FontAwesomeIcon icon='unlock' />
                                <Text className='ms-1'>{t(FileVersionsTKeys.UnlockPreview)}</Text>
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item onClick={() => setIsDeleteModalOpen(true)} className='px-0 ps-2 d-flex align-items-center mb-2 mt-3' disabled={isDeleteOptionDisabled} dataId={`delete-version-${fileVersion.id}`}>
                            <FontAwesomeIcon icon='trash-can' />
                            <Text className='ms-1' muted={isDeleteOptionDisabled}>
                                {t(FileVersionsTKeys.DeleteVersion)}
                            </Text>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Tooltip target={tooltipTarget} isOpen={isToolTipOpen} toggle={toggleTooltip} placement='top'>
                    {t(FileVersionsTKeys.VersionActions)}
                </Tooltip>
            </span>

            {isPrimaryModalOpen && <ConfirmPrimaryModal fileVersion={fileVersion} isPrimaryModalOpen={isPrimaryModalOpen} closePrimaryModal={() => setIsPrimaryModalOpen(false)} />}

            {isDeleteModalOpen && <ConfirmDeleteModal fileVersion={fileVersion} isDeleteModalOpen={isDeleteModalOpen} closeDeleteModal={() => setIsDeleteModalOpen(false)} />}
        </>
    );
};
