import React, { useEffect, useReducer, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useLocation } from 'react-router';
import { Col, Progress, Row } from 'reactstrap';
import { Virtuoso } from 'react-virtuoso';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Dropdown, Text, TooltipItem, useDropdown } from '@efilecabinet/efc-atlantis-components';
import { ManualTooltip } from '@efilecabinet/nautilus-ui';
import { useFileUtilities } from '../../../../hooks/useFileUtilities';
import { useIcons } from '../../../../hooks/useIcons';
import { FileUploadNavMenuItemTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import zeroStateImg from './fileUploadNavMenuItemZeroStateImage.svg';
import { ZeroState } from '../../../ZeroState';
import { UtopiaUploadFileResponseDTO } from '../../../../api/fileUpload/fileUploadApiTypes';
import { ProgressNotificationUpdateStatus, UtopiaFileUploadProgressUpdate, UtopiaFileUploadProgressUpdateNode, UtopiaProgressNotificationUpdateType } from './fileUploadTypes';
import { RoutePath } from '../../../../hooks/Routing/routingTypes';
import { NodeType } from '../../../../api/node/nodeApiTypes';
import { useFileUploadApi } from '../../../../api/fileUpload/useFileUploadApi';
import './FileUpload.css';

export const FileUploadNavMenuItem = () => {
    const FAILED_FILE_UPLOADS_BATCH_SIZE = 51;
    const TOOLTIP_DISPLAY_TIMOUT_IN_MS = 3000;
    const RELOAD_FAILED_FILE_UPLOADS_DELAY = 3000;

    const location = useLocation();
    const { toggleDropdown, dropdownIsOpen } = useDropdown();
    const { queryFailedFileUploadsAsync, deleteFailedFileUploadAsync, deleteAllFailedFileUploadsAsync } = useFileUploadApi();

    const [failedFileUploads, setFailedFileUploads] = useState<UtopiaUploadFileResponseDTO[]>([]);
    const [canLoadMoreFailedFileUploads, setCanLoadMoreFailedFileUploads] = useState<boolean>(false);
    const [{ currentlyUploadingIdentifiers, sessionFileUploads }, dispatchSessionUploadUpdate] = useReducer(sessionUploadsStateReducer, {
        currentlyUploadingIdentifiers: new Set<string>(),
        failedSessionUploads: new Set<string>(),
        sessionFileUploads: new Map<string, UtopiaFileUploadProgressUpdate>(),
    });

    const tooltipDisplayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const { t } = useSafeTranslation(TranslationFiles.FileUploadNavMenuItem);

    usePrompt(t(FileUploadNavMenuItemTKeys.Prompt), currentlyUploadingIdentifiers.size > 0, (newPath) => document.location.pathname.startsWith(RoutePath.Documents) && !newPath.startsWith(RoutePath.Documents));

    const handleWindowMessage = (message: any) => {
        if (message.data.key == 'utopiaProgressNotification') {
            dispatchSessionUploadUpdate({
                type: SessionUploadsStateActionType.UtopiaFileUploadProgressUpdate,
                utopiaFileUploadProgressUpdate: message.data.value as UtopiaFileUploadProgressUpdate
            });
        } else if (message.data.operationName == 'updateNodesState') {
            dispatchSessionUploadUpdate({
                type: SessionUploadsStateActionType.UpdateNodesState,
                nodes: message.data.nodes
            });
        }
    };

    const deleteFailedFileUpload = (failedFileUpload: UtopiaUploadFileResponseDTO) => {
        setFailedFileUploads((failedFileUploads) => failedFileUploads.filter((i) => i.uploadIdentifier != failedFileUpload.uploadIdentifier));
        deleteFailedFileUploadAsync(failedFileUpload.nodeID, failedFileUpload.uploadIdentifier);
    };

    const deleteAllFailedFileUploads = () => {
        setFailedFileUploads([]);
        deleteAllFailedFileUploadsAsync();
    };

    const reloadFailedFileUploads = () => {
        queryFailedFileUploadsAsync(0, FAILED_FILE_UPLOADS_BATCH_SIZE).then((failedFileUploadsBatch: UtopiaUploadFileResponseDTO[]) => {
            setCanLoadMoreFailedFileUploads(failedFileUploadsBatch.length >= FAILED_FILE_UPLOADS_BATCH_SIZE);
            if (failedFileUploadsBatch.length >= FAILED_FILE_UPLOADS_BATCH_SIZE) {
                failedFileUploadsBatch.pop();
            }
            setFailedFileUploads(failedFileUploadsBatch.map((i) => { return { ...i }; }));
        });
    };

    const loadMoreFailedFileUploads = () => {
        if (canLoadMoreFailedFileUploads) {
            queryFailedFileUploadsAsync(failedFileUploads.length, FAILED_FILE_UPLOADS_BATCH_SIZE)
                .then((failedFileUploadsBatch: UtopiaUploadFileResponseDTO[]) => {
                    setCanLoadMoreFailedFileUploads(failedFileUploadsBatch.length >= FAILED_FILE_UPLOADS_BATCH_SIZE);
                    if (failedFileUploadsBatch.length >= FAILED_FILE_UPLOADS_BATCH_SIZE) {
                        failedFileUploadsBatch.pop();
                    }
                    setFailedFileUploads([...failedFileUploads, ...failedFileUploadsBatch.map((i) => { return { ...i }; })]);
                });
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleWindowMessage);
        return () => {
            window.removeEventListener('message', handleWindowMessage);
        };
    }, []);

    useEffect(() => {
        if (!currentlyUploadingIdentifiers.size) {
            setTimeout(reloadFailedFileUploads, RELOAD_FAILED_FILE_UPLOADS_DELAY); // give it a few seconds in case file upload was canceled and needs to be cleaned up still
        }
    }, [currentlyUploadingIdentifiers.size]);

    useEffect(() => { 
        if (!!sessionFileUploads.size && !dropdownIsOpen && currentlyUploadingIdentifiers.size > 0) {
            if (!!tooltipDisplayTimeoutRef.current) {
                clearTimeout(tooltipDisplayTimeoutRef.current);
            }
            tooltipDisplayTimeoutRef.current = setTimeout(() => {
                if (!!tooltipDisplayTimeoutRef.current) {
                    tooltipDisplayTimeoutRef.current = null;
                }
            }, TOOLTIP_DISPLAY_TIMOUT_IN_MS);
        }
    }, [sessionFileUploads.size]);

    useEffect(() => {
        if (!location.pathname.startsWith(RoutePath.Documents) && currentlyUploadingIdentifiers.size > 0) {
            setTimeout(() => {
                dispatchSessionUploadUpdate({ type: SessionUploadsStateActionType.Reset });
                reloadFailedFileUploads();
            }, 100); // we wait a split second so messages posted from iframe can finish coming through before resetting things.
        }
    }, [location.pathname]);

    return (
        <Dropdown toggle={toggleDropdown} isOpen={dropdownIsOpen}>
            <ManualTooltip title={t(FileUploadNavMenuItemTKeys.NewUploadTooltipMessage)} isHidden={!tooltipDisplayTimeoutRef?.current} placement='bottom' className='new-file-upload-tooltip'>
                <NavMenuIcon currentlyUploadingIdentifiers={currentlyUploadingIdentifiers} failedFileUploads={failedFileUploads} sessionFileUploads={sessionFileUploads}></NavMenuIcon>
            </ManualTooltip>
            <NavMenuDropdown canLoadMoreFailedFileUploads={canLoadMoreFailedFileUploads} deleteAllFailedFileUploads={deleteAllFailedFileUploads} deleteFailedFileUpload={deleteFailedFileUpload} failedFileUploads={failedFileUploads} loadMoreFailedFileUploads={loadMoreFailedFileUploads} sessionFileUploads={sessionFileUploads} dispatchSessionUploadUpdate={dispatchSessionUploadUpdate}></NavMenuDropdown>
        </Dropdown>
    );
};

interface SessionUploadsState {
    currentlyUploadingIdentifiers: Set<string>;
    failedSessionUploads: Set<string>;
    sessionFileUploads: Map<string, UtopiaFileUploadProgressUpdate>;
}

interface SessionUploadsStateAction {
    type: SessionUploadsStateActionType;
    utopiaFileUploadProgressUpdate?: UtopiaFileUploadProgressUpdate;
    nodes?: UtopiaFileUploadProgressUpdateNode[];
    uploadIdentifier?: string;
}

enum SessionUploadsStateActionType {
    UtopiaFileUploadProgressUpdate,
    UpdateNodesState,
    Reset,
    DeleteSessionUpload,
}

const sessionUploadsStateReducer = (state: SessionUploadsState, action: SessionUploadsStateAction) => {
    const { currentlyUploadingIdentifiers, failedSessionUploads, sessionFileUploads } = state;

    const areAllFilesProcessed = (sessionFileUploads: Map<string, UtopiaFileUploadProgressUpdate>) => {
        let areAllFilesProcessed = true;
        sessionFileUploads.forEach((sessionFileUpload) => {
            if ((sessionFileUpload.status as ProgressNotificationUpdateStatus) == ProgressNotificationUpdateStatus.Processing) {
                areAllFilesProcessed = false;
            }
        });
        return areAllFilesProcessed;
    };

    switch (action.type) {
        case SessionUploadsStateActionType.Reset: {
            return {
                currentlyUploadingIdentifiers: new Set<string>(),
                failedSessionUploads: new Set<string>(),
                sessionFileUploads: new Map<string, UtopiaFileUploadProgressUpdate>(),
            };
        }
        case SessionUploadsStateActionType.UtopiaFileUploadProgressUpdate: {
            const update = action.utopiaFileUploadProgressUpdate;
            if (!!update && update.updateType == UtopiaProgressNotificationUpdateType.FileUpload) {

                if (!update?.record?.name && !!update?.record?.fileStatus?.name) {
                    update.record.name = update.record.fileStatus.name;
                }

                const isNewItem = !sessionFileUploads.has(update.identifier) && !failedSessionUploads.has(update.identifier);
                if (isNewItem) {
                    currentlyUploadingIdentifiers.add(update.identifier);
                }

                if (!failedSessionUploads.has(update.identifier)) {
                    sessionFileUploads.set(update.identifier, update);
                }

                new Map(sessionFileUploads).forEach(i => {
                    if (i.status == ProgressNotificationUpdateStatus.Failed) {
                        sessionFileUploads.delete(i.identifier);
                        currentlyUploadingIdentifiers.delete(i.identifier);
                        failedSessionUploads.add(i.identifier);
                    }
                });

                if (areAllFilesProcessed(sessionFileUploads)) {
                    currentlyUploadingIdentifiers.clear();
                }

                return {
                    currentlyUploadingIdentifiers: new Set(currentlyUploadingIdentifiers),
                    failedSessionUploads: new Set(failedSessionUploads),
                    sessionFileUploads: new Map(sessionFileUploads),
                };
            } else {
                return { ...state };
            }
        }
        case SessionUploadsStateActionType.UpdateNodesState: {
            (action.nodes ?? []).filter(node => !!(node as any).operationInProgress && !!node.fileStatus).map(i => i.fileStatus).forEach(fileStatus => {
                if (sessionFileUploads.has(fileStatus.nodeID)) {
                    const sessionFileUpload = { ...sessionFileUploads.get(fileStatus.nodeID) } as UtopiaFileUploadProgressUpdate;
                    if (!!sessionFileUpload?.record) {
                        sessionFileUpload.percentage = Math.trunc(fileStatus.bytesProcessed / fileStatus.sizeInBytes * 100);
                        sessionFileUpload.record.fileStatus.bytesProcessed = fileStatus.bytesProcessed;
                        sessionFileUpload.record.fileStatus.sizeInBytes = fileStatus.sizeInBytes;
                        sessionFileUploads.set(fileStatus.nodeID, sessionFileUpload);
                    }
                }
            });

            return {
                currentlyUploadingIdentifiers: new Set(currentlyUploadingIdentifiers),
                failedSessionUploads: new Set(failedSessionUploads),
                sessionFileUploads: new Map(sessionFileUploads),
            };
        }
        case SessionUploadsStateActionType.DeleteSessionUpload: {
            const newSessionFileUploads = new Map(sessionFileUploads);
            newSessionFileUploads.delete(action.uploadIdentifier!);
            return {
                ...state,
                sessionFileUploads: newSessionFileUploads,
            };
        }
    }
};

interface NavMenuIconProps {
    currentlyUploadingIdentifiers: Set<string>;
    failedFileUploads: UtopiaUploadFileResponseDTO[];
    sessionFileUploads: Map<string, UtopiaFileUploadProgressUpdate>;
}

const NavMenuIcon = (props: NavMenuIconProps) => {
    const { currentlyUploadingIdentifiers, failedFileUploads, sessionFileUploads } = props;

    const showProgressBar = currentlyUploadingIdentifiers.size > 0;
    const { t } = useSafeTranslation(TranslationFiles.FileUploadNavMenuItem);

    const calculateProgressPercentage = () => {
        let totalNumOfBytes = 0;
        let bytesUploaded = 0;
        sessionFileUploads.forEach((sessionFileUpload) => {
            if (currentlyUploadingIdentifiers.has(sessionFileUpload.identifier)) {
                bytesUploaded += sessionFileUpload?.record?.fileStatus?.bytesProcessed ?? 0;
                totalNumOfBytes += sessionFileUpload?.record?.fileStatus?.sizeInBytes ?? 0;
            }
        });
        return (bytesUploaded / totalNumOfBytes) * 100;
    };

    return (
        <Dropdown.Toggle className='nav-link d-flex align-items-center' tag='a'>
            <Col className={showProgressBar ? 'nav-menu-icon-with-progress-bar' : 'nav-menu-icon'}>
                <FontAwesomeIcon icon='upload' className='mx-2' size='lg' title={t(FileUploadNavMenuItemTKeys.Uploads)} />
                {showProgressBar && <Progress value={calculateProgressPercentage()} className='nav-menu-icon-progress-bar' dataId='nav-menu-icon-progress-bar' />}
            </Col>
            {failedFileUploads.length > 0 &&
                <FontAwesomeIcon icon='circle-exclamation' className='failed-uploads-bubble' size='sm' />}
        </Dropdown.Toggle>
    );
};

interface NavMenuDropdownProps {
    canLoadMoreFailedFileUploads: boolean;
    deleteAllFailedFileUploads: () => void;
    deleteFailedFileUpload: (failedFileUpload: UtopiaUploadFileResponseDTO) => void;
    failedFileUploads: UtopiaUploadFileResponseDTO[];
    loadMoreFailedFileUploads: () => void;
    sessionFileUploads: Map<string, UtopiaFileUploadProgressUpdate>;
    dispatchSessionUploadUpdate: React.Dispatch<SessionUploadsStateAction>;
}

const NavMenuDropdown = (props: NavMenuDropdownProps) => {
    const { deleteAllFailedFileUploads, failedFileUploads, sessionFileUploads } = props;

    const { t } = useSafeTranslation(TranslationFiles.FileUploadNavMenuItem);

    return (
        <Dropdown.Menu className='nav-menu-dropdown'>
            <Card fluid borderless>
                <Card.Body id='failed-file-uploads-infinite-scroll-target' className={`nav-menu-dropdown-card-body ${failedFileUploads.length > 0 && 'with-failed-uploads'}`}>
                    <Card.Title className='no-decoration' size='lg' bold icon={{ icon: 'upload', pull: 'left', size: 'lg' }}>
                        {t(FileUploadNavMenuItemTKeys.Uploads)}
                    </Card.Title>
                    {!sessionFileUploads.size && !failedFileUploads.length && (
                        <FileUploadNavMenuItemDropdownZeroState></FileUploadNavMenuItemDropdownZeroState>
                    )}
                    <SessionUploads {...props}></SessionUploads>
                    <FailedUploads {...props}></FailedUploads>
                </Card.Body>
                {failedFileUploads.length > 0 && (
                    <Card.Footer className='clear-all-uploads-button'>
                        <div className='clear-all-uploads-button-text' onClick={() => deleteAllFailedFileUploads()}>
                            {t(FileUploadNavMenuItemTKeys.ClearCta)}
                        </div>
                    </Card.Footer>
                )}
            </Card>
        </Dropdown.Menu>
    );
};

const FileUploadNavMenuItemDropdownZeroState = () => {

    const { t } = useSafeTranslation(TranslationFiles.FileUploadNavMenuItem);

    return (
        <ZeroState
            stackColumns={true}   
            maxImgWidth={165}
            imageColContent={<img src={zeroStateImg} className='mt-5' alt={t(FileUploadNavMenuItemTKeys.ZeroStateImageAlt)}/>}
            textColContent={<Text className='mt-3'>{t(FileUploadNavMenuItemTKeys.ZeroStateMessage)}</Text >}
        ></ZeroState>
    );
};

interface SessionUploadsProps {
    sessionFileUploads: Map<string, UtopiaFileUploadProgressUpdate>;
    dispatchSessionUploadUpdate: React.Dispatch<SessionUploadsStateAction>;
}

const SessionUploads = (props: SessionUploadsProps) => {
    const { sessionFileUploads, dispatchSessionUploadUpdate } = props;

    const deleteUpload = (uploadIdentifier: string) => {
        dispatchSessionUploadUpdate({ type: SessionUploadsStateActionType.DeleteSessionUpload, uploadIdentifier });
    };

    return (
        <>
            {sessionFileUploads.size > 0
                ? Array.from(sessionFileUploads.values()).map((i) => (
                    <Row key={i.identifier} className='session-upload-item mt-3'>
                        <Col xs={i.status == ProgressNotificationUpdateStatus.Completed ? 10 : 7} className='pe-0'>
                            <FileName uploadIdentifier={i.identifier} fileName={i.record.name}></FileName>
                        </Col>
                        {i.status == ProgressNotificationUpdateStatus.Completed && (
                            <Col xs='2'>
                                <div className='d-flex text-nowrap justify-content-end'>
                                    <FontAwesomeIcon size='lg' className='session-upload-item-success-icon' icon='circle-check' />
                                    <FontAwesomeIcon size='lg' className='failed-upload-item-delete-icon' icon='xmark' onClick={() => deleteUpload(i.identifier)} />
                                </div>
                            </Col>
                        )}
                        {i.status != ProgressNotificationUpdateStatus.Completed && (
                            <Col xs='5'>
                                <Row className='ps-0 session-upload-item-processing'>
                                    <Col xs='9' className='ps-2 pe-2'>
                                        <Progress value={i.percentage} className='session-upload-item-processing-progress-bar' />
                                    </Col>
                                    <Col xs='3' className='p-0 text-nowrap text-end'>
                                        {`${Math.round(i.percentage)}%`}
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                ))
                : null}
        </>
    );
};

interface FailedUploadsProps {
    canLoadMoreFailedFileUploads: boolean;
    deleteFailedFileUpload: (failedFileUpload: UtopiaUploadFileResponseDTO) => void;
    failedFileUploads: UtopiaUploadFileResponseDTO[];
    loadMoreFailedFileUploads: () => void;
}

const FailedUploads = (props: FailedUploadsProps) => {

    const { canLoadMoreFailedFileUploads, deleteFailedFileUpload, failedFileUploads, loadMoreFailedFileUploads } = props;

    const [customScrollParent, setCustomScrollParent] = useState<HTMLElement>();

    const { t } = useSafeTranslation(TranslationFiles.FileUploadNavMenuItem);

    const getVirtuosoComponentsConfig = () => {
        return {
            Footer: () => <FailedUploadsFooter canLoadMoreFailedFileUploads={canLoadMoreFailedFileUploads}></FailedUploadsFooter>
        };
    };

    const findCustomScrollParent = () => document.getElementById('failed-file-uploads-infinite-scroll-target');

    useEffect(() => {
        const foundCustomScrollParent = findCustomScrollParent();
        if (!!foundCustomScrollParent) {
            setCustomScrollParent(foundCustomScrollParent);
        }
    }, [!!findCustomScrollParent()]);

    return !!failedFileUploads.length ?
        <>
            <div className='mt-4 ms-1'>{t(FileUploadNavMenuItemTKeys.FailedUploads)}</div>
            <Virtuoso
                data={failedFileUploads}
                endReached={loadMoreFailedFileUploads}
                customScrollParent={customScrollParent}
                components={getVirtuosoComponentsConfig()}
                itemContent={(index, failedFileUpload) => {
                    return (
                        <Row key={failedFileUpload.uploadIdentifier} className='mt-3 failed-upload-item'>
                            <Col xs='7' className='pe-2'>
                                <FileName uploadIdentifier={failedFileUpload.uploadIdentifier} fileName={failedFileUpload.name}></FileName>
                            </Col>
                            <Col xs='5'>
                                <div className='d-flex text-nowrap justify-content-end'>
                                    {!!failedFileUpload.createdOn && (<FailedUploadDateInfo uploadIdentifier={failedFileUpload.uploadIdentifier} whenFailed={failedFileUpload.createdOn} />)}
                                    <FontAwesomeIcon size='lg' className='failed-upload-item-delete-icon' icon='xmark' onClick={() => deleteFailedFileUpload(failedFileUpload)} />
                                </div>
                            </Col>
                        </Row>
                    );
                }}>
            </Virtuoso>
        </>
        :
        null;
};

interface FailedUploadsFooterProps {
    canLoadMoreFailedFileUploads: boolean;
}

const FailedUploadsFooter = (props: FailedUploadsFooterProps) => {

    const { canLoadMoreFailedFileUploads } = props;
    const { t } = useSafeTranslation(TranslationFiles.FileUploadNavMenuItem);

    return canLoadMoreFailedFileUploads ?
        <div className='mt-2 text-center'>{t(FileUploadNavMenuItemTKeys.LoadingItems)}</div>
        :
        <div style={{ height: '15px' }}></div>; // if you don't have a footer w/ a little bit of height, scrolling is jittery when you reach the end of the list
};

interface FileNameProps {
    uploadIdentifier: string;
    fileName: string;
}

const FileName = (props: FileNameProps) => {
    const { uploadIdentifier, fileName } = props;

    const { getNodeIconProps } = useIcons();
    const { getFileExtension } = useFileUtilities();

    return (
        <TooltipItem id={uploadIdentifier} message={fileName}>
            <div className='d-flex'>
                <FontAwesomeIcon icon={getNodeIconProps(NodeType.File, getFileExtension(fileName)).icon} size='lg' />
                <div className='ms-1 truncated-text'>{fileName}</div>
            </div>
        </TooltipItem>
    );
};

interface FailedUploadDateInfoProps {
    uploadIdentifier: string;
    whenFailed: string;
}

const FailedUploadDateInfo = (props: FailedUploadDateInfoProps) => {
    const { uploadIdentifier, whenFailed } = props;

    return (
        <TooltipItem id={'failed-date-' + uploadIdentifier} message={dayjs(whenFailed).format('MMM D, YYYY [at] h:mm A')}>
            <div className='me-2'>{dayjs(whenFailed).format('MMM D, YYYY')}</div>
        </TooltipItem>
    );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * The following functions are temporary.
 * They were removed from v6 of react-router but they will be re-implementing them (or something similar) soon.
 * You can track their progress on this at https://github.com/remix-run/react-router/issues/8139.
 * Do not update to version of react-router or react-router-dom greater than 6.3.0 until they do.
 * They are used to block navigation away from the documents iframe when files are uploading.
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * These hooks re-implement the now removed useBlocker and usePrompt hooks in 'react-router-dom'.
 * Thanks for the idea @piecyk https://github.com/remix-run/react-router/issues/8139#issuecomment-953816315
 * Source: https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874#diff-b60f1a2d4276b2a605c05e19816634111de2e8a4186fe9dd7de8e344b65ed4d3L344-L381
 */
import { useContext, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param  blocker
 * @param  when
 * @see https://reactrouter.com/api/useBlocker
 */
function useBlocker(blocker: any, enable: boolean, when: (newPath: string) => boolean) {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
        if (!enable) return;

        const unblock = (navigator as any).block((tx: any) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    // Automatically unblock the transition so it can play all the way
                    // through before retrying it. TODO: Figure out how to re-enable
                    // this block if the transition is cancelled for some reason.
                    unblock();
                    tx.retry();
                },
            };

            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [navigator, blocker, when]);
}
/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  message
 * @param  when
 */
function usePrompt(message: string, enable: boolean, when: (newPath: string) => boolean) {
    const blocker = useCallback(
        (tx: any) => {
            // eslint-disable-next-line no-alert
            if (when(tx.location.pathname)) {
                if (window.confirm(message)) tx.retry();
            } else {
                tx.retry();
            }
        },
        [message]
    );

    useBlocker(blocker, enable, when);
}
