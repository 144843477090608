import { useNavigate } from 'react-router';
import { RoutePath, AccountSettingsRouteParams } from './routingTypes';

export const useAccountSettingsRouting = () => {

    const navigate = useNavigate();

    // Account Settings Index

    const getLinkToAccountSettingsIndex = () => {
        return RoutePath.AccountSettingsIndex;
    };

    const routeToAccountSettingsIndex = () => {
        navigate(getLinkToAccountSettingsIndex());
    };
    
    // Settings Tab

    const getLinkToAccountSettings = () => {
        return RoutePath.AccountSettings;
    };

    const routeToAccountSettings = () => {
        navigate(getLinkToAccountSettings());
    };

    // SSO Tab

    const getLinkToSingleSignOn = () => {
        return RoutePath.SingleSignOn;
    };

    const routeToSingleSignOn = () => {
        navigate(getLinkToSingleSignOn());
    };

    const getLinkToSamlValidate = () => {
        return RoutePath.SamlValidate;
    };

    const routeToSamlValidate = () => {
        navigate(getLinkToSamlValidate());
    };

    const getLinkToSamlNew = () => {
        return RoutePath.SamlNew;
    };

    const routeToSamlNew = () => {
        navigate(getLinkToSamlNew());
    };

    const getLinkToSamlEdit = (configurationId: string) => {
        return RoutePath.SamlEdit.replace(AccountSettingsRouteParams.ConfigurationId, configurationId);
    };

    const routeToSamlEdit = (configurationId: string) => {
        navigate(getLinkToSamlEdit(configurationId));
    };

    // Branding Tab

    const getLinkToBranding = () => {
        return RoutePath.Branding;
    };

    const routeToBranding = () => {
        navigate(getLinkToBranding());
    };

    // Email Imports Tab

    const getLinkToAccountEmailImports = () => {
        return RoutePath.AccountEmailImports;
    };

    const routeToAccountEmailImports = () => {
        navigate(getLinkToAccountEmailImports());
    };

    const getLinkToAccountEmailImport = (mappingId: string) => {
        return RoutePath.AccountEmailImportEdit.replace(AccountSettingsRouteParams.MappingId, mappingId);
    };

    const routeToAccountEmailImport = (mappingId: string) => {
        navigate(getLinkToAccountEmailImport(mappingId));
    };

    const getLinkToAccountEmailImportNew = () => {
        return RoutePath.AccountEmailImportNew;
    };

    const routeToAccountEmailImportNew = () => {
        navigate(getLinkToAccountEmailImportNew());
    };

    return {
        getLinkToAccountSettingsIndex,
        getLinkToAccountSettings,
        getLinkToSingleSignOn,
        getLinkToSamlValidate,
        getLinkToSamlNew,
        getLinkToSamlEdit,
        getLinkToBranding,
        getLinkToAccountEmailImports,
        getLinkToAccountEmailImport,
        getLinkToAccountEmailImportNew,
        routeToAccountSettingsIndex,
        routeToAccountSettings,
        routeToSingleSignOn,
        routeToSamlValidate,
        routeToSamlNew,
        routeToSamlEdit,
        routeToBranding,
        routeToAccountEmailImports,
        routeToAccountEmailImport,
        routeToAccountEmailImportNew,
    };
};
