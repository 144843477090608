import React, { useState } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDFieldModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../../../hooks/useSafeTranslation';
import { MDOptionItemDTO } from '../../../../../../../api/mdFields/mdFieldApiTypes';
import './mdOptionItem.css';

interface MDOptionItemProps {
    optionItem: MDOptionItemDTO;
    setIsDefault: (id: string, isDefault: boolean) => void;
    removeOption: (id: string) => void;
}

export const MDOptionItem = (props: MDOptionItemProps) => {

    const { optionItem, setIsDefault, removeOption } = props;

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: optionItem.id });
    const { t } = useSafeTranslation(TranslationFiles.MDFieldModal);

    const [isFocused, setIsFocused] = useState(false);

    const style = {
        transition,
        transform: CSS.Transform.toString(transform)
    };

    let className = 'option-item m-1 p-2';
    if (isFocused) className += ' focused';

    const handleOnFocus = () => {
        setIsFocused(true);
    };

    const handleOnBlur = () => {
        setIsFocused(false);
    };

    return (
        <>
            <div key={optionItem.id} ref={setNodeRef} {...attributes} {...listeners} style={style} className={className} onPointerEnter={handleOnFocus} onPointerLeave={handleOnBlur}>
                <div className='d-flex align-items-center'>
                    {isFocused ? <FontAwesomeIcon icon="up-down-left-right" /> : (<span className='empty-span'></span>)}
                    {optionItem.optionName}
                </div>

                <div className='d-flex'>
                    {optionItem.isDefault ?
                        <div className='d-flex pointer'>
                            <span className='fa-layers fa-fw default-star' onClick={() => setIsDefault(optionItem.id, false)}>
                                <FontAwesomeIcon color='#FFD700' icon='star' />
                                <FontAwesomeIcon color='#BC890C' icon={['far', 'star']} />
                            </span>
                        </div>
                        : (
                            <span className='d-flex pointer' onClick={() => setIsDefault(optionItem.id, true)}>
                                <FontAwesomeIcon color='grey' icon={['far', 'star']} />
                                {isFocused && <span className='px-1 make-default'>{t(MDFieldModalTKeys.MakeDefault)}</span>}
                            </span>
                        )
                    }

                    <FontAwesomeIcon className='ms-2 pointer' icon='trash-can' color='grey' onClick={() => removeOption(optionItem.id)} />
                </div>
            </div>
        </>
    );
};