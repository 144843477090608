import { useNavigate } from 'react-router';
import { RoutePath } from './routingTypes';

export const useUtilitiesRouting = () => {

    const navigate = useNavigate();

    // Utilities Index

    const getLinkToUtilitiesIndex = () => {
        return RoutePath.UtilitiesIndex;
    };

    const routeToUtilitiesIndex = () => {
        navigate(getLinkToUtilitiesIndex());
    };

    // CSV Import Tab

    const getLinkToCSVImport = () => {
        return RoutePath.CSVImport;
    };

    const routeToCSVImport = () => {
        navigate(getLinkToCSVImport());
    };

    // CSV Export Tab

    const getLinkToCSVExport = () => {
        return RoutePath.CSVExport;
    };

    const routeToCSVExport = () => {
        navigate(getLinkToCSVExport());
    };

    // Account Template Export Tab

    const getLinkToAccountTemplateExport = () => {
        return RoutePath.AccountTemplateExport;
    };

    const routeToAccountTemplateExport = () => {
        navigate(getLinkToAccountTemplateExport());
    };

    // Account Template Import Tab

    const getLinkToAccountTemplateImport = () => {
        return RoutePath.AccountTemplateImport;
    };

    const routeToAccountTemplateImport = () => {
        navigate(getLinkToAccountTemplateImport());
    };

    return {
        getLinkToUtilitiesIndex,
        getLinkToCSVImport,
        getLinkToCSVExport,
        getLinkToAccountTemplateExport,
        getLinkToAccountTemplateImport,
        routeToUtilitiesIndex,
        routeToCSVImport,
        routeToCSVExport,
        routeToAccountTemplateExport,
        routeToAccountTemplateImport,
    };
};
