import React from 'react';
import { useLayerContext } from '../../../../context/layer/LayerContext';
import { RoutePath } from '../../../../hooks/Routing/routingTypes';
import { AtlantisTour, AtlantisTourStep } from '../../AtlantisTour/AtlantisTour';
import { TourType } from '../../tourTypes';

export const HowToUseESignature = () => {

    //const { t } = useSafeTranslation(TranslationFiles.WelcomeTour);

    const { isTourOpen } = useLayerContext();

    const tourType = TourType.HowToUseESignature;

    const steps: AtlantisTourStep[] = [
        {
            selector: '[data-id=sideNavESignature] > li > span',
            content: 'Select eSignature',
            isNavigationStep: true,
            includeWhen: () => window.location.pathname !== RoutePath.ESignatureIndex,
        },
        {
            selector: '[data-id=eSignatureIframe]',
            content: 'Right click on a folder, then click Apply Template',
            stepInteraction: true,
            position: [10,10]
        },
        {
            selector: '[data-id=eSignatureIframe]',
            content: 'Here, you have two options: Applying a template to this folder, or apply a template to each of the individual folders within (mass-apply template).',
            stepInteraction: true,
            position: [10, 10]
        },
    ];

    return (
        <AtlantisTour steps={steps} isOpen={isTourOpen(tourType)} tourType={tourType} />
    );
};
