import { NodePermissionLevelEnum, initialPermissionState } from './nodePermissionTypes';
import { NodePermissionDTO } from '../../api/nodePermissions/nodePermissionApiTypes';
import { useNodePermissionsApi } from '../../api/nodePermissions/useNodePermissionsApi';
import { RoleEnum, UserLicenseEnum } from '../../api/roles/roleApiTypes';

export const useNodePermissionsUtilities = () => {

    const { getPermissionByNodeIdAsync } = useNodePermissionsApi();

    function updateNodePermissionLevel(permission: NodePermissionDTO, permissionLevel: NodePermissionLevelEnum) {
        switch (permissionLevel) {
            case NodePermissionLevelEnum.View:
                return {
                    ...permission,
                    view: true,
                    download: false,
                    childPermission: null,
                    write: false,
                    delete: false,
                    admin: false,
                    restrict: false,
                };
            case NodePermissionLevelEnum.Download:
                return {
                    ...permission,
                    view: true,
                    download: true,
                    childPermission: null,
                    write: false,
                    delete: false,
                    admin: false,
                    restrict: false,
                };
            case NodePermissionLevelEnum.Create:
                return {
                    ...permission,
                    view: true,
                    download: true,
                    childPermission: {
                        ...permission?.childPermission,
                        view: true,
                        download: true,
                        uploadFiles: true,
                        write: true,
                    },
                    write: false,
                    delete: false,
                    admin: false,
                    restrict: false,
                };
            case NodePermissionLevelEnum.OwnDelete:
                if (!permission?.childPermission?.delete) {
                    return {
                        ...permission,
                        childPermission: {
                            ...permission.childPermission,
                            view: true,
                            download: true,
                            delete: true,
                            write: true,
                        },
                    };
                } else {
                    return {
                        ...permission,
                        childPermission: {
                            ...permission.childPermission,
                            delete: false,
                        },
                    };
                }
            case NodePermissionLevelEnum.UploadFiles:
                if (!permission?.childPermission?.uploadFiles) {
                    return {
                        ...permission,
                        childPermission: {
                            ...permission.childPermission,
                            view: true,
                            download: true,
                            uploadFiles: true,
                            write: true,
                        },
                    };
                } else {
                    return {
                        ...permission,
                        childPermission: {
                            ...permission?.childPermission,
                            uploadFiles: false,
                        },
                    };
                }
            case NodePermissionLevelEnum.CreateDirectories:
                if (!permission?.childPermission?.createDirectories) {
                    return {
                        ...permission,
                        childPermission: {
                            ...permission.childPermission,
                            view: true,
                            download: true,
                            createDirectories: true,
                            write: true,
                        },
                    };
                } else {
                    return {
                        ...permission,
                        childPermission: {
                            ...permission?.childPermission,
                            createDirectories: false,
                        },
                    };
                }
            case NodePermissionLevelEnum.Write:
                return {
                    ...permission,
                    view: true,
                    download: true,
                    childPermission: null,
                    write: true,
                    delete: false,
                    admin: false,
                    restrict: false,
                };
            case NodePermissionLevelEnum.Delete:
                return {
                    ...permission,
                    view: true,
                    download: true,
                    childPermission: null,
                    write: true,
                    delete: true,
                    admin: false,
                    restrict: false,
                };
            case NodePermissionLevelEnum.Admin:
                return {
                    ...permission,
                    view: true,
                    download: true,
                    childPermission: null,
                    write: true,
                    delete: true,
                    admin: true,
                    restrict: false,
                };
            case NodePermissionLevelEnum.Override:
                if (!permission.enforce) {
                    return {
                        ...permission,
                        enforce: true,
                    };
                } else {
                    return {
                        ...permission,
                        enforce: false,
                        restrict: false,
                    };
                }
            case NodePermissionLevelEnum.RestrictAll:
                if (!permission.restrict) {
                    return {
                        ...permission,
                        view: false,
                        download: false,
                        childPermission: null,
                        write: false,
                        delete: false,
                        admin: false,
                        enforce: true,
                        restrict: true,
                    };
                } else {
                    return {
                        ...permission,
                        restrict: false,
                    };
                }
            case NodePermissionLevelEnum.Default:
                return initialPermissionState;
            default:
                return permission;
        }
    }

    const getNodePermissionsAsync = async (nodeId: string) => {
        return await getPermissionByNodeIdAsync(nodeId);
    };

    const filterNodePermissionsByRoleType = (permissions: NodePermissionDTO[], roleType: RoleEnum) => {
        switch (roleType) {
            case RoleEnum.User:
                return permissions.filter((permission) => permission.roleData.roleType === RoleEnum.User && permission.roleData.license !== UserLicenseEnum.Guest);
            case RoleEnum.Group:
                return permissions.filter((permission) => permission.roleData.roleType === RoleEnum.Group);
            case RoleEnum.Guest:
                return permissions.filter((permission) => permission.roleData.roleType === RoleEnum.User && permission.roleData.license === UserLicenseEnum.Guest);
            default:
                return permissions;
        }
    };

    const filterNodePermissionsByCreatedByUserId = (permissions: NodePermissionDTO[], createdByUserId: string) => {
        return permissions.filter((permission) => permission.createdByUserID?.toString() === createdByUserId);
    };

    const filterOtherNodePermissionsByCreatedByUserId = (permissions: NodePermissionDTO[], createdByUserId: string) => {
        return permissions.filter((permission) => permission.createdByUserID?.toString() !== createdByUserId);
    };

    const filterIndividualPermission = (permissions: NodePermissionDTO[], roleId: string) => {
        return permissions.find((permission) => permission.roleID?.toString() === roleId);
    };

    const checkCreateBox = (permission: NodePermissionDTO) => {
        return (
            !!permission?.admin ||
            !!permission?.delete ||
            !!permission?.write ||
            !!permission?.childPermission?.uploadFiles ||
            !!permission?.childPermission?.createDirectories ||
            !!permission?.childPermission?.delete
        );
    };

    return {
        checkCreateBox,
        getNodePermissionsAsync,
        updateNodePermissionLevel,
        filterNodePermissionsByRoleType,
        filterNodePermissionsByCreatedByUserId,
        filterOtherNodePermissionsByCreatedByUserId,
        filterIndividualPermission,
    };
};
