import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Card, Page } from '@efilecabinet/efc-atlantis-components';
import { Recents } from './Recents';
import { WorkYouCareAboutWidget } from '../../features/_workYouCareAbout/workYouCareAboutWidget/workYouCareAboutWidget';
import { Favorites } from './Favorites';
import { Collections } from './Collections';
import { ProductUpdates } from './ProductUpdates';
import { useSettingsAndFeatures } from '../../context/settingsAndFeatures/SettingsFeaturesContext';
import { ScreenSize } from '../../hooks/bootstrap/bootstrapTypes';
import { useBootstrapBreakpoints } from '../../hooks/bootstrap/useBootstrapBreakpoints';
import { SharedWithYou } from './SharedWithYou';
import { useAuthContext } from '../../auth';
import { AccountGuestWelcome } from './AccountGuestWelcome';
import { useAccountBranding } from '../../hooks/useAccountBranding';
import { ExperienceTypeEnum } from '../../auth/AuthenticationTypes';
import { HomeTKeys, TranslationFiles, useSafeTranslation } from '../../hooks/useSafeTranslation';
import { FeatureFlags } from '../../context/settingsAndFeatures/settingsAndFeaturesTypes';
import { ToDoListWidget } from '../../features/_toDoList/toDoListWidget/toDoListWidget';
import './Home.css';


export const Home = () => {
    const { hasFeatureFlags, checkFeatureFlag, settings } = useSettingsAndFeatures();
    const { screenSize } = useBootstrapBreakpoints();
    const { accountBranding } = useAccountBranding();
    const { experienceType } = useAuthContext();
    const { hasAuthUser, authUser, userAccounts } = useAuthContext();

    const [accountName, setAccountName] = useState<string>();

    const showProductUpdates = () => {
        return !!hasFeatureFlags
                    && checkFeatureFlag(FeatureFlags.ProductUpdatesEnabled)
                    && experienceType == ExperienceTypeEnum.Standard;
    };

    const { t } = useSafeTranslation(TranslationFiles.Home);

    useEffect(() => {
        if (!!userAccounts && !!authUser) {
            setAccountName(userAccounts.get(authUser.accountID)?.accountName);
        }
    }, [hasAuthUser]);

    return (
        <Page className='home-page'>
            <Row className='pt-4'>
                <Col lg='9' className='pe-3'>
                    {experienceType == ExperienceTypeEnum.Guest && !!accountName &&
                        <AccountGuestWelcome
                            className='home-card'
                            accountMessage={!! accountBranding?.accountMessage ? accountBranding?.accountMessage : t(HomeTKeys.DefaultWelcomeMessage, {accountName: accountName})}
                            accountLogoImgString={accountBranding?.logoWithMessage ? accountBranding?.accountLogoImgString : undefined}
                            isModal={false}/>
                    }

                    <ToDoListWidget className='home-card' />

                    {screenSize < ScreenSize.l &&
                        <SecondaryHomeCards />
                    }

                    <WorkYouCareAboutWidget hideIfEmpty={experienceType == ExperienceTypeEnum.Guest} className='home-card' />

                    {showProductUpdates() &&
                        <ProductUpdates className='home-card' />
                    }
                </Col>
                {screenSize >= ScreenSize.l &&
                    <Col lg='3' className='ps-3'>
                        <SecondaryHomeCards />
                    </Col>
                }
            </Row>
        </Page>
    );
};

const SecondaryHomeCards = () => {

    const RECENT_NODES_COUNT = 12;
    const { experienceType } = useAuthContext();
    return (
        <>
            <Card fluid borderless className='home-card'>
                <Card.Body>
                    {experienceType == ExperienceTypeEnum.Guest ?
                        <SharedWithYou />
                        :
                        <>
                            <Favorites />
                            <Collections />
                        </>
                    }
                </Card.Body>
            </Card>

            <Recents className='home-card' maxRowsToDisplay={RECENT_NODES_COUNT} />
        </>
    );
};