import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { EfcCallToActionInfo, EfcTableColumnInfo, FullTable, Modal, Page, Tab, Text } from '@efilecabinet/efc-atlantis-components';
import { CheckedOutRoutePath, RoutePath } from '../../../hooks/Routing/routingTypes';
import { useIcons } from '../../../hooks/useIcons';
import { useAuthContext } from '../../../auth';
import { useRouteValidator } from '../../../hooks/useRouteValidator';
import { CheckedOutTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { useCheckedOutApi } from '../../../api/checkedOut/useCheckedOutApi';
import { CheckedOutFileDTO } from '../../../api/checkedOut/checkOutApiTypes';
import './CheckedOut.css';

export const CheckedOut = () => {
    const [selectedItems, setSelectedItems] = useState<CheckedOutFileDTO[]>([]);
    const [checkedOutFiles, setCheckedOut] = useState<CheckedOutFileDTO[]>([]);
    const [displayCheckInConfirmation, setDisplayCheckInConfirmation] = useState(false);

    const { routeToMyCheckedOut, routeToAccountCheckedOut, getLinkToNode } = useRouting();

    const { t } = useSafeTranslation(TranslationFiles.CheckedOut);
    const { authUser } = useAuthContext();
    const { getNodeIconProps } = useIcons();
    const { getMyCheckedOut, getAccountCheckedOut, forceCheckIn } = useCheckedOutApi();
    const { canGoToRoute } = useRouteValidator();

    const currentRoute = `${location.pathname}` as CheckedOutRoutePath;
    const showCheckInConfirmation = () => setDisplayCheckInConfirmation(true);
    const hideCheckInConfirmation = () => setDisplayCheckInConfirmation(false);

    const handleCheckIn = (rowItem?: CheckedOutFileDTO) => {
        if (!!rowItem) {
            setSelectedItems([rowItem]);
            showCheckInConfirmation();
        }
    };

    const confirmCheckIn = async (confirm: boolean) => {
        hideCheckInConfirmation();

        if (confirm && selectedItems.length > 0) {
            const nodeIds = selectedItems.map((i) => i.node.id);
            if (nodeIds.length > 0) {
                await forceCheckIn(nodeIds);
                await getCheckedOut();
                setSelectedItems([]);
            }
        }
    };

    const getCheckedOut = async () => {
        if (currentRoute == CheckedOutRoutePath.CheckedOutPersonal) {
            const myCheckedOut = await getMyCheckedOut();
            setCheckedOut(myCheckedOut);
        } else if (currentRoute == CheckedOutRoutePath.CheckedOutAccount) {
            const accountCheckedOut = await getAccountCheckedOut(authUser?.accountID as number);
            setCheckedOut(accountCheckedOut);
        }
    };

    const getIcon = (item: CheckedOutFileDTO): FontAwesomeIconProps => {
        const fileExt = item.node.name.slice(item.node.name.lastIndexOf('.'));
        return getNodeIconProps(item.node.systemType, fileExt);
    };

    const columns: (string | EfcTableColumnInfo)[] = [
        { name: 'node.name', displayName: t(CheckedOutTKeys.NameColumn), useIcon: getIcon, linkTo: (checkedOutFile: CheckedOutFileDTO) => getLinkToNode([checkedOutFile.node.id]) },
        { name: 'checkedOutOn', displayName: t(CheckedOutTKeys.CheckedOutOnColumn), searchBy: true, isDate: true },
        { name: 'checkedOutBy', displayName: t(CheckedOutTKeys.CheckedOutByColumn), searchBy: true },
        { name: 'path', displayName: t(CheckedOutTKeys.PathColumn), searchBy: true },
    ];

    const bulkActions: EfcCallToActionInfo[] = [
        { text: t(CheckedOutTKeys.ForceCheckInButton), color: 'primary', icon: { icon: 'unlock' }, onClick: showCheckInConfirmation }
    ];

    const contextActions: EfcCallToActionInfo[] = [
        { text: t(CheckedOutTKeys.ForceCheckInButton), color: 'primary', icon: { icon: 'unlock' }, onClick: handleCheckIn }
    ];

    const confirmationCTAs: EfcCallToActionInfo[] = [
        { text: t(CheckedOutTKeys.CancelButton), color: 'primary', emphasis: 'med', onClick: () => confirmCheckIn(false) },
        { text: t(CheckedOutTKeys.ForceCheckInButton), color: 'primary', emphasis: 'high', onClick: () => confirmCheckIn(true) },
    ];

    useEffect(() => {
        if (currentRoute == RoutePath.CheckedOutIndex) {
            if (canGoToRoute(RoutePath.CheckedOutPersonal)) {
                routeToMyCheckedOut();
            } else {
                routeToAccountCheckedOut();
            }
        } else {
            setCheckedOut([]);
            getCheckedOut();
        }
    }, [location.pathname]);

    return (
        <Page title={t(CheckedOutTKeys.CheckedOutTitle)} icon={{ icon: 'lock' }}>
            {canGoToRoute(RoutePath.CheckedOutPersonal) &&
                <Tab active={currentRoute === RoutePath.CheckedOutPersonal} onClick={() => routeToMyCheckedOut()}>
                    {t(CheckedOutTKeys.MyCheckedOutFilesTitle)}
                </Tab>
            }

            {canGoToRoute(RoutePath.CheckedOutAccount) &&
                <Tab active={currentRoute == RoutePath.CheckedOutAccount} onClick={() => routeToAccountCheckedOut()}>
                    {t(CheckedOutTKeys.AccountCheckedOutFilesTitle)}
                </Tab>
            }

            <div className='my-2'>
                <FullTable tag={Link} data={checkedOutFiles} bulkActions={bulkActions} contextActions={contextActions} columns={columns} onSelect={setSelectedItems} />
                {checkedOutFiles.length === 0 &&
                    <Text>
                        {t(CheckedOutTKeys.EmptyCheckedOutMessage)}
                    </Text>
                }
            </div>

            <Modal toggle={hideCheckInConfirmation} title={t(CheckedOutTKeys.ForceCheckInConfirmationTitle)} isOpen={displayCheckInConfirmation} ctas={confirmationCTAs}>
                <Modal.Body>
                    {t(CheckedOutTKeys.ForceCheckInConfirmationMessage, { count: selectedItems.length })}
                </Modal.Body>
            </Modal>
        </Page>
    );
};
