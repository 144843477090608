import React, { useEffect, useState } from 'react';
import { EfcCallToActionInfo, Input, Text, Label, Modal, FormGroup, EfcLink, FormFeedback, Toast, Button } from '@efilecabinet/efc-atlantis-components';
import { FeedbackModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useAuthContext } from '../../../../../auth';
import { useSettingsAndFeatures } from '../../../../../context/settingsAndFeatures/SettingsFeaturesContext';
import { useGoToProductBoard } from '../../../../../hooks/useGoToProductBoard';
import { useForm } from '../../../../../hooks/useForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useColors } from '../../../../../hooks/useColors';
import { FeedbackType, SubmitFeedbackDTO } from '../../../../../api/feedback/feedbackApiTypes';
import { useFeedbackApi } from '../../../../../api/feedback/useFeedbackApi';

export interface FeedbackModalProps {
    toggleFeedback: () => void;
    showFeedbackModal: boolean;
    isExperiencedFeedback?: boolean;
    isPreviewerXFeedback?: boolean;
}



export const FeedbackModal = (props: FeedbackModalProps) => {
    const { t } = useSafeTranslation(TranslationFiles.FeedbackModal);

    const { toggleFeedback, showFeedbackModal, isExperiencedFeedback, isPreviewerXFeedback } = props;

    const [feedbackSuccess, setFeedbackSuccess] = useState(false);

    const { user, authUser, optInInfo } = useAuthContext();
    const { settings, desktopApplicationInfo } = useSettingsAndFeatures();
    const { submitFeedback } = useFeedbackApi();
    const { openProductBoardUpdatesInNewTab } = useGoToProductBoard();
    const { atlantisPrimary } = useColors();

    const getInitialFeedbackType = (): FeedbackType => {
        if (isExperiencedFeedback) {
            return FeedbackType.ExperiencedFeedback;
        }

        if (isPreviewerXFeedback) {
            return FeedbackType.PreviewerX;
        }

        return FeedbackType.Unspecified;
    };

    const initFeedbackForm: SubmitFeedbackDTO = {
        FeedbackType: getInitialFeedbackType(),
        Message: '',
        Email: '',
        AccountId: 0,
        FeedbackRange: 0,
        Location: '',
        AtlantisOnly: false,
    };

    const feedbackForm = useForm<SubmitFeedbackDTO>(initFeedbackForm);

    const handleSubmitFeedback = async () => {
        feedbackForm.clearErrors();
        if (feedbackForm.model.FeedbackType === FeedbackType.PreviewerX || !!feedbackForm.model.Message) {
            const isAlreadySuccess = feedbackSuccess;

            setFeedbackSuccess(true);
            const feedback: SubmitFeedbackDTO = {
                FeedbackType: feedbackForm.model.FeedbackType,
                Message: feedbackForm.model.Message,
                Email: user?.userName ?? '',
                AccountId: authUser?.accountID ?? 0,
                FeedbackRange: feedbackForm.model.FeedbackRange,
                Location: location.pathname,
                DesktopVersion: desktopApplicationInfo?.version ?? '',
                AtlantisOnly: !userHasUsedUtopia(),
            };

            if (isAlreadySuccess) {
                togglePreviewerXFeedback();
            }

            await submitFeedback(feedback);
        } else {
            feedbackForm.addError('Message', t(FeedbackModalTKeys.Required));
        }
    };

    const togglePreviewerXFeedback = async () => {
        setFeedbackSuccess(false);
        localStorage.setItem(`previewerx-sent-feedback-${user?.id}`, 'true');
        toggleFeedback();
    };

    const getMessageLabelText = () => {
        if (feedbackForm.model.FeedbackType == FeedbackType.Bug || feedbackForm.model.FeedbackType == FeedbackType.NotFound) {
            return t(FeedbackModalTKeys.DescribeYourIssue);
        } else if (feedbackForm.model.FeedbackType == FeedbackType.FeatureReq) {
            return t(FeedbackModalTKeys.DescribeFeatureRequest);
        } else if (feedbackForm.model.FeedbackType == FeedbackType.ExperiencedFeedback) {
            return t(FeedbackModalTKeys.ExperiencedFeedbackLabel);
        }

        return t(FeedbackModalTKeys.HowIsProductWorking);
    };

    const getFeedbackTitle = () => {
        if (feedbackSuccess && feedbackForm.model.FeedbackType != FeedbackType.PreviewerX) {
            return t(FeedbackModalTKeys.SuccessTitle);
        } else if (feedbackForm.model.FeedbackType == FeedbackType.PreviewerX) {
            return '';
        }

        return t(FeedbackModalTKeys.Title);
    };

    const getFeedbackCtas = () => {
        if (feedbackSuccess) {
            if (feedbackForm.model.FeedbackType == FeedbackType.PreviewerX) {
                return feedbackCtas;
            }

            return feedbackSuccessCtas;
        }

        return feedbackCtas;
    };

    const onFeedbackClosed = () => {
        setFeedbackSuccess(false);
    };

    const shouldShowFeedbackTypeOptions = () => {
        const isExperiencedFeedback = feedbackForm.model.FeedbackType == FeedbackType.ExperiencedFeedback;
        const isPreviewerXFeedback = feedbackForm.model.FeedbackType == FeedbackType.PreviewerX;

        if (isExperiencedFeedback || isPreviewerXFeedback) {
            return false;
        }

        return true;
    };

    const shouldShowFormFeedbackBox = () => {
        const isPreviewerXFeedback = feedbackForm.model.FeedbackType == FeedbackType.PreviewerX;

        if (isPreviewerXFeedback) {
            return false;
        }

        return true;
    };

    const shouldBeStatic = () => {
        const isExperiencedFeedback = feedbackForm.model.FeedbackType == FeedbackType.ExperiencedFeedback;

        return isExperiencedFeedback;
    };

    const shouldDisableBackdrop = () => {
        const isPreviewerXFeedback = feedbackForm.model.FeedbackType == FeedbackType.PreviewerX;

        return isPreviewerXFeedback;
    };

    const userHasUsedUtopia = () => {
        return !!optInInfo?.optInAtlantisDateTime;
    };

    const getModalStyle = () => {
        return feedbackForm.model.FeedbackType == FeedbackType.PreviewerX ? 'feedbackModal previewerXFeedbackModal' : 'feedbackModal';
    };

    const getBackdrop = () => {
        if (shouldBeStatic()) {
            return 'static';
        }

        if (shouldDisableBackdrop()) {
            return false;
        }

        return true;
    };

    const feedbackCtas: EfcCallToActionInfo[] = [
        { text: t(FeedbackModalTKeys.Close), emphasis: 'med', onClick: toggleFeedback },
        { text: t(FeedbackModalTKeys.Submit), emphasis: 'high', onClick: handleSubmitFeedback },
    ];

    const feedbackSuccessCtas: EfcCallToActionInfo[] = [{ text: t(FeedbackModalTKeys.Close), emphasis: 'med', onClick: toggleFeedback }];

    useEffect(() => {
        if (!showFeedbackModal) {
            feedbackForm.setObject(initFeedbackForm);
            feedbackForm.clearErrors();
        }
    }, [showFeedbackModal]);

    return (
        <>
            {feedbackForm.model.FeedbackType != FeedbackType.PreviewerX && (
                <Modal className={getModalStyle()} onClosed={onFeedbackClosed} title={getFeedbackTitle()} toggle={toggleFeedback} isOpen={showFeedbackModal} icon={feedbackSuccess ? { icon: 'circle-check', color: 'green' } : undefined} ctas={getFeedbackCtas()} backdrop={getBackdrop()}>
                    {!feedbackSuccess && (
                        <Modal.Body>
                            {shouldShowFeedbackTypeOptions() && (
                                <FormGroup className='mt-3'>
                                    <Label for='type-of-feedback'>{t(FeedbackModalTKeys.TypeOfFeedback)}</Label>
                                    <Input id='type-of-feedback' name='FeedbackType' type='select' className='w-75' value={feedbackForm.model.FeedbackType} onChange={feedbackForm.onPropChanged}>
                                        <option value={FeedbackType.Unspecified} hidden>
                                            {t(FeedbackModalTKeys.UnspecifiedOption)}
                                        </option>
                                        <option value={FeedbackType.Bug}>{t(FeedbackModalTKeys.BugOption)}</option>
                                        <option value={FeedbackType.NotFound}>{t(FeedbackModalTKeys.NotFoundOption)}</option>
                                        <option value={FeedbackType.FeatureReq}>{t(FeedbackModalTKeys.FeatureOption)}</option>
                                        <option value={FeedbackType.Feedback}>{t(FeedbackModalTKeys.FeedbackOption)}</option>
                                    </Input>
                                </FormGroup>
                            )}
                            {feedbackForm.model.FeedbackType == FeedbackType.Bug && (
                                <>
                                    <Text>
                                        {t(FeedbackModalTKeys.ImmediateSupport)}
                                        <EfcLink color='primary' newWindow href={settings?.chatNowUrl}>
                                            {t(FeedbackModalTKeys.ChatWithAnAgent)}
                                        </EfcLink>
                                        {t(FeedbackModalTKeys.CallUsAt)}
                                        <br />
                                        {t(FeedbackModalTKeys.SupportNumber)}
                                    </Text>
                                </>
                            )}
                            {feedbackForm.model.FeedbackType == FeedbackType.ExperiencedFeedback && userHasUsedUtopia() && (
                                <>
                                    <Text>{t(FeedbackModalTKeys.ExperiencedFeedbackMessage)}</Text>
                                    <FormGroup className='mt-4 mb-5'>
                                        <Label for='feedback-range'>{t(FeedbackModalTKeys.ExperiencedFeedbackRangeLabel)}</Label>
                                        <Input id='feedback-range' name='FeedbackRange' list='feedback-tickmarks' type='range' step='10' min='-50' max='50' value={feedbackForm.model.FeedbackRange} onChange={feedbackForm.onPropChanged} />
                                        <datalist id='feedback-tickmarks' className='feedback-datalist'>
                                            <option value={-50} label={t(FeedbackModalTKeys.MuchWorse)} />
                                            <option value={-25} label={t(FeedbackModalTKeys.Worse)} />
                                            <option value={0} label={t(FeedbackModalTKeys.Same)} />
                                            <option value={25} label={t(FeedbackModalTKeys.Better)} />
                                            <option value={50} label={t(FeedbackModalTKeys.MuchBetter)} />
                                        </datalist>
                                    </FormGroup>
                                </>
                            )}
                            {feedbackForm.model.FeedbackType == FeedbackType.ExperiencedFeedback && !userHasUsedUtopia() && (
                                <>
                                    <Text>{t(FeedbackModalTKeys.AtlantisOnlyExperiencedFeedbackMessage)}</Text>
                                    <FormGroup className='mt-4 mb-5'>
                                        <Label for='feedback-range'>{t(FeedbackModalTKeys.AtlantisOnlyExperiencedFeedbackRangeLabel)}</Label>
                                        <Input id='feedback-range' name='FeedbackRange' list='feedback-tickmarks' type='range' step='10' min='-50' max='50' value={feedbackForm.model.FeedbackRange} onChange={feedbackForm.onPropChanged} />
                                        <datalist id='feedback-tickmarks' className='feedback-datalist'>
                                            <option value={-50} label={t(FeedbackModalTKeys.VeryDifficult)} />
                                            <option className='shift' value={-25} label={t(FeedbackModalTKeys.Difficult)} />
                                            <option className='shift' value={0} label={t(FeedbackModalTKeys.Neutral)} />
                                            <option className='shift' value={25} label={t(FeedbackModalTKeys.Easy)} />
                                            <option value={50} label={t(FeedbackModalTKeys.VeryEasy)} />
                                        </datalist>
                                    </FormGroup>
                                </>
                            )}
                            {shouldShowFormFeedbackBox() && (
                                <FormGroup>
                                    <Text semibold>{getMessageLabelText()}</Text>
                                    <Input type='textarea' className='feedback-input mt-2' name='Message' value={feedbackForm.model.Message} onChange={feedbackForm.onPropChanged} invalid={!!feedbackForm.errors?.Message} />
                                    <FormFeedback>{feedbackForm.errors?.Message}</FormFeedback>
                                </FormGroup>
                            )}
                        </Modal.Body>
                    )}
                    <Modal.Body className='mt-3 mb-2'>
                        <Text semibold>
                            {t(FeedbackModalTKeys.LearnMore)}
                            <EfcLink color='primary' onClick={openProductBoardUpdatesInNewTab}>
                                {t(FeedbackModalTKeys.SeeUpdates)}
                                <FontAwesomeIcon icon='external-link' className='my-auto' color={atlantisPrimary} />
                            </EfcLink>
                        </Text>
                    </Modal.Body>
                </Modal>
            )}
            {feedbackForm.model.FeedbackType == FeedbackType.PreviewerX && (
                <Toast className='feedback-toast-wrapper' isOpen={showFeedbackModal}>
                    {!feedbackSuccess && (
                        <>
                            <Toast.Header className='feedback-toast-header' close={<button type='button' className='btn-close feedback-input-close' aria-label='Close' onClick={toggleFeedback}></button>}>
                                <strong>{t(FeedbackModalTKeys.PreviewerXExperienceFeedbackTitle)}</strong>
                            </Toast.Header>
                            <Toast.Body className='feedback-toast-body'>
                                <FormGroup>
                                    <Input id='feedback-range' name='FeedbackRange' list='feedback-tickmarks' type='range' step='10' min='-50' max='50' value={feedbackForm.model.FeedbackRange} onChange={feedbackForm.onPropChanged} />
                                    <datalist id='feedback-tickmarks' className='feedback-datalist'>
                                        <option value={-1} label={t(FeedbackModalTKeys.Poor)} />
                                        <option className='previewerx-feedback-slider-option' value={0} label={t(FeedbackModalTKeys.Neutral)} />
                                        <option value={1} label={t(FeedbackModalTKeys.Excellent)} />
                                    </datalist>
                                </FormGroup>
                                <div className='feedback-toast-footer'>
                                    <Button color='primary' emphasis='med' onClick={toggleFeedback}>
                                        Cancel
                                    </Button>
                                    <Button color='primary' className='feedback-toast-submit-button' onClick={handleSubmitFeedback}>
                                        Submit
                                    </Button>
                                </div>
                            </Toast.Body>
                        </>
                    )}
                    {feedbackSuccess && (
                        <>
                            <Toast.Header className='feedback-toast-header' close={<button type='button' className='btn-close feedback-input-close' aria-label='Close' onClick={togglePreviewerXFeedback}></button>}>
                                <strong>{t(FeedbackModalTKeys.PreviewerXExperienceFeedbackSuccessTitle)}</strong>
                            </Toast.Header>
                            <Toast.Body className='feedback-toast-body'>
                                <FormGroup>
                                    <Text>{t(FeedbackModalTKeys.PreviewerXExperienceFeedbackSuccessMessage)}</Text>
                                    <Input type='textarea' className='success-feedback-input mt-2' name='Message' value={feedbackForm.model.Message} onChange={feedbackForm.onPropChanged} invalid={!!feedbackForm.errors?.Message} />
                                    <FormFeedback>{feedbackForm.errors?.Message}</FormFeedback>
                                </FormGroup>
                                <div className='feedback-toast-footer'>
                                    <Button color='primary' emphasis='med' onClick={togglePreviewerXFeedback}>
                                        Cancel
                                    </Button>
                                    <Button color='primary' className='feedback-toast-submit-button' onClick={handleSubmitFeedback}>
                                        Submit
                                    </Button>
                                </div>
                            </Toast.Body>
                        </>
                    )}
                </Toast>
            )}
        </>
    );
};
