import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useFormik } from 'formik';
import { Collapse, NestedCheckbox, FormGroup, Label, Input, TextRadioButton, EfcTextRadioButtonInfo, FormFeedback } from '@efilecabinet/efc-atlantis-components';
import { TextMDFieldModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../../../hooks/useSafeTranslation';
import { MDFieldAdvancedFormatsCollapse } from '../../__shared/mdFieldAdvancedFormatsCollapse/mdFieldAdvancedFormatsCollapse';
import { AllowedCharacterTypes, MDFieldDTO, TextMDFieldDTO } from '../../../../../../../api/mdFields/mdFieldApiTypes';

export interface TextMDFieldAdvancedFormatsProps {
    mdField: MDFieldDTO;
    setMDField: (mdField: MDFieldDTO) => void;
    setIsFormValid: (isFormValid: boolean) => void;
}

enum TextFormInputIds {
    AllowedCharacters = 'allowedCharacters',
    MinChars = 'minChars',
    MaxChars = 'maxChars',
    Prefix = 'prefix',
    SensitiveDataFormatting = 'sensitiveDataShown',
    FirstChars = 'firstChars',
    LastChars = 'lastChars',
}

export const TextMDFieldAdvancedFormats = (props: TextMDFieldAdvancedFormatsProps) => {
    const { mdField, setMDField, setIsFormValid } = props;

    const { t } = useSafeTranslation(TranslationFiles.TextMDFieldModal);

    const [isCollapseOpen, setIsCollapseOpen] = useState(false);
    const [isMinMaxCharsChecked, setIsMinMaxCharsChecked] = useState(false);
    const [isPrefixChecked, setIsPrefixChecked] = useState(false);
    const [isSensitiveDataChecked, setIsSensitiveDataChecked] = useState(false);
    const [minMaxCharsError, setMinMaxCharsError] = useState('');
    const [prefixError, setPrefixError] = useState('');
    const [sensitiveDataError, setSensitiveDataError] = useState('');

    const formik = useFormik({
        initialValues: {
            allowedCharacters: mdField?.textProps?.allowedCharacters ?? AllowedCharacterTypes.Alphanumeric,
            minChars: mdField?.textProps?.minChars ?? undefined,
            maxChars: mdField?.textProps?.maxChars ?? undefined,
            prefix: mdField?.textProps?.prefix ?? undefined,
            sensitiveDataFormat: {
                sensitiveDataShown: mdField?.textProps?.sensitiveDataFormat?.sensitiveDataShown ?? false,
                firstChars: mdField?.textProps?.sensitiveDataFormat?.firstChars ?? undefined,
                lastChars: mdField?.textProps?.sensitiveDataFormat?.lastChars ?? undefined,
            }
        },
        onSubmit: values => { return; }, //Formik Expects an OnSubmit
    });

    const initialEditSetup = () => {
        if (!!mdField?.textProps?.minChars || !!mdField?.textProps?.maxChars) {
            toggleMinMaxCharsCheck();
        }

        if (!!mdField?.textProps?.prefix) {
            togglePrefixCheck();
        }

        if (!!mdField?.textProps?.sensitiveDataFormat?.sensitiveDataShown) {
            toggleSensitiveDataCheck();
        }
    };

    const toggleCollapse = () => {
        setIsCollapseOpen(!isCollapseOpen);
    };

    const toggleMinMaxCharsCheck = () => {
        setIsMinMaxCharsChecked(!isMinMaxCharsChecked);
    };

    const togglePrefixCheck = () => {
        setIsPrefixChecked(!isPrefixChecked);
    };

    const toggleSensitiveDataCheck = () => {
        setIsSensitiveDataChecked(!isSensitiveDataChecked);
    };

    const getButtonInfos = (): EfcTextRadioButtonInfo[] => {
        return [
            { key: AllowedCharacterTypes[AllowedCharacterTypes.Alphanumeric], labelName: t(TextMDFieldModalTKeys.Alphanumeric), helperText: t(TextMDFieldModalTKeys.AlphanumericHelpText), checked: true },
            { key: AllowedCharacterTypes[AllowedCharacterTypes.Alpha], labelName: t(TextMDFieldModalTKeys.Alpha), helperText: t(TextMDFieldModalTKeys.AlphaHelpText) },
        ];
    };

    const getHideOrShowCopy = () => {
        return formik.values.sensitiveDataFormat.sensitiveDataShown
            ? `${t(TextMDFieldModalTKeys.Hide)}`
            : `${t(TextMDFieldModalTKeys.Show)}`;
    };

    const onAllowedCharactersChecked = (button: EfcTextRadioButtonInfo) => {
        formik.handleChange({ target: { name: TextFormInputIds.AllowedCharacters, value: button.key } });
    };

    const isValueNegative = (value: number | undefined) => {
        return !!value && value < 0;
    };

    const validateMinMaxChars = () => {
        let error = '';
        if (isMinMaxCharsChecked && !formik.values.minChars && !formik.values.maxChars) {
            error = t(TextMDFieldModalTKeys.MinMaxCharsNeededError);
        } else if (isValueNegative(formik.values.minChars) || isValueNegative(formik.values.maxChars)) {
            error = t(TextMDFieldModalTKeys.NegativeValueError);
        } else if (!!formik.values.minChars && !!formik.values.maxChars && formik.values.maxChars <= formik.values.minChars) {
            error = t(TextMDFieldModalTKeys.MaxValueError);
        }
        setMinMaxCharsError(error);
    };

    const validatePrefix = () => {
        let error = '';
        if (isPrefixChecked && !formik.values.prefix) {
            error = t(TextMDFieldModalTKeys.PrefixNeededError);
        }
        setPrefixError(error);
    };

    const validateSensitiveData = () => {
        let error = '';
        if (isSensitiveDataChecked && !formik.values.sensitiveDataFormat.firstChars && !formik.values.sensitiveDataFormat.lastChars) {
            error = t(TextMDFieldModalTKeys.SensitiveDataNeededError);
        } else if (isValueNegative(formik.values.sensitiveDataFormat.firstChars) || isValueNegative(formik.values.sensitiveDataFormat.lastChars)) {
            error = t(TextMDFieldModalTKeys.NegativeValueError);
        }
        setSensitiveDataError(error);
    };

    function handleSensitiveDataInput(e: any) {
        formik.handleChange({target: {name: 'sensitiveDataFormat.'+ e.target.name, value: e.target.value}});
    }

    useEffect(() => {
        initialEditSetup();

        setMDField({ ...mdField, textProps: { ...mdField.textProps as TextMDFieldDTO, ...formik.initialValues } });
    }, []);

    useEffect(() => {
        setMDField({ ...mdField, textProps: { ...mdField.textProps as TextMDFieldDTO, ...formik.values } });
    }, [formik.values]);

    useEffect(() => {
        setIsFormValid(!minMaxCharsError && !prefixError && !sensitiveDataError);
    }, [minMaxCharsError, prefixError, sensitiveDataError]);

    return (
        <>
            <MDFieldAdvancedFormatsCollapse isOpen={isCollapseOpen} toggleCollapse={toggleCollapse} />

            <Row className='mt-3'>
                <Col>
                    <Collapse isOpen={isCollapseOpen}>

                        <TextRadioButton
                            buttonInfos={getButtonInfos()}
                            onChecked={onAllowedCharactersChecked}
                            radioGroupName={TextFormInputIds.AllowedCharacters} />

                        <NestedCheckbox
                            labelName={t(TextMDFieldModalTKeys.MinMaxCharacters)}
                            className='my-4'
                            isChecked={isMinMaxCharsChecked}
                            onToggleCheck={toggleMinMaxCharsCheck} >

                            <FormGroup>
                                <Row>
                                    <Col xs='3' className='mt-3'>
                                        <Label for={TextFormInputIds.MinChars}>
                                            {t(TextMDFieldModalTKeys.MinimumCharacters)}
                                        </Label>
                                        <Input
                                            id={TextFormInputIds.MinChars}
                                            name={TextFormInputIds.MinChars}
                                            type='number'
                                            placeholder={t(TextMDFieldModalTKeys.NAPlaceholder)}
                                            min={0}
                                            value={formik.values.minChars}
                                            onChange={formik.handleChange}
                                            invalid={!!minMaxCharsError}
                                            onBlur={validateMinMaxChars} />
                                    </Col>
                                    <Col xs='3' className='mt-3'>
                                        <Label for={TextFormInputIds.MaxChars}>
                                            {t(TextMDFieldModalTKeys.MaximumCharacters)}
                                        </Label>
                                        <Input
                                            id={TextFormInputIds.MaxChars}
                                            name={TextFormInputIds.MaxChars}
                                            type='number'
                                            placeholder={t(TextMDFieldModalTKeys.NAPlaceholder)}
                                            min={0}
                                            value={formik.values.maxChars}
                                            onChange={formik.handleChange}
                                            invalid={!!minMaxCharsError}
                                            onBlur={validateMinMaxChars} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormFeedback className={!!minMaxCharsError ? 'd-block' : ''} valid={!minMaxCharsError}>
                                            {minMaxCharsError}
                                        </FormFeedback>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </NestedCheckbox>

                        <NestedCheckbox
                            labelName={t(TextMDFieldModalTKeys.Prefix)}
                            className='my-4'
                            isChecked={isPrefixChecked}
                            onToggleCheck={togglePrefixCheck} >

                            <FormGroup>
                                <Row>
                                    <Col xs='5' className='mt-3'>
                                        <Label for={TextFormInputIds.Prefix}>
                                            {t(TextMDFieldModalTKeys.Prefix)}
                                        </Label>
                                        <Input
                                            id={TextFormInputIds.Prefix}
                                            name={TextFormInputIds.Prefix}
                                            type='text'
                                            placeholder={t(TextMDFieldModalTKeys.PrefixPlaceholder)}
                                            value={formik.values.prefix}
                                            onChange={formik.handleChange}
                                            invalid={!!prefixError}
                                            onBlur={validatePrefix} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormFeedback className={!!prefixError ? 'd-block' : ''} valid={!prefixError}>
                                            {prefixError}
                                        </FormFeedback>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </NestedCheckbox>

                        <NestedCheckbox
                            labelName={t(TextMDFieldModalTKeys.SensitiveData)}
                            className='my-4'
                            isChecked={isSensitiveDataChecked}
                            onToggleCheck={toggleSensitiveDataCheck} >

                            <FormGroup tag='fieldset'>
                                <Row>
                                    <Col xs={3} className='mt-3'>
                                        <FormGroup check>
                                            <Input
                                                id={TextMDFieldModalTKeys.Hide}
                                                name={TextFormInputIds.SensitiveDataFormatting}
                                                type='radio'
                                                defaultChecked
                                                value={TextMDFieldModalTKeys.Hide}
                                                onClick={formik.handleChange} />
                                            <Label for={TextMDFieldModalTKeys.Hide}>
                                                {`${t(TextMDFieldModalTKeys.Hide)}${t(TextMDFieldModalTKeys.CharactersUpper)}`}
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={4} className='mt-3'>
                                        <FormGroup check>
                                            <Input
                                                id={TextMDFieldModalTKeys.Show}
                                                name={TextFormInputIds.SensitiveDataFormatting}
                                                type='radio'
                                                value={TextMDFieldModalTKeys.Show}
                                                onClick={formik.handleChange} />
                                            <Label for={TextMDFieldModalTKeys.Show}>
                                                {`${t(TextMDFieldModalTKeys.Show)}${t(TextMDFieldModalTKeys.CharactersUpper)}`}
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup>
                                <Row>
                                    <Col className='d-flex align-items-center'>
                                        <Label for={TextFormInputIds.FirstChars} className='mb-0 text-nowrap'>
                                            {getHideOrShowCopy() + `${t(TextMDFieldModalTKeys.First)}`}
                                        </Label>
                                        <div className='mx-2' style={{ width: '80px' }}>
                                            <Input
                                                id={TextFormInputIds.FirstChars}
                                                name={TextFormInputIds.FirstChars}
                                                type='number'
                                                placeholder={t(TextMDFieldModalTKeys.NAPlaceholder)}
                                                value={formik.values.sensitiveDataFormat.firstChars}
                                                onChange={handleSensitiveDataInput}
                                                invalid={!!sensitiveDataError}
                                                onBlur={validateSensitiveData} />
                                        </div>
                                        <Label for={TextFormInputIds.FirstChars} className='mb-0 me-5 text-nowrap'>
                                            {t(TextMDFieldModalTKeys.CharactersLower)}
                                        </Label>
                                        <Label for={TextFormInputIds.LastChars} className='mb-0 text-nowrap'>
                                            {getHideOrShowCopy() + `${t(TextMDFieldModalTKeys.Last)}`}
                                        </Label>
                                        <div className='mx-2' style={{ width: '80px' }}>
                                            <Input
                                                id={TextFormInputIds.LastChars}
                                                name={TextFormInputIds.LastChars}
                                                type='number'
                                                placeholder={t(TextMDFieldModalTKeys.NAPlaceholder)}
                                                value={formik.values.sensitiveDataFormat.lastChars}
                                                onChange={handleSensitiveDataInput}
                                                invalid={!!sensitiveDataError}
                                                onBlur={validateSensitiveData} />
                                        </div>
                                        <Label for={TextFormInputIds.LastChars} className='mb-0 text-nowrap'>
                                            {t(TextMDFieldModalTKeys.CharactersLower)}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormFeedback className={!!sensitiveDataError ? 'd-block' : ''} valid={!sensitiveDataError}>
                                            {sensitiveDataError}
                                        </FormFeedback>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </NestedCheckbox>

                    </Collapse>
                </Col>
            </Row>
        </>
    );
};