import { useNavigate } from 'react-router';
import { IAuthContext } from '../../auth';
import { RoutePath, WorkflowRouteParams } from './routingTypes';

export const useWorkflowRouting = () => {

    const navigate = useNavigate();

    // Workflow Index

    const getLinkToWorkflowIndex = () => {
        return RoutePath.WorkflowIndex;
    };

    const routeToWorkflowIndex = () => {
        navigate(getLinkToWorkflowIndex());
    };
    
    // Workflows Tab

    const getLinkToWorkflows = () => {
        return RoutePath.Workflows;
    };

    const routeToWorkflows = () => {
        navigate(getLinkToWorkflows());
    };

    const getLinkToWorkflow = (workflowId: string) => {
        return RoutePath.WorkflowEdit.replace(WorkflowRouteParams.WorkflowId, workflowId);
    };

    const routeToWorkflow = (accountId: number, workflowId: string, authContext: IAuthContext) => {
        if (!!accountId && accountId != authContext.authUser?.accountID) {
            authContext.openAccountSwitchRequiredModal(accountId, () => navigate(getLinkToWorkflow(workflowId)));
        } else {
            navigate(getLinkToWorkflow(workflowId));
        }
    };

    // Instances Tab

    const getLinkToWorkflowInstances = () => {
        return RoutePath.WorkflowInstances;
    };

    const routeToWorkflowInstances = () => {
        navigate(getLinkToWorkflowInstances());
    };

    const getLinkToWorkflowInstance = (workflowInstanceId: string) => {
        return RoutePath.WorkflowInstanceEdit.replace(WorkflowRouteParams.InstanceId, workflowInstanceId.toString());
    };

    const routeToWorkflowInstance = (accountId: number, workflowInstanceId: string, authContext: IAuthContext) => {
        if (!!accountId && accountId != authContext.authUser?.accountID) {
            authContext.openAccountSwitchRequiredModal(accountId, () => navigate(getLinkToWorkflowInstance(workflowInstanceId)));
        } else {
            navigate(getLinkToWorkflowInstance(workflowInstanceId));
        }
    };

    // Dashboard Tab

    const getLinkToWorkflowDashboard = () => {
        return RoutePath.WorkflowDashboard;
    };

    const routeToWorkflowDashboard = () => {
        navigate(getLinkToWorkflowDashboard());
    };

    // History Tab

    const getLinkToWorkflowHistory = () => {
        return RoutePath.WorkflowHistory;
    };

    const routeToWorkflowHistory = () => {
        navigate(getLinkToWorkflowHistory());
    };

    return {
        getLinkToWorkflowIndex,
        getLinkToWorkflows,
        getLinkToWorkflow,
        getLinkToWorkflowInstances,
        getLinkToWorkflowInstance,
        getLinkToWorkflowDashboard,
        getLinkToWorkflowHistory,
        routeToWorkflowIndex,
        routeToWorkflows,
        routeToWorkflow,
        routeToWorkflowInstances,
        routeToWorkflowInstance,
        routeToWorkflowDashboard,
        routeToWorkflowHistory,
    };
};
