import React, { Dispatch, SetStateAction, useState } from 'react';
import { Col, Modal, Row } from 'reactstrap';
import { PermissionDetailsTable } from './PermissionDetailsTable';
import { PermissionDetailsModalHeader } from './PermissionDetailsModalHeader';
import { Button } from '@efilecabinet/efc-atlantis-components';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { NodePermissionDTO } from '../../../../../api/nodePermissions/nodePermissionApiTypes';
import { NodeType } from '../../../../../api/node/nodeApiTypes';

interface PermissionDetailsModalProps {
    destroyModal: () => void;
    permissionsState: [NodePermissionDTO[], Dispatch<SetStateAction<NodePermissionDTO[]>>];
}

export const PermissionDetailsModal = ({ destroyModal, permissionsState }: PermissionDetailsModalProps) => {

    const { t } = useSafeTranslation(TranslationFiles.Permissions);

    const [permissions, setPermissions] = permissionsState;
    const [permissionsEdit, setPermissionsEdit] = useState<NodePermissionDTO[]>(permissions);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isGrantAccessMode, setIsGrantAccessMode] = useState<boolean>(false);

    function onEdit() {
        setIsEditMode(true);
    }

    function onGrantAccess() {
        setIsGrantAccessMode(true);
        // TODO: Open Grant Access Modal
    }

    function onCancel() {
        setPermissionsEdit(permissions);
        setIsEditMode(false);
    }

    function onSave() {
        setPermissions(permissionsEdit);
        setIsEditMode(false);
    }

    return (
        <>
            <Modal fullscreen isOpen={!isGrantAccessMode}>
                <div className='modal-header'>
                    {!!permissions && <PermissionDetailsModalHeader isEditMode={isEditMode} nodeName={permissions[0].name} nodeType={permissions[0].systemType as NodeType} /> }
                    <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={destroyModal}></button>
                </div>

                <div className='modal-body m-3'>
                    {isEditMode
                        ? <PermissionDetailsTable permissionsState={[permissionsEdit, setPermissionsEdit]} isEditMode={isEditMode} />
                        : <PermissionDetailsTable permissionsState={[permissions, setPermissions]} isEditMode={isEditMode} editCallback={onEdit} grantAccessCallback={onGrantAccess} />
                    }
                </div>

                {isEditMode &&
                    <div className='modal-footer d-flex justify-content-start'>
                        <Row>
                            <Col>
                                <Button emphasis='med' onClick={onCancel}>{t(PermissionsTKeys.CancelButton)}</Button>
                                <Button emphasis='high' color='primary' onClick={onSave} className='ms-2'>{t(PermissionsTKeys.SaveEditsButton)}</Button>
                            </Col>
                        </Row>
                    </div>
                }
            </Modal>
        </>
    );
};